// Core imports
import { Admin, CustomRoutes, DataProvider, I18nProvider, Resource } from 'react-admin'
import { Route } from 'react-router'

// Components
import GalleryTemplate from 'components/modelManager/templates/GalleryTemplate'
import HomepageTemplate from 'components/modelManager/templates/HomepageTemplate'
import Configuration from 'configuration/Configuration'
import Dashboard from './dashboard/dashboard'

// Layout components
import Layout from './layout/Layout'
import Login from './layout/Login'

// Auth
import { ResourceKey, resources } from 'config/resources'
import { useAuthProvider } from 'context/auth/AuthProvider'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'
import AuthProvider from './AuthProvider'

const customRoutes = [
    { path: '/configuration', element: <Configuration /> },
    { path: '/model', element: <HomepageTemplate /> },
    { path: '/gallery', element: <GalleryTemplate /> }
]

export const AdminContent = ({
    dataProvider,
    i18nProvider
}: {
    dataProvider: DataProvider
    i18nProvider: I18nProvider
}) => {
    const { permissions } = useAuthProvider()

    return (
        <Admin
            theme={proudNerdsTheme}
            dataProvider={dataProvider}
            authProvider={AuthProvider}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
            layout={Layout}
            loginPage={Login}
            requireAuth
        >
            <>
                {permissions &&
                    (Object.keys(resources) as ResourceKey[]).map((key) =>
                        permissions[key]?.length > 0 ? (
                            <Resource key={key} name={key} {...resources[key]} />
                        ) : null
                    )}
            </>

            <CustomRoutes>
                {customRoutes.map(({ path, element: Element }) => (
                    <Route key={path} path={path} element={<>{Element}</>} />
                ))}
            </CustomRoutes>
        </Admin>
    )
}
