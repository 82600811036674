import { Box } from '@mui/material'
import { useGalleryStructureQuery } from 'apollo/configurator/queries/GalleryStructure.generated'
import { GalleryContext } from 'context/gallery/GalleryContext'
import React, { ComponentProps, useContext, useEffect, useState } from 'react'
import SortableTree, { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import { IAddFsForm, IFsNode, IRemovableDir } from 'ts/interfaces'
import { AddButton, RemoveButton } from '../../atoms/buttons/Buttons'
import AddFSFolderForm from '../form/AddFSFolderForm'
import { updateTree } from '../manager/helpers/TreeHelperFunctions'
import RemoveFolderPopup from '../popups/RemoveFolderPopup'

interface IBreadcrumb {
    title: string
    id: string
}

type FolderTreeProps = {
    wrapperSx?: ComponentProps<typeof Box>['sx'] & { height?: string }
}

const FolderTree = ({ wrapperSx }: FolderTreeProps) => {
    const { data: { configuratorGalleryStructure: galleryStructure } = {} } =
        useGalleryStructureQuery({
            variables: { from: '/' }
        })
    const [fileSystem, setFileSystem] = useState<TreeItem[] | null>(null)
    const [form, setForm] = React.useState<IAddFsForm>({ dir: '', open: false, rowInfo: null })
    const [nodeData, setNodeData] = useState<ExtendedNodeData | null>(null)
    const [selectedNodeId, setSelectedNodeId] = useState<string>()
    const [removableItem, setRemovableItem] = useState<IRemovableDir>({
        dir: null,
        parent_dir: '',
        extendedNode: null
    })
    const [breadcrumbs, setBreadCrumbs] = useState<IBreadcrumb[] | null>(null)
    const { state: galleryState, setGalleryState } = useContext(GalleryContext)
    const [, updateState] = React.useState({})
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const filterTree = (node: TreeItem) => {
        if (!node.hasOwnProperty('children') || typeof node.children === 'function') {
            return null
        }
        const filteredChildren = node.children
            ?.map((child) => filterTree(child))
            .filter((child) => child !== null)
        return {
            ...node,
            children: filteredChildren
        }
    }
    useEffect(() => {
        if (galleryStructure && !fileSystem) {
            setFileSystem([filterTree(JSON.parse(galleryStructure))])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryStructure]) // Adding the dependency fileSystem here causes an infinite loop.

    useEffect(() => {
        if (fileSystem && nodeData && 'id' in nodeData?.node && selectedNodeId) {
            const updatedTree = updateTree(fileSystem, selectedNodeId, nodeData?.node as IFsNode)
            if (updatedTree) {
                // Make sure the tree updates when there is new data available
                forceUpdate()
            }
        }
        // eslint-disable-next-line
    }, [nodeData, forceUpdate])

    useEffect(() => {
        if (breadcrumbs && breadcrumbs?.length > 0) {
            const formattedBreadcrumbs = breadcrumbs?.map((x) => x.title)?.join('/')
            setGalleryState({ ...galleryState, breadcrumbs: formattedBreadcrumbs || '/' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breadcrumbs])

    const getWrapperHeight = () => {
        if (galleryState.isUploading) return 'calc(100vh - 500px)'
        return wrapperSx?.height || 'calc(100vh - 175px)'
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '90%',
                height: getWrapperHeight()
            }}
            style={{
                marginTop: 35
            }}
        >
            <p>{breadcrumbs?.map((crumb: IBreadcrumb) => `${crumb?.title} `).join('')}</p>
            {fileSystem ? (
                <SortableTree
                    getNodeKey={({ node }) => node.id}
                    treeData={fileSystem}
                    canDrop={({ nextParent }) => !nextParent || nextParent.isDirectory}
                    isVirtualized={false}
                    generateNodeProps={(extendedNode: ExtendedNodeData) => {
                        return {
                            title: (
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    {extendedNode.node.title ? (
                                        <div
                                            key={extendedNode.node.id}
                                            style={{
                                                borderLeft: 'solid 8px gray',
                                                borderBottom: 'solid 10px gray',
                                                marginRight: 10,
                                                boxSizing: 'border-box',
                                                width: 16,
                                                height: 12,
                                                filter: extendedNode.node.expanded
                                                    ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                                    : 'none',
                                                borderColor: extendedNode.node.expanded
                                                    ? 'white'
                                                    : 'gray'
                                            }}
                                        />
                                    ) : (
                                        <div
                                            key={extendedNode.node.id}
                                            style={{
                                                border: 'solid 1px black',
                                                fontSize: 10,
                                                textAlign: 'center',
                                                marginRight: 10,
                                                width: 14,
                                                height: 18
                                            }}
                                        >
                                            F
                                        </div>
                                    )}
                                    {extendedNode?.node?.title?.toString() ||
                                        extendedNode?.node?.file?.toString()}
                                </Box>
                            ),
                            onClick: () => {
                                const title = extendedNode?.node?.title?.toString()
                                const id = extendedNode?.node?.id?.toString()
                                setNodeData(extendedNode)
                                setSelectedNodeId(id)

                                if (title) {
                                    const filteredBreadCrumbs = breadcrumbs
                                        ?.filter((item: IBreadcrumb) => {
                                            return item.id !== id
                                        })
                                        .splice(0, extendedNode?.path?.length - 1)

                                    if (filteredBreadCrumbs) {
                                        setBreadCrumbs([...filteredBreadCrumbs, { title, id }])
                                    } else {
                                        setBreadCrumbs([{ title, id }])
                                    }
                                }
                            },
                            buttons: extendedNode.node.title && [
                                extendedNode.node.title !== '/' ? (
                                    <RemoveButton
                                        key={extendedNode?.node?.id}
                                        className="remove-button"
                                        onClick={() => {
                                            if (extendedNode?.node?.title) {
                                                setRemovableItem({
                                                    dir: extendedNode?.node?.title?.toString(),
                                                    parent_dir:
                                                        extendedNode?.parentNode?.title?.toString() ||
                                                        '/',
                                                    extendedNode
                                                })
                                            }
                                        }}
                                    />
                                ) : (
                                    ''
                                ),
                                <AddButton
                                    key={extendedNode?.node?.id}
                                    className="add-button"
                                    onClick={() => {
                                        setForm({
                                            dir:
                                                breadcrumbs && breadcrumbs?.length > 0
                                                    ? breadcrumbs?.map((x) => x.title)?.join('/')
                                                    : `/${extendedNode?.node?.title?.toString()}`,
                                            rowInfo: extendedNode,
                                            open: true
                                        })
                                    }}
                                />
                            ]
                        }
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(treeData: any) => setFileSystem(treeData)}
                />
            ) : null}

            {removableItem && fileSystem && (
                <RemoveFolderPopup
                    removableItem={removableItem}
                    treeState={fileSystem}
                    setTreeState={setFileSystem}
                    setRemovableItem={setRemovableItem}
                />
            )}
            {fileSystem && (
                <AddFSFolderForm
                    dir={form.dir}
                    open={form.open}
                    rowInfo={form.rowInfo}
                    setForm={setForm}
                    treeState={fileSystem}
                    setTreeState={setFileSystem}
                />
            )}
        </Box>
    )
}

export default FolderTree
