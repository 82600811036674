import { Helmet } from 'react-helmet'

export const Head = () => {
    return (
        <Helmet>
            <title>Manager</title>
            <link key="title" rel="icon" href="favicon-proud-nerds.png" />
        </Helmet>
    )
}
