import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdminContent } from 'AdminContent'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'
import { DataProvider } from 'ra-core'
import buildGraphQLProvider from 'ra-data-graphql-simple'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import React, { useEffect, useState } from 'react'
import { Loading } from 'react-admin'
import { Provider } from 'react-redux'
import { combineReducers, createStore } from 'redux'
import { introspection, introspectionOperationNames } from './apollo/introspection'
import { queryBuilder } from './apollo/queryBuilder'
import { AuthProvider as AuthProviderWrapper } from './context/auth/AuthProvider'
import { ErrorProvider } from './context/error/ErrorContext'
import { GalleryProvider } from './context/gallery/GalleryContext'
import { ModelProvider } from './context/model/ModelContext'
import englishMessages from './i18n/en'
import themeReducer from './themeReducer'

const i18nProvider = polyglotI18nProvider((locale) => {
    return englishMessages // Fallback on english
}, 'en')

const App = (): React.ReactElement => {
    const { isLoading: isAuth0Loading } = useAuth0()
    const client = useApolloClient()
    const [dataProvider, setDataProvider] = useState<DataProvider | null>(null)

    useEffect(() => {
        const buildProvider = async () => {
            const provider = await buildGraphQLProvider({
                client,
                buildQuery: queryBuilder,
                resolveIntrospection: introspection,
                introspection: {
                    operationNames: introspectionOperationNames,
                    exclude: undefined,
                    include: undefined
                }
            })
            setDataProvider(provider)
        }
        buildProvider()
    }, [client])

    if (!dataProvider || isAuth0Loading) {
        return (
            <Loading
                loadingPrimary="Loading"
                loadingSecondary="The page is loading, just a moment please"
            />
        )
    }

    return (
        <ThemeProvider theme={createTheme(proudNerdsTheme)}>
            <ErrorProvider>
                <AuthProviderWrapper>
                    <ModelProvider>
                        <GalleryProvider>
                            <Provider store={createStore(combineReducers({ theme: themeReducer }))}>
                                <AdminContent
                                    dataProvider={dataProvider}
                                    i18nProvider={i18nProvider}
                                />
                            </Provider>
                        </GalleryProvider>
                    </ModelProvider>
                </AuthProviderWrapper>
            </ErrorProvider>
        </ThemeProvider>
    )
}

export default App
