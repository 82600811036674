/* eslint-disable no-unused-vars */
import { Box, Button, DialogActions, TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useCreateDirMutation } from 'apollo/configurator/mutations/CreateDir.generated'
import { DialogTop } from 'components/modelManager/atoms/dialog/DialogTop'
import React, { SyntheticEvent, useState } from 'react'
import { useTranslate } from 'react-admin'
import { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import { addNode } from '../manager/helpers/TreeHelperFunctions'

type TFsFormNode = { dir: string; open: boolean; rowInfo: ExtendedNodeData | null }

interface IFsNodeForm extends TFsFormNode {
    setForm: ({ dir, open, rowInfo }: TFsFormNode) => void
    treeState: TreeItem[] | null
    setTreeState: (treeState: TreeItem[] | null) => void
}

interface IFormData {
    directory?: string
}

const AddFSFolderForm = ({ dir, open, rowInfo, setForm, treeState, setTreeState }: IFsNodeForm) => {
    const [formData, setFormData] = useState<IFormData>({})
    const [createDir] = useCreateDirMutation({})
    const [error, setError] = useState<string | null>(null)

    const translate = useTranslate()
    const regex = /\s/

    const OnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { value, name } = e.currentTarget
        if (!regex.test(value)) {
            if (error) {
                setError(null)
            }
            setFormData({ [name]: value })
        } else {
            setError(translate('manager.resources.model_manager.errors.no_spaces_use_underscores'))
        }
    }

    const OnSaveHandler = (e: SyntheticEvent) => {
        e.preventDefault()

        const { directory } = formData

        if (!directory) {
            return setError(translate('manager.resources.model_manager.errors.no_dir_filled_in'))
        }

        if (directory && regex.test(directory)) {
            return setError(
                translate('manager.resources.model_manager.errors.cannot_add_contains_spaces')
            )
        }

        createDir({
            variables: {
                parent_dir: dir,
                dir: directory || '/'
            }
        })
            .then(async (res) => {
                const makeDir = res?.data?.configuratorMakeDir
                if (treeState && rowInfo && res && directory) {
                    // The tree needs to be updated so we don't have to refetch the whole tree and possibly lose changes.
                    const addFolder = await addNode(
                        treeState,
                        {
                            title: directory,
                            children: [],
                            isDirectory: true,
                            expanded: true,
                            id: makeDir?.id as any
                        },
                        rowInfo,
                        rowInfo?.node?.id
                    )

                    addFolder && setTreeState([...addFolder?.treeData])
                }

                setForm({ dir: '', open: false, rowInfo: null })
                setFormData({})
            })
            .catch((err) => {
                setError(err.message)
            })
    }

    const handleClose = () => {
        setForm({ dir: '', open: false, rowInfo: null })
        setFormData({})
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTop
                title={translate('manager.resources.model_manager.folder_form_dialog.title')}
                onClose={handleClose}
            />
            <DialogContent>
                <DialogContentText>
                    {translate('manager.resources.model_manager.folder_form_dialog.content')}
                </DialogContentText>
                <Box
                    component="form"
                    sx={{
                        '& > *': {
                            width: '50%'
                        }
                    }}
                    autoComplete="off"
                    onSubmit={(e: SyntheticEvent) => OnSaveHandler(e)}
                >
                    <TextField
                        id="standard-basic"
                        label="Directory name"
                        name="directory"
                        autoFocus
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => OnChangeHandler(e)}
                    />
                </Box>
                {error && (
                    <Box
                        component="p"
                        sx={{
                            color: 'tomato',
                            fontSize: '.85rem'
                        }}
                    >
                        Error: {error}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {translate('manager.resources.model_manager.folder_form_dialog.cancel')}
                </Button>
                <Button onClick={OnSaveHandler} color="primary">
                    {translate('manager.resources.model_manager.folder_form_dialog.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddFSFolderForm
