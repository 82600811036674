import FileCopyIcon from '@mui/icons-material/FileCopy'
import MaterialTextfield from '@mui/material/TextField'
import { RaRecord, useNotify, useTranslate } from 'ra-core'
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Pagination,
    TextField,
    useRedirect
} from 'react-admin'

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@mui/material'
import { useDuplicatePackageMutation } from 'apollo/package_manager/mutations/DuplicatePackage.generated'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import { ChangeEvent, MouseEvent, useState } from 'react'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'
import PackageListFilters from './PackageListFilters'

const PackageList = (props: ListProps) => {
    const sx = {
        fileIcon: {
            '&:hover': {
                transform: `scale(1.1)`
            }
        },
        dialogActions: {
            padding: 0,
            paddingBottom: '1rem'
        },
        spinner: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px'
        }
    }
    const translate = useTranslate()
    const redirect = useRedirect()
    const [duplicatePackageMutation, { loading: isLoadingDuplication }] =
        useDuplicatePackageMutation()

    const [open, setOpen] = useState(false)
    const [duplicateId, setDuplicateId] = useState('')
    const [publicName, setPublicName] = useState('')
    const [ajatId, setAjatId] = useState('')
    const notify = useNotify()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        setPublicName('')
        setAjatId('')
        setDuplicateId(id)
        togglePopup()
    }

    const handlePublicNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublicName(e.target.value)
    }

    const handleAjatIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAjatId(e.target.value)
    }

    const handlePopupSubmit = () => {
        duplicatePackageMutation({
            variables: { id: duplicateId, public_name: publicName, ajat_id: ajatId }
        })
            .then((result) => {
                redirect('edit', '/Package', result.data?.duplicatePackage?.id)
            })
            .catch((error) => notify(error.message, { type: 'error' }))
    }

    return (
        <>
            <List
                {...props}
                sort={{ field: 'public_name', order: 'DESC' }}
                empty={false}
                perPage={DEFAULT_PER_PAGE}
                filters={PackageListFilters}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
                sx={{
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 700
                    }
                }}
            >
                <Datagrid
                    body={
                        <CustomDatagridBody
                            bulkActionButtons={<BulkActionButtons isWithActionsFlush />}
                        />
                    }
                    rowClick="edit"
                >
                    <TextField
                        source="public_name"
                        label={translate('manager.resources.package.public_name')}
                    />
                    <TextField
                        source="type"
                        label={translate('manager.resources.package.type')}
                        sortable={false}
                    />
                    <FunctionField
                        source="active"
                        label={translate('manager.resources.general.status')}
                        render={(record: RaRecord) => (
                            <DatagridPublishedColumnContent record={record} />
                        )}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                    <FunctionField
                        label={translate('manager.resources.general.actions')}
                        render={(record: RaRecord) =>
                            record?.id && (
                                <Tooltip title={translate('manager.resources.general.duplicate')}>
                                    <FileCopyIcon
                                        sx={sx.fileIcon}
                                        onClick={(e: MouseEvent<SVGSVGElement>) =>
                                            handleDuplicateClick(e, record.id.toString())
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Datagrid>
            </List>
            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.package.duplicate_package')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.package.public_name')}
                        onChange={handlePublicNameChange}
                    />
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.package.package_id')}
                        onChange={handleAjatIdChange}
                    />
                    <DialogActions sx={sx.dialogActions}>
                        <Button onClick={togglePopup} variant="contained">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={!publicName || !ajatId || isLoadingDuplication}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingDuplication && <CircularProgress size={24} sx={sx.spinner} />}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PackageList
