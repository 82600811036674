import { FormControl } from '@mui/material'
import classNames from 'classnames'
import { useState } from 'react'
import { Loading } from 'react-admin'
import { Mention, MentionsInput } from 'react-mentions'

import { useAvailableMentionsQuery } from 'apollo/personalization/queries/AvailableMentions.generated'
import { defaultMentionStyle, useSx } from './styles'
import { MentionInputProps } from './types'
import { defaultRenderSuggestionItem } from './utils'

export const mentionInputClassName = 'mention-input'
export const floatingLabelClassName = 'mention-input-floating-label'

const MentionsField = (props: MentionInputProps) => {
    const sx = useSx()
    const [mentionsValue, setMentionsValue] = useState<string>(props?.value ?? '')

    const dataTestId = 'test-id-mention-input'

    const { data: { personalizationAvailableMentions } = {}, loading } = useAvailableMentionsQuery({
        variables: { ...props.variables }
    })

    const availableMentionsList = JSON.parse((personalizationAvailableMentions as string) ?? null)

    const onMentionsChange = (_, newValue) => {
        setMentionsValue(newValue)
        props.onChange && props.onChange(newValue)
    }

    const getDisplayNameById = (id: string) =>
        availableMentionsList && availableMentionsList.length
            ? availableMentionsList.find((item) => item.id === id)?.display
            : ''

    if (loading && !availableMentionsList) return <Loading />

    return (
        <FormControl sx={{ ...sx.root, ...props.sx }} error={props.error}>
            {availableMentionsList && (
                <MentionsInput
                    value={mentionsValue}
                    onChange={onMentionsChange}
                    className={classNames(mentionInputClassName, props.inputClassName)}
                    data-testid={dataTestId}
                >
                    <Mention
                        data={availableMentionsList}
                        style={{
                            ...defaultMentionStyle,
                            ...props.mentionStyle
                        }}
                        renderSuggestion={defaultRenderSuggestionItem(dataTestId)}
                        markup={'{{__id__}}'}
                        displayTransform={(id: string) => getDisplayNameById(id)}
                        //in-string trigger fix https://github.com/signavio/react-mentions/issues/435#issuecomment-951765637
                        trigger={/(@([^@]*))$/}
                    />
                </MentionsInput>
            )}

            <label
                className={classNames(
                    floatingLabelClassName,
                    mentionsValue?.length > 0 ? 'filled' : null
                )}
            >
                {props.placeholder}
            </label>
        </FormControl>
    )
}

export default MentionsField
