import { useState } from 'react'
import { Create, useCreatePath, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import PackageGroupCollectionForm from './PackageGroupCollectionForm'

const PackageGroupCollectionCreate = (props) => {
    const translate = useTranslate()
    const createPath = useCreatePath()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Create
                title={translate('manager.resources.packageGroupCollection.create')}
                redirect={createPath({ resource: 'packageGroupCollection', type: 'list' })}
                {...props}
            >
                <PackageGroupCollectionForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default PackageGroupCollectionCreate
