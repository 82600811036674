import { Grid, Typography } from '@mui/material'
import {
    FormTab,
    maxValue,
    minValue,
    number,
    NumberInput,
    regex,
    required,
    TabbedForm,
    TextInput,
    useRecordContext,
    useTranslate
} from 'react-admin'

import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import DefaultToolbar from '../../components/common/DefaultToolbar'
import RetailerClassList from './RetailerClassTable/RetailerClassList'

const RetailerClassRepresentativeForm = ({
    isEdit = false,
    isPristineForm,
    setIsPristineFormCallback,
    ...props
}) => {
    const translate = useTranslate()
    const validateRegex = regex(/^[\w-]+$/, translate('manager.regex.az09'))
    const record = useRecordContext(props)
    const { control } = useForm({
        defaultValues: useRecordContext()
    })
    const { isDirty } = useFormState({ control })

    // Update form pristine state when it changes
    useEffect(() => {
        setIsPristineFormCallback(!isDirty)
    }, [isDirty, setIsPristineFormCallback])

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')}>
                <Typography variant="h6" gutterBottom>
                    {translate('manager.resources.contactPersons.title')}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={7}>
                        <TextInput
                            fullWidth
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.name')}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="email"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.email')}
                            validate={requiredValidate}
                            type="email"
                        />
                        <TextInput
                            fullWidth
                            source="telephone"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.phone')}
                            validate={requiredValidate}
                            type="tel"
                        />
                        <TextInput
                            fullWidth
                            source="ssn"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.ssn')}
                            validate={validateRegex}
                            helperText={translate('manager.regex.az09')}
                        />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <NumberInput
                            fullWidth
                            source="discount_amount"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.discount_amount')}
                        />
                        <NumberInput
                            fullWidth
                            source="discount_percentage"
                            variant="outlined"
                            label={translate(
                                'manager.resources.contactPersons.discount_percentage'
                            )}
                            min={0}
                            max={100}
                            validate={[number(), minValue(0), maxValue(100)]}
                        />
                    </Grid>
                </Grid>
                {isEdit && record?.id && <RetailerClassList />}
            </FormTab>
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerClassRepresentativeForm
