import { useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { TreeItem } from 'react-sortable-tree'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material'

import { useDeletePresetNodeMutation } from 'apollo/configurator/mutations/DeletePresetNode.generated'

import { removePresetNodeDataFromTree } from 'components/presetManager/helpers/TreeHelperFunctions'
import { RemoveAbleItemProps } from 'ts/interfaces'

interface IRemoveNodePopup {
    removeableItem: RemoveAbleItemProps
    setRemovableItemCallback: (item: RemoveAbleItemProps | null) => void
    treeState: TreeItem[]
    onSubmitCallback: (tree: TreeItem[]) => void
}

const RemovePresetNodePopup = ({
    removeableItem,
    setRemovableItemCallback,
    treeState,
    onSubmitCallback
}: IRemoveNodePopup) => {
    const translate = useTranslate()
    const notify = useNotify()
    const [removeNode] = useDeletePresetNodeMutation()
    const [presetNodeId] = useState<string | number | undefined>(
        removeableItem?.node?.presetNodeId ?? undefined
    )

    const handleClose = (): void => {
        setRemovableItemCallback(null)
    }

    const handleRemove = async () => {
        await removeNode({
            variables: {
                id: presetNodeId as any
            }
        })
            .then(() => {
                if (removeableItem?.node) {
                    const newTree = removePresetNodeDataFromTree(treeState, removeableItem.node)
                    onSubmitCallback(newTree?.treeData ?? [])
                }
            })
            .catch((error) => console.error(error))
            .finally(() => {
                notify('manager.resources.preset.removed_successfully', { type: 'success' })
            })
        setRemovableItemCallback(null)
    }

    return (
        <div>
            <Dialog open={!!presetNodeId} onClose={handleClose}>
                <DialogTitle>
                    {translate('manager.resources.preset.delete_preset_dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('manager.resources.preset.delete_preset_dialog.content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        {translate('manager.resources.preset.delete_preset_dialog.cancel')}
                    </Button>
                    <Button onClick={handleRemove} variant="contained" color="primary" autoFocus>
                        {translate('manager.resources.preset.delete_preset_dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default RemovePresetNodePopup
