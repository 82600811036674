import { ComponentProps } from 'react'
import { Datagrid, DatagridBody, DatagridRow, useCreatePath, useResourceContext } from 'react-admin'

type CustomDatagridRowProps = {
    id?: string
    onClickOverride?: (id: string) => void
} & ComponentProps<typeof DatagridRow>
const CustomDatagridRow = (props) => {
    const createPath = useCreatePath()
    const resource = useResourceContext()
    const { id, onClickOverride } = props

    const handleClick = (e) => {
        const recordPath = createPath({
            type: 'edit',
            resource,
            id
        })
        if (!e.ctrlKey) {
            e.preventDefault()
        }
        if (onClickOverride) {
            onClickOverride(id)
            return
        }
        if (e.ctrlKey) {
            window.open(`#${recordPath}`)
            return
        }
        window.open(`#${recordPath}`, '_self')
    }

    return <DatagridRow onClick={handleClick} {...props} />
}

type CustomDatagridBodyProps = ComponentProps<typeof DatagridBody> &
    // for some reason, bulkActionButtons is passed down often in this repo
    // however,  bulkActionButtons is not a valid prop for DatagridBody
    // it somehow is a valid prop for Datagrid
    // supposedly it does nothing, but it is currentely not in my scope to
    // check whether it is used or not, so it is (mistakingly?) added to the below type to prevent project wide errors
    ComponentProps<typeof Datagrid> & {
        rowProps?: CustomDatagridRowProps
    }
export const CustomDatagridBody = ({ rowProps, ...props }: CustomDatagridBodyProps) => (
    <DatagridBody row={<CustomDatagridRow {...rowProps} />} {...props} />
)
