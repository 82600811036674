import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WarningDialog from 'components/modelManager/molecules/popups/WarningDialog'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface IProps {
    isPristineForm: boolean
    backToRouteURL?: string
}

export const BackFromEditorButton = ({ isPristineForm, backToRouteURL }: IProps) => {
    const [isOpenedWarningPopup, setIsOpenedWarningPopup] = useState(false)
    const navigate = useNavigate()
    const onClick = () => {
        if (isPristineForm) {
            if (backToRouteURL) {
                navigate(backToRouteURL)
            } else {
                navigate(-1)
            }
        } else {
            setIsOpenedWarningPopup(true)
        }
    }

    const handleCloseWarning = () => {
        setIsOpenedWarningPopup(false)
    }

    const handleContinueWarning = () => {
        setIsOpenedWarningPopup(false)
        if (backToRouteURL) {
            navigate(backToRouteURL)
        } else {
            navigate(-1)
        }
    }

    return (
        <>
            <div>
                <IconButton onClick={onClick} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
            </div>
            <WarningDialog
                open={isOpenedWarningPopup}
                handleClose={handleCloseWarning}
                handleContinue={handleContinueWarning}
                title="Warning"
                content="You have unsaved changes in the form. Are you sure you want to continue?"
            />
        </>
    )
}
