import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useContext } from 'react'
import { TreeItem } from 'react-sortable-tree'
import { ModelContext } from 'context/model/ModelContext'
import { IRemovableItem } from 'ts/interfaces'
import { deleteNodeInTree } from '../manager/helpers/TreeHelperFunctions'
import { useDeleteNodeMutation } from 'apollo/configurator/mutations/DeleteNode.generated'

interface IRemoveNodePopup {
    removableItem: IRemovableItem
    setRemovableItem: ({ id, extendedNode }: IRemovableItem) => void
    treeState: TreeItem[]
    setTreeState: (data: TreeItem[]) => void
}

const RemoveNodePopup = ({
    removableItem,
    setRemovableItem,
    treeState,
    setTreeState
}: IRemoveNodePopup) => {
    const [removeNode] = useDeleteNodeMutation({})
    const { state: modelState, setState: setModelState } = useContext(ModelContext)

    const handleClose = () => {
        setRemovableItem({ id: null, extendedNode: null, parent_id: null })
    }

    const handleUndefined = () => {
        setModelState({ ...modelState, currentModel: '' })
    }

    const handleRemove = async () => {
        if (removableItem.id) {
            await removeNode({
                variables: {
                    id: removableItem.id as any
                }
            })
                .then(() => {
                    if (!removableItem.parent_id) {
                        setModelState({
                            hasChanges: false,
                            hasEdited: false,
                            currentModel: '',
                            currentModelCode: '',
                            updatedModel: false,
                            showEditedMessage: false
                        })
                    }
                    removableItem.extendedNode &&
                        deleteNodeInTree(
                            treeState,
                            setTreeState,
                            removableItem.extendedNode,
                            handleUndefined
                        )
                })
                .catch((err) => console.error(err))
        }

        setRemovableItem({ id: null, extendedNode: null, parent_id: null })
    }
    return (
        <div>
            <Dialog
                open={removableItem.id ? true : false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {removableItem.parent_id
                        ? 'Are you sure you want to remove this node?'
                        : 'WARNING: Are you sure you want to remove the whole model?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {removableItem.parent_id
                            ? 'By removing this node it will be deleted from the model and this cannot be reversed.'
                            : 'The whole model will be removed. This operation is not reversible. Published Model will not be affected.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Do not remove
                    </Button>
                    <Button onClick={handleRemove} color="secondary" variant="contained" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default RemoveNodePopup
