import { RaRecord } from 'ra-core'
import { useState } from 'react'
import { Datagrid, FunctionField, List, TextField, useTranslate } from 'react-admin'
import styled from 'styled-components'

import { usePackageAvailableProductsQuery } from 'apollo/package_manager/queries/PackageAvailableProducts.generated'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import RelatedResourceToolbar from 'components/common/RelatedResourceToolbar'
import { PackageProductDialog } from './PackageProductDialog'

export interface AvailableProduct {
    id: string
    sku: string
    name: string
}

interface PackageProductListProps {
    isPackage?: boolean
    filter?: Record<string, any>
}

const PackageProductList = (props: PackageProductListProps) => {
    const RESOURCE = 'PackageProduct'
    const [open, setOpen] = useState(false)

    const translate = useTranslate()
    const packageId = props?.filter?.package_id

    const { data: { packagesAvailableProducts: availableProducts } = {} } =
        usePackageAvailableProductsQuery()
    const mappedAvailableProducts =
        availableProducts &&
        availableProducts.map((item) => ({
            sku: item?.sku ?? '',
            name: item?.name ?? ''
        }))

    /*
     * Toggle modal state
     */
    const toggleModal = (): void => {
        setOpen(!open)
    }

    /*
     * Gets product name by sku from list of available products
     */
    const getProductName = (sku: string): string => {
        return (
            (mappedAvailableProducts &&
                mappedAvailableProducts.find((availableProduct) => availableProduct?.sku === sku)
                    ?.name) ||
            sku
        )
    }

    return (
        <>
            <ListWrapper>
                <List
                    resource={`${RESOURCE}`}
                    actions={
                        <RelatedResourceToolbar
                            resource={RESOURCE}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    perPage={20}
                    sx={{
                        width: '100%',
                        margin: '2rem 0',

                        '& .RaDatagrid-headerCell': {
                            fontWeight: 700
                        }
                    }}
                    sort={{ field: 'sku', order: 'ASC' }}
                    {...props}
                >
                    <Datagrid
                        body={<CustomDatagridBody />}
                        rowClick="edit"
                        bulkActionButtons={false}
                    >
                        <TextField
                            label={translate('manager.resources.package.products.sku')}
                            source="sku"
                        />
                        <FunctionField
                            label={translate('manager.resources.package.products.name')}
                            render={(record: RaRecord) => getProductName(record?.sku || '')}
                        />
                        <TextField
                            label={translate('manager.resources.package.pricing')}
                            source="pricing"
                            sortable={false}
                        />
                        <FunctionField
                            sortBy="active"
                            source="active"
                            label={translate('manager.resources.general.status')}
                            render={(record: RaRecord) => (
                                <DatagridPublishedColumnContent record={record} />
                            )}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <PackageProductDialog
                toggleModal={toggleModal}
                open={open}
                props={props}
                availableProducts={availableProducts}
            />
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

export default PackageProductList
