import {
    Button,
    DeleteButton,
    SaveButton,
    Toolbar,
    useRecordContext,
    useTranslate
} from 'react-admin'
import { useNavigate } from 'react-router'

const PackageGalleryItemEditToolbar = (props: any) => {
    const record = useRecordContext()
    const backUrl = `/Package/${record?.package_id}/images`
    const sx = {
        toolbar: {
            display: 'flex',
            flexFlow: 'row wrap',
            margin: '0 auto',
            width: '100%'
        },
        buttonMargin: {
            marginRight: '1rem'
        },
        buttonRight: {
            margin: '0 0 0 auto'
        }
    }
    const navigate = useNavigate()
    const translate = useTranslate()
    const goBack = () => {
        navigate(backUrl)
    }

    return (
        <Toolbar {...props} sx={sx.toolbar}>
            <Button
                sx={sx.buttonMargin}
                onClick={goBack}
                label={translate('manager.resources.general.go_back')}
            />
            <SaveButton />
            <DeleteButton sx={sx.buttonRight} redirect={backUrl + '/'} />
        </Toolbar>
    )
}

export default PackageGalleryItemEditToolbar
