import { DeleteButton, SaveButton, Toolbar, useRecordContext } from 'react-admin'

const RetailerPeriodEditToolbar = (props: any) => {
    const record = useRecordContext()

    return (
        <Toolbar
            {...props}
            sx={{
                '& .RaToolbar-defaultToolbar': {
                    justifyContent: 'space-between'
                }
            }}
        >
            <SaveButton />
            <DeleteButton redirect={`/Retailer/${record?.retailer?.id}/periods`} />
        </Toolbar>
    )
}

export default RetailerPeriodEditToolbar
