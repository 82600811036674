import { darken, lighten } from '@mui/material'
import { COLORS, DEFAULT_TONAL_OFFSET } from './colors'

export const lightTheme = {
    palette: {
        primary: {
            main: COLORS.theme.ajat_green.main,
            light: COLORS.theme.ajat_green.light,
            dark: COLORS.theme.ajat_green.dark,
            contrastText: COLORS.white
        },
        secondary: {
            main: COLORS.theme.dark_purple.main,
            light: COLORS.theme.dark_purple.light,
            dark: COLORS.theme.dark_purple.dark,
            contrastText: COLORS.white
        },
        error: {
            main: COLORS.theme.error_red.main,
            light: COLORS.theme.error_red.light,
            dark: COLORS.theme.error_red.dark,
            contrastText: COLORS.white
        },
        background: {
            default: darken(COLORS.white, 0.01)
        },
        text: {
            primary: lighten(COLORS.black, DEFAULT_TONAL_OFFSET),
            secondary: lighten(COLORS.black, 0.5)
        },
        type: 'light' as 'light'
    },
    shape: {
        borderRadius: 10
    },
    sidebar: {
        width: 220
    },
    overrides: {
        RaLayout: {
            content: {
                paddingBottom: '4rem'
            }
        },
        RaEdit: {
            root: {
                marginBottom: '2rem'
            }
        },
        RaMenuItemLink: {
            root: {
                borderLeft: `none`
            },
            active: {
                borderLeft: `0.5rem solid ${COLORS.theme.ajat_green.main}`
            }
        },
        MuiDrawer: {
            root: {
                backgroundColor: COLORS.white,
                borderRight: `0.1rem solid ${COLORS.theme.grey.light}`
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none'
            },
            root: {
                border: `0.1rem solid ${COLORS.theme.grey.light}`,
                backgroundClip: 'padding-box'
            }
        },
        MuiButton: {
            root: {
                minWidth: '2rem'
            },
            contained: {
                backgroundColor: COLORS.white,
                color: COLORS.theme.ajat_green.main,
                boxShadow: 'none'
            },
            containedPrimary: {
                color: COLORS.white,
                backgroundColor: COLORS.theme.ajat_green.main,
                boxShadow: 'none'
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit'
                }
            }
        },
        MuiListItemIcon: {
            root: {
                color: COLORS.theme.ajat_green.main
            }
        },
        MuiAppBar: {
            colorSecondary: {
                color: lighten(COLORS.black, 0.5),
                backgroundColor: COLORS.white
            }
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
                justifyContent: 'center',
                alignSelf: 'center'
            },
            message: {
                color: COLORS.white
            }
        },
        MuiToolbar: {
            root: {
                width: '100%',
                marginTop: 0,
                bottom: 0
            }
        },
        MuiTableCellHead: {
            root: {
                fontWeight: 'bold'
            }
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true
            }
        }
    }
}
