import { BooleanInput, Edit, Loading, SimpleForm, useRedirect, useTranslate } from 'react-admin'

import { usePackageAvailableProductsQuery } from 'apollo/package_manager/queries/PackageAvailableProducts.generated'
import AvailableProductsSelect from '../../components/formfields/AvailableProductsSelect'
import PackageProductEditToolbar from './PackageProductEditToolbar'

const PackageProductEdit = (props) => {
    const translate = useTranslate()
    const redirect = useRedirect()

    const RESOURCE = 'PackageProduct'
    const onSuccess = (response: any) => {
        redirect('edit', '/Package', response.data?.package_id)
    }

    const { data: { packagesAvailableProducts: availableProducts } = {}, loading } =
        usePackageAvailableProductsQuery()

    if (loading || !availableProducts) return <Loading />
    return (
        <Edit
            {...props}
            basePath={props.basepath}
            resource={RESOURCE}
            mutationMode="pessimistic"
            mutationOptions={{ onSuccess }}
            title={translate(`manager.resources.${RESOURCE}.edit`)}
        >
            <SimpleForm
                defaultValues={{
                    package_id: props?.location?.state?.package_id
                }}
                toolbar={<PackageProductEditToolbar resource={RESOURCE} />}
            >
                <AvailableProductsSelect disabled isEdit products={availableProducts} />
                <BooleanInput
                    row
                    source="is_main"
                    label={translate(`manager.resources.${RESOURCE}.is_main`)}
                />
                <BooleanInput
                    row
                    source="is_optional"
                    label={translate(`manager.resources.${RESOURCE}.is_optional`)}
                />
                <BooleanInput
                    row
                    source="is_included"
                    label={translate(`manager.resources.${RESOURCE}.is_included`)}
                />
                <BooleanInput
                    row
                    source="active"
                    label={translate('manager.resources.general.published')}
                />
            </SimpleForm>
        </Edit>
    )
}

export default PackageProductEdit
