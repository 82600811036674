import { required, SelectInput, useTranslate } from 'react-admin'

import { ChoiceType } from 'types'
import { usePackageAvailableProductsQuery } from 'apollo/package_manager/queries/PackageAvailableProducts.generated'

interface AvailableProductsSelectProps {
    disabled?: boolean
    required?: boolean
    onChangeCallback: (value: string) => void
}

const AvailableProductsSelectSingle = ({
    disabled,
    required,
    onChangeCallback
}: AvailableProductsSelectProps) => {
    const translate = useTranslate()
    const { data: { packagesAvailableProducts: availableProducts } = {}, loading } =
        usePackageAvailableProductsQuery()

    // Map and sort available products
    const mappedAvailableProducts =
        availableProducts &&
        availableProducts
            .map(
                (item): ChoiceType => ({
                    id: item?.sku ?? '',
                    name: `${item?.name} | ${item?.sku}`
                })
            )
            .sort((a: ChoiceType, b: ChoiceType) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })

    return (
        <SelectInput
            fullWidth
            disabled={disabled || loading}
            source="sku"
            variant="outlined"
            choices={(mappedAvailableProducts && mappedAvailableProducts) ?? []}
            validate={required ? requiredValidate : undefined}
            onChange={(event) => onChangeCallback(event?.target?.value)}
            helperText={loading ? translate('manager.loading') : ''}
        />
    )
}

const requiredValidate = [required()]

export default AvailableProductsSelectSingle
