import { LayoutProps, Layout as RaLayout } from 'react-admin'

import { Alert, Snackbar } from '@mui/material'
import GalleryPopup from 'components/modelManager/molecules/gallery/GalleryPopup'
import { useContext } from 'react'
import { ErrorContext } from '../context/error/ErrorContext'
import AppBar from './AppBar'
import Menu from './Menu'

const Layout = (props: LayoutProps) => {
    const { state: errorState, setErrorState } = useContext(ErrorContext)

    return (
        <RaLayout
            {...props}
            appBar={AppBar}
            menu={Menu}
            sx={{
                '& .RaLayout-content': {
                    paddingTop: 2
                }
            }}
        >
            {props.children}

            <Snackbar
                open={errorState.hasError}
                onClose={() => setErrorState({ ...errorState, hasError: false })}
                key={Date.now()}
            >
                <Alert
                    onClose={() => setErrorState({ ...errorState, hasError: false })}
                    severity="error"
                >
                    {errorState.message}
                </Alert>
            </Snackbar>

            <GalleryPopup />
        </RaLayout>
    )
}

export default Layout
