import { Box, Grid } from '@mui/material'
import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'
import { ModelContext } from 'context/model/ModelContext'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'
import { useContext, useEffect, useState } from 'react'
import { Loading } from 'react-admin'
import AttributesEditor from '../molecules/manager/AttributesEditor'
import ModelSelector from '../molecules/manager/ModelSelector'
import TreeView from '../molecules/manager/TreeView'

const Manager = () => {
    const [nodeId, setNodeId] = useState<number | null>()
    const [modelId, setModelId] = useState<number | null>()
    const { data: { configuratorNodes: nodes } = {} } = useRootNodesQuery()
    const [parentId, setParentId] = useState<string>()
    const { state } = useContext(ModelContext)

    const handleNodeClick = (id: number | string, parentId: string) => {
        setNodeId(+id)
        setParentId(parentId)
    }

    const handleModelSelection = (model: number | null) => {
        setModelId(model)
    }

    useEffect(() => {
        if (+state.currentModel !== modelId) {
            setModelId(+state.currentModel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentModel]) // Disabled because it breaks rendering of the TreeView

    const rootnode = nodes?.find((node: any) => node.id === modelId || +node.id === modelId) as any

    // Loading
    if (!nodes?.length) return <Loading />

    return (
        <ModelContext.Consumer>
            {() => (
                <Grid
                    container
                    sx={{
                        position: 'sticky',
                        zIndex: 99,
                        backgroundColor: proudNerdsTheme.palette.background.default,
                        paddingBottom: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: 'sticky',
                            top: 50,
                            zIndex: 99,
                            backgroundColor: proudNerdsTheme.palette.background.default
                        }}
                    >
                        {nodes && (
                            <ModelSelector setModel={handleModelSelection} nodes={nodes as any} />
                        )}
                    </Grid>
                    <Grid
                        container
                        sx={{
                            position: 'fixed',
                            top: 225,
                            maxWidth: '100%',
                            width: 'auto',
                            minWidth: 'calc(100% - 230px)'
                        }}
                    >
                        <Grid
                            item
                            xs={6}
                            sx={{
                                minWidth: '50%'
                            }}
                        >
                            {modelId ? (
                                <>
                                    <TreeView
                                        handleNodeClick={handleNodeClick}
                                        rootNode={rootnode}
                                    />
                                </>
                            ) : null}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                overflow: 'scroll',
                                minWidth: '50%',
                                maxHeight: 'calc(100vh - 230px)'
                            }}
                        >
                            {nodeId ? (
                                <Box
                                    sx={{
                                        marginTop: '40px',
                                        borderLeft: '1px solid #eaeaea',
                                        padding: ' 0 25px'
                                    }}
                                >
                                    <AttributesEditor id={+nodeId} parentId={parentId} />
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </ModelContext.Consumer>
    )
}

export default Manager
