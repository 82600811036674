import { Grid2, Typography } from '@mui/material'
import { useDeleteMediaMutation } from 'apollo/configurator/mutations/DeleteMedia.generated'
import { useGalleryStructureLazyQuery } from 'apollo/configurator/queries/GalleryStructure.generated'
import { GalleryContext } from 'context/gallery/GalleryContext'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { IFsNode } from 'ts/interfaces'
import GalleryImage from '../../atoms/buttons/gallery/GalleryImage'
import WarningDialog from '../popups/WarningDialog'

const ImagesBlock = ({ handleFileSelection }: { handleFileSelection?: (file: string) => void }) => {
    const translate = useTranslate()
    const [data, setData] = useState<IFsNode>()
    const [files, setFiles] = useState<IFsNode[]>()
    const [selectedFile, setSelectedFile] = useState<{ id: string; file: string }>({
        id: '',
        file: ''
    })
    const [showWarning, setShowWarning] = useState(false)
    const [removableFile, setRemovableFile] = useState<{ from: string; remove: string }>()
    const [getFolderImages] = useGalleryStructureLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: ({ configuratorGalleryStructure: galleryStructure }) => {
            return setData(JSON.parse(galleryStructure || ''))
        }
    })

    const { state: galleryState, setGalleryState } = useContext(GalleryContext)

    const [deleteMedia] = useDeleteMediaMutation({
        onCompleted: () => {
            getFolderImages({ variables: { from: galleryState.breadcrumbs } })
        }
    })

    const handleDelete = (from: string, remove: string) => {
        setShowWarning(true)
        setRemovableFile({ from, remove })
    }

    const handleCloseWarning = () => {
        setShowWarning(false)
    }

    const handleContinueWarning = () => {
        setShowWarning(false)
        deleteMedia({ variables: removableFile })
    }

    useEffect(() => {
        if (galleryState.breadcrumbs) {
            const getImages = async () => {
                await getFolderImages({ variables: { from: galleryState.breadcrumbs } })
            }

            getImages()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryState.breadcrumbs])

    useEffect(() => {
        if (galleryState.hasUploaded) {
            const getImages = async () => {
                await getFolderImages({ variables: { from: galleryState.breadcrumbs } })
            }

            getImages()
            setGalleryState({ ...galleryState, hasUploaded: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryState.hasUploaded])

    useEffect(() => {
        if (data) {
            setFiles(data?.children?.filter((item: IFsNode) => item.file))
        }
    }, [data])

    return (
        <Grid2
            container
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 20,
                justifyContent: 'flex-start',
                overflow: 'scroll'
            }}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            style={{
                height: galleryState.isUploading ? 'calc(100vh - 500px)' : 'calc(100vh - 175px)'
            }}
        >
            {files && files.length > 0 ? (
                files?.map((file: Pick<IFsNode, 'file' | 'id'>) => {
                    if (file.file) {
                        return (
                            <GalleryImage
                                galleryImage={file?.file}
                                key={file.id || file.file}
                                handleDelete={handleDelete}
                                onClick={() => {
                                    file?.file &&
                                        handleFileSelection &&
                                        handleFileSelection(file?.file)
                                    file?.file &&
                                        setSelectedFile({ file: file?.file, id: file?.id })
                                }}
                                selected={selectedFile}
                            />
                        )
                    }
                    return false
                })
            ) : (
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {translate('manager.gallery.images_block.no_images')}
                </Typography>
            )}
            <WarningDialog
                open={showWarning}
                handleClose={handleCloseWarning}
                handleContinue={handleContinueWarning}
                title={translate('manager.gallery.images_block.warning_dialog.title')}
                content={translate('manager.gallery.images_block.warning_dialog.content')}
            />
        </Grid2>
    )
}

export default ImagesBlock
