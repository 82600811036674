import { useEffect, useState } from 'react'
import { AutocompleteInput, required } from 'react-admin'
import { ChoiceType } from 'types'

interface AutoCompleteSelectWithDataProps {
    data: any[]
    defaultValue?: number | null
    source: string
    required?: boolean
    sortByName?: boolean
    format?: boolean
    disabled?: boolean
    customChoices?: ChoiceType[]
    onChange?: (selectedItem) => void
}

const AutoCompleteSelectWithData = ({
    data,
    defaultValue,
    source,
    required = false,
    sortByName = true,
    format = false,
    disabled = false,
    customChoices,
    onChange
}: AutoCompleteSelectWithDataProps) => {
    const [choices, setChoices] = useState<ChoiceType[]>([])

    // Map to input
    useEffect(() => {
        if (!customChoices) {
            setChoices(
                data.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.name ?? item.internal_name ?? ''
                    })
                )
            )
        } else {
            setChoices(customChoices)
        }
    }, [data, customChoices])

    // Sort
    if (sortByName) {
        choices.sort((a: ChoiceType, b: ChoiceType) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })
    }

    return (
        <AutocompleteInput
            defaultValue={defaultValue}
            fullWidth
            source={source}
            parse={(id) => parseInt(id)}
            format={(id) => (format ? `${id}` : id)}
            disabled={disabled}
            choices={choices}
            variant="outlined"
            validate={required ? requiredValidate : undefined}
            onChange={onChange}
        />
    )
}

const requiredValidate = [required()]

export default AutoCompleteSelectWithData
