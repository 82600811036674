import { useTheme } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, Typography } from '@mui/material'
import MuiDialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { useTranslate } from 'react-admin'

type GalleryPopupTitleProps = {
    id: string
    children: React.ReactNode
    onClose: () => void
    toggleUpload?: () => void
    isUploading: boolean
}

const GalleryPopupTitle = ({
    children,
    onClose,
    toggleUpload,
    isUploading,
    ...other
}: GalleryPopupTitleProps) => {
    const translate = useTranslate()
    const theme = useTheme()
    const classes = {
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        uploadButton: {
            position: 'absolute',
            right: theme.spacing(10),
            top: theme.spacing(2),
            color: 'white'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    }
    return (
        <MuiDialogTitle sx={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {toggleUpload && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleUpload()}
                    sx={classes.uploadButton}
                >
                    {isUploading
                        ? translate('manager.gallery.close')
                        : translate('manager.gallery.upload_media')}
                </Button>
            )}
            {onClose ? (
                <IconButton aria-label="close" sx={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

export default GalleryPopupTitle
