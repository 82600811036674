import FileCopyIcon from '@mui/icons-material/FileCopy'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@mui/material'
import MaterialTextfield from '@mui/material/TextField'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { RaRecord, useTranslate } from 'ra-core'
import { ChangeEvent, MouseEvent, useState } from 'react'
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Pagination,
    TextField,
    useNotify,
    useRedirect
} from 'react-admin'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { useDuplicatePresetMutation } from '../../apollo/configurator/mutations/DuplicatePreset.generated'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'
import { PresetBulkActionButtons } from './PresetBulkActionButtons'
import PresetListFilters from './PresetListFilters'

const PresetList = (props: ListProps) => {
    const translate = useTranslate()
    const [duplicateId, setDuplicateId] = useState('')
    const [publicName, setPublicName] = useState('')
    const [internalName, setInternalName] = useState('')
    const [open, setOpen] = useState(false)
    const [duplicatePreset, { loading: isLoadingDuplication }] = useDuplicatePresetMutation()
    const redirect = useRedirect()
    const notify = useNotify()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handlePublicNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublicName(e.target.value)
    }

    const handleInternalNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInternalName(e.target.value)
    }

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        setPublicName('')
        setInternalName('')
        setDuplicateId(id)
        togglePopup()
    }

    const handlePopupSubmit = () => {
        duplicatePreset({
            variables: { id: duplicateId, internal_name: internalName, public_name: publicName }
        })
            .then((result) => {
                redirect('edit', '/Preset', result.data?.duplicatePreset?.id)
            })
            .catch((error) => notify(error.message, { type: 'error' }))
    }

    return (
        <>
            <List
                sx={{
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 700
                    }
                }}
                {...props}
                sort={{ field: 'internal_name', order: 'DESC' }}
                perPage={DEFAULT_PER_PAGE}
                filters={PresetListFilters}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid
                    body={<CustomDatagridBody />}
                    bulkActionButtons={<PresetBulkActionButtons />}
                    rowClick="edit"
                >
                    <TextField
                        source="internal_name"
                        label={translate('manager.resources.preset.internal_name')}
                    />
                    <TextField
                        source="public_name"
                        label={translate('manager.resources.preset.public_name')}
                    />
                    <FunctionField
                        source="active"
                        label={translate('manager.resources.general.status')}
                        render={(record: RaRecord) => (
                            <DatagridPublishedColumnContent record={record} />
                        )}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                    <FunctionField
                        label={translate('manager.resources.general.actions')}
                        render={(record: RaRecord) =>
                            record?.id && (
                                <Tooltip title={translate('manager.resources.general.duplicate')}>
                                    <FileCopyIcon
                                        sx={{
                                            '&:hover': {
                                                transform: `scale(1.1)`
                                            }
                                        }}
                                        onClick={(e: MouseEvent<SVGSVGElement>) =>
                                            handleDuplicateClick(e, record.id.toString())
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Datagrid>
            </List>
            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.preset.duplicate_preset')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.preset.internal_name')}
                        onChange={handleInternalNameChange}
                    />
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.preset.public_name')}
                        onChange={handlePublicNameChange}
                    />
                    <DialogActions sx={{ padding: 0, paddingBottom: '1rem' }}>
                        <Button onClick={togglePopup} variant="contained">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={!publicName || !internalName || isLoadingDuplication}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingDuplication && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px'
                                    }}
                                />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PresetList
