import { Grid, InputAdornment, Typography } from '@mui/material'
import {
    BooleanInput,
    FormTab,
    NumberInput,
    required,
    TabbedForm,
    TextInput,
    useRecordContext,
    useTranslate
} from 'react-admin'
import { useForm, useFormState } from 'react-hook-form'

import PackageGroupGalleryItemList from 'resources/packageGroupGalleryItem/PackageGroupGalleryItemList'

import { usePackageGroupCollectionsQuery } from 'apollo/package_manager/queries/PackageGroupCollections.generated'
import AutoCompleteSelectWithData from 'components/formfields/AutoCompleteSelectWithData'
import PackageGroupPackageList from './PackageGroupPackageList'

import { ChoiceType } from 'types'
import PackageGroupProductList from './PackageGroupProductList'

import DefaultToolbar from 'components/common/DefaultToolbar'
import { useEffect } from 'react'

const PackageGroupForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const { control } = useForm()
    const { isDirty } = useFormState({ control }) // React Hook Form equivalent of "pristine"

    const translate = useTranslate()
    const { data: collectionsData } = usePackageGroupCollectionsQuery()
    const collections = collectionsData?.packagesPackageGroupCollections?.data

    const record = useRecordContext()

    useEffect(() => {
        setIsPristineFormCallback(!isDirty) // Mimic the "pristine" state
    }, [isDirty, setIsPristineFormCallback])

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar isWithActionsFlush />}>
            <FormTab label={translate('manager.tabs.general')}>
                <Typography variant="h6" gutterBottom>
                    {translate('manager.resources.packageGroup.title')}
                </Typography>
                <AutoCompleteSelectWithData
                    required
                    format
                    source="collection_id"
                    data={collections ?? []}
                    customChoices={
                        (collections &&
                            collections.map(
                                (item): ChoiceType => ({
                                    id: item.id,
                                    name: `${item.internal_name} | ${item.id}`
                                })
                            )) ??
                        []
                    }
                />
                <Grid container spacing={1}>
                    <Grid item md={7}>
                        <TextInput
                            fullWidth
                            source="public_name"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.public_name')}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="internal_name"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.internal_name')}
                            validate={requiredValidate}
                        />
                        <Grid container spacing={6} alignItems="center">
                            <Grid item md={3}>
                                <NumberInput
                                    source="order"
                                    variant="outlined"
                                    min={0}
                                    defaultValue={0}
                                    label={translate('manager.resources.packageGroup.order')}
                                    validate={requiredValidate}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <BooleanInput
                                    source="is_favourite"
                                    label={translate('manager.resources.packageGroup.is_favourite')}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <BooleanInput
                                    source="is_default"
                                    label={translate('manager.resources.packageGroup.is_default')}
                                />
                            </Grid>
                        </Grid>
                        <BooleanInput
                            source="is_free_design"
                            label={translate('manager.resources.packageGroup.is_free_design')}
                        />
                        <TextInput
                            fullWidth
                            source="short_description"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.short_description')}
                            multiline={true}
                        />
                        <TextInput
                            fullWidth
                            source="long_description"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.long_description')}
                            rows={3}
                            multiline={true}
                        />
                        <TextInput
                            fullWidth
                            source="overlay_text"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.overlay_text')}
                        />
                        <TextInput
                            fullWidth
                            source="wp_slug"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.wp_slug')}
                        />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <TextInput
                            fullWidth
                            source="usps"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.usps')}
                            rows={5}
                            multiline={true}
                            helperText={translate(
                                'manager.resources.packageGroup.usps_helper_text'
                            )}
                            sx={{
                                resize: 'vertical'
                            }}
                        />
                        <Typography variant="h6" gutterBottom sx={{ marginTop: 4.5 }}>
                            {translate('manager.resources.packageGroup.pricing_title')}
                        </Typography>
                        <NumberInput
                            source="final_price"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.final_price')}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                            validate={requiredValidate}
                        />
                        <NumberInput
                            source="base_price"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.base_price')}
                            fullWidth
                            validate={requiredValidate}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                        />
                        <TextInput
                            fullWidth
                            source="price_tooltip"
                            variant="outlined"
                            label={translate('manager.resources.packageGroup.price_tooltip')}
                        />
                        <BooleanInput
                            row
                            source="active"
                            label={translate('manager.resources.general.published')}
                            defaultValue={true}
                        />
                    </Grid>
                </Grid>
            </FormTab>
            {/* Trailing slash (images/) below is to enable redirect location sync for tab */}
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.images')} path="images" syncWithLocation>
                    <PackageGroupGalleryItemList filter={{ group_id: Number(record?.id || 0) }} />
                </FormTab>
            )}
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.package')} path="package">
                    <PackageGroupPackageList />
                </FormTab>
            )}
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.product_preset')} path="product_preset">
                    <PackageGroupProductList />
                </FormTab>
            )}
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default PackageGroupForm
