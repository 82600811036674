import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Form from '@rjsf/mui'
import { IChangeEvent } from '@rjsf/mui/dist'
import { RJSFSchema } from '@rjsf/utils/dist'
import validator from '@rjsf/validator-ajv8'
import { useCreateNodeMutation } from 'apollo/configurator/mutations/CreateNode.generated'
import { useModelSchemaQuery } from 'apollo/configurator/queries/ModelSchema.generated'
import { DialogTop } from 'components/modelManager/atoms/dialog/DialogTop'
import { addNode } from 'components/modelManager/molecules/manager/helpers/TreeHelperFunctions'
import { JSONSchema7 } from 'json-schema'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import { getAvailableTypes, getSchemaByNode } from 'utils/Schema'

type TFormNode = { id: string; open: boolean; rowInfo: ExtendedNodeData | null }

interface INodeForm extends TFormNode {
    setForm: ({ id, open, rowInfo }: TFormNode) => void
    treeState: TreeItem[] | null
    setTreeState: (treeState: TreeItem[] | null) => void
}

const AddNodeForm = ({ id, open, rowInfo, setForm, treeState, setTreeState }: INodeForm) => {
    const { data: { configuratorModelSchema: modelSchema } = {} } = useModelSchemaQuery()
    const modelSchemaObject = modelSchema && JSON.parse(modelSchema)
    const parentType = (id && rowInfo?.node?.node_attributes.type) || 'root'
    const types = (id && parentType && getAvailableTypes(modelSchemaObject, parentType)) || []
    const [formData, setFormData] = useState<unknown>({})
    const [nodeSchema, setNodeSchema] = useState<JSONSchema7>({})
    const [type, setType] = useState<string>('')
    const [createNode] = useCreateNodeMutation({})
    const translate = useTranslate()

    const formControlSx = {
        margin: 1,
        minWidth: 120
    }

    const uiSchema = {
        version: { 'ui:readonly': true },
        constraints: { 'ui:readonly': true },
        upsell: { 'ui:readonly': true },
        valueGroups: { 'ui:readonly': true },
        config: {
            value_group: { 'ui:readonly': true },
            validation: { 'ui:readonly': true }
        },
        description: {
            'ui:widget': 'textarea',
            'ui:options': {
                rows: 5
            }
        },
        description_short: {
            'ui:widget': 'textarea',
            'ui:options': {
                rows: 2
            }
        }
    }
    const handleTypeChange = (event: SelectChangeEvent) => {
        setFormData(null)
        setNodeSchema(modelSchemaObject && getSchemaByNode(modelSchemaObject, type, true))
        const { value } = event?.target
        value && setType(value as string)
    }

    useEffect(() => {
        type &&
            type.length &&
            setNodeSchema(modelSchemaObject && getSchemaByNode(modelSchemaObject, type, true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    const OnSaveHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        const { formData } = e
        if (!formData.config) {
            formData.config = {}
        }
        formData.type = type
        createNode({
            variables: {
                parent_id: +id,
                node_attributes: JSON.stringify(formData)
            }
        }).then(async (res) => {
            const createNode = res?.data?.configuratorCreateNode
            if (treeState && rowInfo && createNode) {
                const addFolder = await addNode(
                    treeState,
                    createNode,
                    rowInfo,
                    createNode?.parent_id || null
                )
                addFolder && setTreeState([...addFolder?.treeData])
            }
        })
        setForm({ id: '', open: false, rowInfo: null })
        setFormData(null)
        setNodeSchema({})
    }

    const handleClose = () => {
        setForm({ id: '', open: false, rowInfo: null })
        setFormData(null)
        setNodeSchema({})
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTop
                    title={translate('manager.resources.model_manager.node_form_dialog.title')}
                    onClose={handleClose}
                    dialogTitleProps={{ id: 'form-dialog-title' }}
                />

                <DialogContent
                    sx={{
                        padding: 2
                    }}
                >
                    <DialogContentText>
                        {translate('manager.resources.model_manager.node_form_dialog.content')}
                    </DialogContentText>
                    <FormControl sx={formControlSx}>
                        <InputLabel id="select-new-node-type-label">
                            {translate('manager.resources.model_manager.node_form_dialog.type')}
                        </InputLabel>
                        <Select sx={formControlSx} onChange={handleTypeChange}>
                            {types.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {type.length > 0 && nodeSchema && (
                        <Form
                            showErrorList={false}
                            schema={nodeSchema}
                            uiSchema={uiSchema}
                            formData={formData}
                            onSubmit={OnSaveHandler}
                            validator={validator}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddNodeForm
