import { CircularProgress } from '@mui/material'
import Form from '@rjsf/mui'
import { IChangeEvent } from '@rjsf/mui/dist'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { useRetailersExtensionAttributesSchemaQuery } from 'apollo/retailer_manager/queries/RetailersExtensionAttributesSchema.generated'
import { useEffect, useState } from 'react'
import { RaRecord, useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { uiSchema, widgets } from './uiSchema'
import { deserializeData, serializeData } from './utils'

export interface VariablesProps {
    record: RaRecord | undefined
    entityType: string
}

export const Variables: React.FC<VariablesProps> = ({ entityType }) => {
    const [schema, setSchema] = useState<RJSFSchema>()
    const [initialFormData, setInitialFormData] = useState<any>(null)
    const { setValue } = useFormContext()
    const record = useRecordContext()
    const { data: { retailersExtensionAttributesSchema } = {}, error } =
        useRetailersExtensionAttributesSchemaQuery({
            variables: {
                entityType
            }
        })

    /**
     * Handles a change event for an RJSF form by serializing the data and updating the form state.
     *
     * @param data - The IChangeEvent object containing the changed form data.
     */
    const handleChange = (data: IChangeEvent<any, RJSFSchema, any>) => {
        setValue('extension_attributes', serializeData(data))
        setInitialFormData(data?.formData || {})
    }

    /**
     * Sets the RJSF schema based on the `retailersExtensionAttributesSchema` state variable.
     */
    useEffect(() => {
        if (retailersExtensionAttributesSchema) {
            setSchema(JSON.parse(retailersExtensionAttributesSchema || '{}'))
        }
    }, [retailersExtensionAttributesSchema])

    /**
     * Deserializes the `extension_attributes` data and sets the initial form data based on the `schema` state variable.
     * This effect runs only once, when the component mounts.
     */
    useEffect(() => {
        if (schema && record?.['extension_attributes'] !== undefined && initialFormData === null) {
            const deserializedData = deserializeData(record?.extension_attributes, schema)
            setInitialFormData(deserializedData || {})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, record])

    if (error) {
        const drawError = () => {
            if (!record?.__typename) {
                return 'No schema found for this record'
            }
            return `No schema found for ${record?.__typename}`
        }
        return <>{drawError()}</>
    }

    return schema && initialFormData !== null ? (
        <Form
            key={'form'}
            schema={schema}
            validator={validator}
            onChange={handleChange}
            formData={initialFormData}
            uiSchema={uiSchema}
            widgets={widgets}
        >
            {/* hack to hide submit button */}
            <button />
        </Form>
    ) : (
        <CircularProgress />
    )
}
