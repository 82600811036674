import { useState } from 'react'
import { Edit, useCreatePath, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'
import PackageGroupCollectionForm from './PackageGroupCollectionForm'

const PackageGroupCollectionEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)
    const createPath = useCreatePath()

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Edit
                title={translate('manager.resources.packageGroupCollection.edit')}
                undoable={false}
                {...props}
                redirect={createPath({ resource: 'packageGroupCollection', type: 'list' })}
            >
                <PackageGroupCollectionForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Edit>
        </>
    )
}

export default PackageGroupCollectionEdit
