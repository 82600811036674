import React, { useContext } from 'react'
import { useNotify, useTranslate } from 'react-admin'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Skeleton,
    Typography
} from '@mui/material'

import { GalleryContext } from 'context/gallery/GalleryContext'

interface GalleryImageProps {
    galleryImage: string
    onClick: () => void
    selected: {
        id: string
        file: string
    }
    handleDelete: (from: string, remove: string) => void
}

const GalleryImage: React.FC<GalleryImageProps> = ({
    selected,
    galleryImage,
    onClick,
    handleDelete
}) => {
    const notify = useNotify()

    const REACT_APP_MEDIA_URL = process.env.REACT_APP_MEDIA_URL
    const { state } = useContext(GalleryContext)
    const translate = useTranslate()

    // The reason for this reformatting is that the newly created rootnode is called '/' which adds some issues with the file names concatting duplicate slashes.
    const imageUrl = `${REACT_APP_MEDIA_URL}${
        state?.breadcrumbs.replace('//', '') + '/'
    }${galleryImage}`
    const imageName = galleryImage.split('.')[0]

    const urlToClipboard = () => {
        navigator.clipboard.writeText(imageUrl)
        notify(translate('manager.gallery.copyUrlSuccess'), {
            type: 'success'
        })
    }

    return (
        <Grid item>
            {galleryImage ? (
                <Card
                    sx={{
                        height: 275,
                        maxWidth: 345,
                        width: 230,
                        marginBottom: 20
                    }}
                    onClick={onClick}
                    style={{
                        border: selected.file === galleryImage ? '2px solid dodgerblue' : ''
                    }}
                >
                    <CardActionArea>
                        <Box
                            sx={{
                                backgroundImage: ` url('/img/checkered-bg.png')`,
                                height: 140,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            {!REACT_APP_MEDIA_URL ? (
                                <Box
                                    sx={{
                                        height: 140,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexFlow: 'column wrap'
                                    }}
                                >
                                    <ImageNotSupportedIcon
                                        sx={{
                                            alignSelf: 'center'
                                        }}
                                        fontSize="large"
                                    />
                                </Box>
                            ) : (
                                <CardMedia
                                    sx={{
                                        height: 140,
                                        backgroundSize: 'contain'
                                    }}
                                    image={imageUrl}
                                />
                            )}
                        </Box>
                        <CardContent>
                            <Typography
                                style={{ wordBreak: 'break-word' }}
                                gutterBottom
                                variant="body2"
                                component="h2"
                            >
                                {imageName}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => handleDelete(state.breadcrumbs, galleryImage)}
                        >
                            {translate('manager.gallery.delete')}
                        </Button>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation()
                                urlToClipboard()
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            ) : (
                <div>
                    <Skeleton variant="rectangular" width={210} height={135} />
                    <Skeleton variant="text" width={210} height={40} />
                </div>
            )}
        </Grid>
    )
}

export default GalleryImage
