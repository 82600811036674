import { Toolbar } from '@mui/material'
import { Toolbar as RaToolbar, SaveButton } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

interface DefaultToolbarProps {
    isWithActionsFlush?: boolean
    onClick?: (hasError: boolean) => void
}

const DefaultToolbar = ({ isWithActionsFlush, onClick, ...props }: DefaultToolbarProps) => {
    const { formState } = useFormContext()

    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleSaveButtonClick = () => {
        // Check for specific field errors
        const hasErrors = !!(
            formState.errors.ajat_id ||
            formState.errors.final_price ||
            formState.errors.public_name
        )

        onClick && onClick(hasErrors)

        if (isWithActionsFlush) {
            flushActionsCache()
        } else {
            return false
        }
    }

    return (
        <Toolbar sx={{ position: 'fixed', bottom: 0, margin: 0, zIndex: 2 }} disableGutters>
            <RaToolbar
                {...props}
                sx={{
                    margin: 0
                }}
            >
                <SaveButton onClick={handleSaveButtonClick} />
            </RaToolbar>
        </Toolbar>
    )
}
export default DefaultToolbar
