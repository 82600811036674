import { PersonalizationRulesQuery } from 'apollo/personalization/queries/PersonalizationRules.generated'
import { PersonalizationRulePathUpdateInput } from 'apollo/types.generated'
import jp from 'jsonpath'
import { RaRecord } from 'react-admin'

export type PersonalizationRule = {
    __typename?: 'personalizationRule'
    actions: string
    active: boolean
    break?: boolean | null | undefined
    created_at: any
    id: string
    label: string
    priority?: number | null | undefined
    rules: string
    updated_at: any
}

export interface getRetailerTagOccurencesProps {
    personalizationRules: PersonalizationRulesQuery['personalizationRules']
    record: RaRecord
    returnPaths?: boolean
    newTagValue?: string
}

const findRulesWithTag = ({
    personalizationRules,
    record,
    returnPaths = false,
    newTagValue
}: getRetailerTagOccurencesProps): Array<
    PersonalizationRule | PersonalizationRulePathUpdateInput
> => {
    const objectsWithRuleTag: Array<PersonalizationRule> = []
    const occurrences: Array<PersonalizationRulePathUpdateInput> = []

    personalizationRules?.data?.forEach((personalizationRule) => {
        if (personalizationRule?.rules) {
            if (!returnPaths) {
                const match: boolean = personalizationRule.rules?.includes(
                    `[{"var": "tag"}, "${record?.tag}"`
                )

                if (match) {
                    objectsWithRuleTag.push(personalizationRule)
                }
            } else if (returnPaths) {
                try {
                    const parse = JSON.parse(personalizationRule.rules)

                    if (typeof parse == 'object') {
                        let possibles = jp.nodes(parse, `$..[?(@[1] == '${record.tag}')]`)

                        possibles.forEach(function (match) {
                            if (match.value[0].var === 'tag') {
                                occurrences.push({
                                    id: personalizationRule.id,
                                    path: jp.stringify(match.path),
                                    value: newTagValue || ''
                                })
                            }
                        })
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    })

    return returnPaths ? occurrences : objectsWithRuleTag
}

export const getRetailerTagOccurences = ({
    personalizationRules,
    record,
    returnPaths = false,
    newTagValue
}: getRetailerTagOccurencesProps): Array<
    PersonalizationRule | PersonalizationRulePathUpdateInput
> => {
    const filteredPersonalizationRules = findRulesWithTag({
        personalizationRules,
        record,
        returnPaths,
        newTagValue
    })

    return filteredPersonalizationRules
}
