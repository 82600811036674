import { FieldProps } from '@rjsf/utils'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'

import { Button, Grid, TextField } from '@mui/material'

import { GalleryContext } from 'context/gallery/GalleryContext'
import { formStyles } from 'layout/styles/formStyles'

export const GalleryImageSelector = (props: FieldProps) => {
    const {
        name,
        formData,
        onChange,
        idSchema: { $id }
    } = props

    const { state: galleryState, setGalleryState } = useContext(GalleryContext)
    const [isUpdatingImage, setIsUpdatingImage] = useState(false)

    const translate = useTranslate()

    const formatFileName = (file: string) => {
        return file.includes('//') ? file.replace('//', '') : file.replace('/', '')
    }

    // If a file is selected from the gallery, update the local file state
    // Using a currentSource to make sure we update the right input field
    useEffect(() => {
        if (!galleryState.openGallery && galleryState.selectedFile && isUpdatingImage) {
            onChange(
                `${formatFileName(galleryState.selectedFile)}${galleryState.selectedFileExtension}`
            )

            setIsUpdatingImage(false)
            setGalleryState({ ...galleryState, selectedFile: '', selectedFileExtension: '' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryState])

    // Open gallery
    const handleSelectFileClick = () => {
        setIsUpdatingImage(true)
        setGalleryState({ ...galleryState, openGallery: true })
    }

    // Remove image
    const handleRemoveClick = () => {
        onChange('')
    }

    return (
        <Grid
            container
            key={$id}
            sx={{
                ...formStyles.fullWidth
            }}
        >
            <Grid item md={5}>
                <TextField disabled fullWidth variant="outlined" label={name} value={formData} />
            </Grid>

            <Grid
                item
                md={5}
                sx={{
                    ...formStyles.buttonsAfterInputContainer
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSelectFileClick}
                    sx={{
                        ...formStyles.buttonsAfterInputContainer
                    }}
                >
                    {translate('manager.resources.general.upload.select_file')}
                </Button>

                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleRemoveClick}
                    sx={{
                        ...formStyles.buttonsAfterInputContainer
                    }}
                >
                    {translate('manager.resources.general.upload.remove')}
                </Button>
            </Grid>
        </Grid>
    )
}
