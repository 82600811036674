import { Grid, Typography } from '@mui/material'
import {
    FormTab,
    RaRecord,
    TabbedForm,
    TextInput,
    required,
    useRecordContext,
    useTranslate
} from 'react-admin'

import DefaultToolbar from 'components/common/DefaultToolbar'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { OccurencesList } from './OccurrencesList'
import { PersonalizationRule } from './utils/getRetailerTagOccurrences'
import { useForm, useFormState } from 'react-hook-form'

interface RetailerTagFormProps {
    isPristineForm: any
    setIsPristineFormCallback: any
    setRecord?: Dispatch<SetStateAction<RaRecord | undefined>>
    filteredPersonalizationRules?: PersonalizationRule[] | undefined
    record?: RaRecord
    isEdit?: boolean
}

const RetailerTagForm: React.FC<RetailerTagFormProps> = ({
    isPristineForm,
    setIsPristineFormCallback,
    setRecord,
    filteredPersonalizationRules,
    ...props
}) => {
    const translate = useTranslate()
    const record = useRecordContext()

    const { control } = useForm({
        defaultValues: record
    })

    const { isDirty } = useFormState({ control })

    useEffect(() => {
        setIsPristineFormCallback(isDirty)
    }, [isDirty, setIsPristineFormCallback])

    useEffect(() => {
        if (props?.isEdit && setRecord && record) {
            setRecord(record)
            localStorage.setItem('currentTag', record?.tag || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')}>
                <Grid container spacing={0}>
                    <Grid item md={7}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.tag.tag')}
                        </Typography>

                        <TextInput
                            source="tag"
                            variant="outlined"
                            label={translate('manager.resources.tag.name')}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Grid>

                    {filteredPersonalizationRules?.length && (
                        <OccurencesList
                            filteredPersonalizationRules={filteredPersonalizationRules}
                        />
                    )}
                </Grid>
            </FormTab>
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerTagForm
