import BackupIcon from '@mui/icons-material/Backup'
import { Box, Slide /* , Snackbar */ } from '@mui/material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fab from '@mui/material/Fab'
import { useExportModelMutation } from 'apollo/configurator/mutations/ExportModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
import { ErrorContext } from 'context/error/ErrorContext'
import { ModelContext } from 'context/model/ModelContext'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'
import React, { useContext } from 'react'
import { useNotify } from 'react-admin'

interface IExportButton {
    id: number
}

const ExportButton = ({ id }: IExportButton) => {
    const notify = useNotify()
    const { state, setState } = useContext(ModelContext)
    const { setErrorState } = useContext(ErrorContext)
    const [success, setSuccess] = React.useState(false)
    const [mutationError, setMutationError] = React.useState(false)
    const [exportModel, { loading, error }] = useExportModelMutation({
        onCompleted({ configuratorExportModel: exportModel }) {
            if (exportModel) {
                setSuccess(true)
                setState({
                    ...state,
                    hasEdited: false,
                    hasChanges: false,
                    showEditedMessage: false
                })
                setMutationError(false)
                notify('The model has been exported successfully', { type: 'success' })
            } else if (error) {
                setMutationError(true)
                notify('The model has not been exported successfully', { type: 'error' })
            }
        },
        onError(error) {
            setMutationError(true)
            setErrorState({ hasError: true, message: error.message })
        }
    })

    const buttonSx = {
        backgroundColor: proudNerdsTheme.palette.primary.main,
        '&:hover': {
            backgroundColor: proudNerdsTheme.palette.primary.dark
        },
        ...(mutationError && {
            backgroundColor: proudNerdsTheme.palette.error.main,
            '&:hover': {
                backgroundColor: proudNerdsTheme.palette.error.dark
            }
        }),
        ...(state.hasChanges && {
            backgroundColor: proudNerdsTheme.palette.warning.main,
            '&:hover': {
                backgroundColor: proudNerdsTheme.palette.warning.dark
            }
        })
    } as const

    const [open, setOpen] = React.useState(false)

    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        exportModel({ variables: { id: id as any } })
        flushActionsCache()
        setOpen(false)
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}>
                <Box
                    sx={{
                        position: 'relative',
                        margin: 4
                    }}
                >
                    <Fab aria-label="save" color="primary" sx={buttonSx} onClick={handleClickOpen}>
                        <BackupIcon />
                    </Fab>
                    {loading && (
                        <CircularProgress
                            size={68}
                            sx={{
                                color: proudNerdsTheme.palette.warning.light,
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1
                            }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        height: 50,
                        overflow: 'hidden',
                        marginLeft: -25,
                        color: proudNerdsTheme.palette.warning.contrastText
                    }}
                >
                    <Slide
                        appear={true}
                        direction="right"
                        in={state?.showEditedMessage}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Box
                            component="p"
                            sx={{
                                width: 150,
                                position: 'absolute',
                                margin: 0,
                                zIndex: -1,
                                height: 50,
                                borderRadius: '0 10px 10px 0 ',
                                backgroundColor: proudNerdsTheme.palette.primary.light,
                                display: 'flex',
                                fontSize: 18,
                                alignItems: 'center',
                                paddingLeft: 40,
                                fontWeight: 'bold'
                            }}
                        >
                            Edited!
                        </Box>
                    </Slide>
                </Box>
            </Box>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {'Are you sure you want to publish the model?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        The current model is going to be exported. This may have non-reversible
                        consequences. Do you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        No
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExportButton
