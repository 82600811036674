import { useEffect, useState } from 'react'
import { Loading, SelectInput, TextInput, useRecordContext, useTranslate } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { usePackageAvailableProductsQuery } from 'apollo/package_manager/queries/PackageAvailableProducts.generated'
import { PackagesPackageProductType } from 'apollo/types.generated'
import ConfiguratorOption from 'resources/packages/common/ConfiguratorOption'
import { ChoiceType, TProductType } from 'types'
import ConfiguratorNodesSelect from './ConfiguratorNodesSelect'
import ConfiguratorPresetsSelect from './ConfiguratorPresetsSelect'

interface AvailableProductsSelectProps {
    disabled?: boolean
    required?: boolean
    isEdit?: boolean
    isPackage?: boolean
    products: Exclude<
        ReturnType<typeof usePackageAvailableProductsQuery>['data'],
        undefined
    >['packagesAvailableProducts']
}

const AvailableProductsSelect = ({
    disabled,
    required: isRequired,
    isEdit = false,
    isPackage,
    products
}: AvailableProductsSelectProps) => {
    const translate = useTranslate()
    const formData = useWatch()
    const { setValue } = useFormContext()
    const record = useRecordContext()

    const [selectedRootModelCode, setSelectedRootModelCode] = useState<string | null>(
        isEdit ? formData?.root_model : null
    )

    // Map and sort available products
    const mappedAvailableProducts =
        products &&
        products
            .map(
                (item): ChoiceType => ({
                    id: item?.sku ?? '',
                    name: `${item?.name} | ${item?.sku}`
                })
            )
            .sort((a: ChoiceType, b: ChoiceType) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })

    const onRootModelSelectorChangeHandler = (event) => {
        setSelectedRootModelCode(event.target.value)
    }

    const onPresetSelectorChangeHandler = (event) => {}

    const selectedProduct = products?.find((product) => product?.sku === formData.sku)
    const selectedProductType = isEdit ? formData?.type : selectedProduct?.type
    let isSimpleSelectedProductType: boolean

    if (isEdit) {
        isSimpleSelectedProductType =
            (selectedProductType as PackagesPackageProductType) === 'simple' ||
            (selectedProductType as PackagesPackageProductType) === 'configurable'
                ? true
                : false
    } else {
        isSimpleSelectedProductType =
            (selectedProductType as TProductType) === 'SimpleProduct' ||
            (selectedProductType as TProductType) === 'ConfigurableProduct'
                ? true
                : false
    }

    useEffect(() => {
        if (selectedProduct && !formData.type) {
            const type =
                selectedProduct.type === 'ConfigurableProduct'
                    ? PackagesPackageProductType?.Configurable
                    : selectedProduct.type === 'SimpleProduct'
                    ? PackagesPackageProductType.Simple
                    : selectedProduct.type === 'ConfigurationProduct'
                    ? PackagesPackageProductType?.Configuration
                    : ''

            setValue('type', type)
        }
    }, [selectedProduct, setValue, formData?.type])

    if (!products) return <Loading />

    return (
        <>
            <SelectInput
                source="sku"
                fullWidth
                disabled={mappedAvailableProducts?.length === 0 || disabled}
                variant="outlined"
                choices={mappedAvailableProducts ?? []}
            />

            {isPackage && (
                <>
                    <TextInput
                        fullWidth
                        variant="outlined"
                        source="comparison_label"
                        label={translate('manager.resources.package.products.comparison_label')}
                    />

                    <TextInput
                        fullWidth
                        variant="outlined"
                        label={translate('manager.resources.package.products.short_description')}
                        source="short_description"
                    />
                </>
            )}

            {!isSimpleSelectedProductType && (
                <>
                    {formData.sku && (
                        <ConfiguratorNodesSelect
                            source="root_model"
                            label={translate('manager.resources.package.products.root_model')}
                            choiseValueIsID
                            required
                            setIsSelectedRootModel={onRootModelSelectorChangeHandler}
                        />
                    )}
                    {selectedRootModelCode && (
                        <>
                            <ConfiguratorPresetsSelect
                                label={translate('manager.resources.package.products.preset_code')}
                                isFilteredPresetsByModelCode
                                parentModelCode={selectedRootModelCode}
                                setIsSelectedPreset={onPresetSelectorChangeHandler}
                            />
                            <ConfiguratorOption
                                initialData={formData?.included_options}
                                selectedRootModelCode={selectedRootModelCode}
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default AvailableProductsSelect
