import { BooleanInput, NumberInput, regex, required, TextInput, useTranslate } from 'react-admin'

import { Grid, InputAdornment, Typography } from '@mui/material'
import { PackagesPackageType } from 'apollo/types.generated'
import { formStyles } from 'layout/styles/formStyles'
import { useWatch } from 'react-hook-form'
import PackageProductList from 'resources/packageProduct/PackageProductList'

export interface GeneralTabContentProps {
    packageType: PackagesPackageType
}

export const GeneralTabContent: React.FC<GeneralTabContentProps> = ({ packageType }) => {
    const translate = useTranslate()
    const requiredValidate = [required()]
    const record = useWatch()
    const validateRegex = regex(/^[\w-]+$/, translate('manager.regex.az09'))

    return (
        <Grid container sx={formStyles.fullWidth}>
            <Grid item md={7}>
                <Grid item md={12}>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.package.package')}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <TextInput
                        fullWidth
                        source="ajat_id"
                        variant="outlined"
                        label={translate('manager.resources.package.package_id')}
                        validate={[required(), validateRegex]}
                        helperText={translate('manager.regex.az09')}
                    />
                    <TextInput
                        fullWidth
                        source="public_name"
                        variant="outlined"
                        label={translate('manager.resources.package.public_name')}
                        validate={requiredValidate}
                    />
                    <Grid container md={12} spacing={6} alignItems="center">
                        <Grid item md={3}>
                            <NumberInput
                                source="order"
                                variant="outlined"
                                min={0}
                                defaultValue={0}
                                label={translate('manager.resources.package.order')}
                                validate={requiredValidate}
                            />
                        </Grid>
                        {packageType === PackagesPackageType.Package && (
                            <>
                                <Grid item md={4}>
                                    <BooleanInput
                                        source="is_favourite"
                                        label={translate('manager.resources.package.is_favourite')}
                                    />
                                </Grid>
                                <Grid item md={5}>
                                    <BooleanInput
                                        source="is_default"
                                        label={translate('manager.resources.package.is_default')}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {packageType === PackagesPackageType.Package && (
                        <>
                            <TextInput
                                fullWidth
                                source="short_description"
                                variant="outlined"
                                label={translate('manager.resources.package.short_description')}
                                multiline={true}
                            />
                            <TextInput
                                fullWidth
                                source="long_description"
                                variant="outlined"
                                label={translate('manager.resources.package.long_description')}
                                rows={3}
                                multiline={true}
                            />
                            <TextInput
                                fullWidth
                                source="overlay_text"
                                variant="outlined"
                                label={translate('manager.resources.package.overlay_text')}
                            />
                            <TextInput
                                fullWidth
                                source="overlay_text_bottom"
                                variant="outlined"
                                label={translate('manager.resources.package.overlay_text_bottom')}
                            />
                            <TextInput
                                fullWidth
                                source="wp_slug"
                                variant="outlined"
                                label={translate('manager.resources.package.wp_slug')}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid item md={1} />
            <Grid item md={4}>
                <Grid item md={12} sx={formStyles.flexStart}>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.package.pricing')}
                    </Typography>
                    {packageType === PackagesPackageType.Package && (
                        <NumberInput
                            source="base_price"
                            variant="outlined"
                            label={translate('manager.resources.package.price_original')}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                        />
                    )}
                    <NumberInput
                        source="final_price"
                        variant="outlined"
                        label={translate('manager.resources.package.price_sell')}
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>
                        }}
                        validate={requiredValidate}
                    />
                    {packageType === PackagesPackageType.Package && (
                        <TextInput
                            fullWidth
                            source="price_tooltip"
                            variant="outlined"
                            label={translate('manager.resources.package.price_tooltip')}
                        />
                    )}
                    <BooleanInput
                        row
                        source="active"
                        label={translate('manager.resources.general.published')}
                        defaultValue={true}
                    />
                </Grid>
            </Grid>
            {record?.id && (
                <Grid container spacing={1} sx={formStyles.fullWidth}>
                    <PackageProductList
                        filter={{ package_id: Number(record?.id) }}
                        isPackage={packageType === PackagesPackageType.Package}
                    />
                </Grid>
            )}
        </Grid>
    )
}
