import { required, SelectInput, useTranslate } from 'react-admin'
import { ChoiceType } from 'types'

import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'

interface ConfiguratorNodesSelectProps {
    source?: string
    label?: string
    disabled?: boolean
    required?: boolean
    choiseValueIsID?: boolean
    setIsSelectedRootModel?: (event) => void
}

const ConfiguratorNodesSelect = ({
    source = 'parent_model_id',
    label,
    disabled = false,
    required = false,
    choiseValueIsID = false,
    setIsSelectedRootModel
}: ConfiguratorNodesSelectProps) => {
    const translate = useTranslate()
    const { data: nodes, loading } = useRootNodesQuery()
    const choices = nodes?.configuratorNodes.map(
        (item): ChoiceType => ({
            id: choiseValueIsID ? item.code ?? '' : item.id,
            name: item.code ?? ''
        })
    )

    return (
        <SelectInput
            fullWidth
            variant="outlined"
            label={label}
            source={source}
            parse={choiseValueIsID ? (id) => id : (id) => parseInt(id)}
            choices={choices ?? []}
            disabled={disabled || loading}
            helperText={loading ? translate('manager.loading') : ''}
            validate={required ? requiredValidate : undefined}
            onChange={setIsSelectedRootModel}
        />
    )
}

const requiredValidate = [required()]

export default ConfiguratorNodesSelect
