import { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { useForm, useFormState } from 'react-hook-form'

import { usePackageSchemaQuery } from 'apollo/package_manager/queries/PackageSchema.generated'
import { PackagesPackageType } from 'apollo/types.generated'
import { PackageCreateForm } from '../PackageCreate/PackageCreateForm'
import { PackageEditForm } from '../PackageEdit/PackageEditForm'

const PackageForm = ({ isPristineForm, setIsPristineFormCallback, isEdit = false }) => {
    const record = useRecordContext()
    const [packageType, setPackageType] = useState<PackagesPackageType>(
        record?.type ?? PackagesPackageType.Package
    )
    const { data: { packageSchema } = {}, loading } = usePackageSchemaQuery({
        variables: {
            type: packageType
        }
    })

    const { control } = useForm()

    // Access the `isDirty` state of the form
    const { isDirty } = useFormState({ control })

    // Call the callback when the `isDirty` state changes
    useEffect(() => {
        setIsPristineFormCallback(!isDirty) // `!isDirty` corresponds to `pristine`
    }, [isDirty, setIsPristineFormCallback])

    return (
        <>
            {isEdit ? (
                <PackageEditForm
                    packageSchema={packageSchema}
                    loading={loading}
                    packageType={packageType}
                    setPackageType={setPackageType}
                />
            ) : (
                <PackageCreateForm
                    packageSchema={packageSchema}
                    loading={loading}
                    packageType={packageType}
                    setPackageType={setPackageType}
                />
            )}
        </>
    )
}

export default PackageForm
