import { useState } from 'react'
import { Edit, useResourceContext, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'
import PersonalizationRuleForm from './PersonalizationRuleForm'

const PersonalizationRuleEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)
    const resource = useResourceContext()

    if (!resource) {
        return null
    }

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/rule" />
            <Edit
                title={translate(`manager.resources.${resource.toLowerCase()}.edit`)}
                undoable={false}
                {...props}
            >
                <PersonalizationRuleForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Edit>
        </>
    )
}

export default PersonalizationRuleEdit
