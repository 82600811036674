import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import { FieldProps } from '@rjsf/utils/dist'
import { useEffect, useState } from 'react'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { useFormContext, useWatch } from 'react-hook-form'

import { useConfiguratorModelTreeStructureQuery } from 'apollo/package_manager/queries/ConfiguratorModelTreeStructure.generated'

const STRING_SEPARATOR: string = ','

const ConfiguratorOptionTreeMultiple = (props: FieldProps) => {
    const { getValues } = useFormContext()
    const parsedPropsValue = props.value ? props.value.split(STRING_SEPARATOR) : []

    // Watch for changes in root_model field
    const rootModel = useWatch({
        name: 'root_model'
    })

    const [selectedRootModelCode, setSelectedRootModelCode] = useState(getValues('root_model'))
    const [checked, setChecked] = useState(parsedPropsValue)
    const [expanded, setExpanded] = useState<string[]>([])

    // Update selectedRootModelCode when root_model changes
    useEffect(() => {
        setSelectedRootModelCode(rootModel)
    }, [rootModel])

    const onCheck = (checked: string[]) => {
        setChecked(checked)
        props.onChange(checked.join(STRING_SEPARATOR))
    }

    const onExpand = (expanded: string[]) => {
        setExpanded(expanded)
    }

    const { data, loading } = useConfiguratorModelTreeStructureQuery({
        variables: { code: selectedRootModelCode }
    })

    let parsedConfiguratorModelTreeStructure: Node[] = []
    if (data?.configuratorModelTreeStructure) {
        parsedConfiguratorModelTreeStructure = JSON.parse(data?.configuratorModelTreeStructure)
    }

    return (
        <>
            {loading ? (
                <LinearProgress />
            ) : (
                <>
                    <CheckboxTree
                        showExpandAll={true}
                        noCascade={true}
                        checkModel="all"
                        nodes={parsedConfiguratorModelTreeStructure ?? []}
                        checked={checked}
                        expanded={expanded}
                        onCheck={onCheck}
                        onExpand={onExpand}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Divider />
                    </Box>
                    {parsedPropsValue &&
                        parsedPropsValue.map((element) => (
                            <Typography
                                key={element}
                                variant="caption"
                                sx={{ wordBreak: 'break-all' }}
                            >
                                <strong>Path:</strong> {element}
                            </Typography>
                        ))}
                </>
            )}
        </>
    )
}

export default ConfiguratorOptionTreeMultiple
