import { Box, Button } from '@mui/material'
import { GalleryContext } from 'context/gallery/GalleryContext'
import React, { useContext } from 'react'
import FolderTree from '../../molecules/gallery/FolderTree'
import ImagesBlock from '../../molecules/gallery/ImagesBlock'

const GalleryContent = () => {
    const { state, setGalleryState } = useContext(GalleryContext)
    return (
        <Box
            component="section"
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr'
            }}
        >
            {!state.openGallery && (
                <React.Fragment>
                    <div>
                        <FolderTree />
                    </div>
                    <div>
                        <Button
                            onClick={() =>
                                setGalleryState({ ...state, isUploading: !state.isUploading })
                            }
                            variant="contained"
                            color="primary"
                            sx={{
                                margin: '30px 24px 30px auto',
                                display: 'block'
                            }}
                        >
                            {state.isUploading ? 'Close' : 'Upload media'}
                        </Button>
                        <ImagesBlock />
                    </div>
                </React.Fragment>
            )}
        </Box>
    )
}

export default GalleryContent
