import Form from '@rjsf/mui'
import validator from '@rjsf/validator-ajv8'
import { formStyles } from 'layout/styles/formStyles'
import { useEffect, useState } from 'react'
import { Button } from 'react-admin'
import { useFormContext } from 'react-hook-form'

interface PackageSchemaFormProps {
    packageSchema: string
    definition: string
}

const PackageSchemaForm = ({ packageSchema, definition }: PackageSchemaFormProps) => {
    const { setValue, getValues } = useFormContext()
    const [formData] = useState<unknown>(definition && JSON.parse(definition))
    const schema = packageSchema && JSON.parse(packageSchema)
    const uiSchema = {
        package_config: {
            code: {
                'ui:widget': 'hidden'
            }
        }
    }
    const code = schema?.properties?.package_config?.properties?.code?.default
    const values = getValues()

    useEffect(() => {
        if (code && !values.definition) {
            setValue(
                'definition',
                JSON.stringify({
                    package_config: {
                        code: code
                    }
                })
            )
        }
    }, [code, values, setValue])

    // Unset gallery
    delete schema.properties.gallery

    return (
        <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            onError={(e) => console.error('errors', e)}
            validator={validator}
        >
            <Button sx={formStyles.hidden} type="submit" />
        </Form>
    )
}

export default PackageSchemaForm
