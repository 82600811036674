import englishMessages from 'ra-language-english'
import { TranslationMessages } from 'react-admin'

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    manager: {
        configuration: 'Configuration',
        language: 'Language',
        required: 'Required',
        loading: 'Loading...',
        gallery: {
            close: 'close',
            delete: 'Delete',
            copyUrlSuccess: 'Media link copied to clipboard',
            gallery: 'Gallery',
            select_file: 'Select file',
            upload_media: 'Upload media',
            images_block: {
                no_images: 'No images available for this folder',
                warning_dialog: {
                    title: 'Are you sure you want to delete this image?',
                    content:
                        'By clicking continue the image will be deleted from the server and cannot be restored, this action will be permanent.'
                }
            },
            upload_block: {
                remove: 'remove',
                successfully_uploaded: 'The files are succesfully uploaded.',
                could_not_be_uploaded: 'The following file could not be uploaded',
                drop_files_here: 'Drop the files here ...',
                drag_or_drop_files_here: 'Drag and drop some files here, or click to select files',
                upload_files: 'upload_files'
            }
        },
        languages: {
            english: 'english',
            finnish: 'finnish'
        },
        login: {
            incorrect_username_and_or_password: 'Incorrect username and/or password',
            required: 'Required'
        },
        menu: {
            classes: 'Classes',
            collections: 'Collections',
            educations: 'Educations',
            gallery: 'Gallery',
            model: 'Models',
            package_manager: 'Package manager',
            model_manager: 'Model manager',
            packages: 'Packages',
            packageGroup: 'Package Groups',
            presets: 'Presets',
            personalization: 'Personalization',
            retailer_manager: 'Entity manager',
            retailers: 'Retailers',
            tags: 'Tags',
            years: 'Years',
            contactPersons: 'Contact Persons'
        },
        tabs: {
            general: 'General',
            config: 'Config',
            periods: 'periods',
            images: 'Images',
            model: 'Model',
            classes: 'Classes',
            package: 'Packages',
            packageGroup: 'Package Groups',
            product_preset: 'Product (preset)',
            contact_persons: 'Contact persons',
            variables: 'Variables'
        },
        regex: {
            az09: 'Can only contain (A-z, 0-9 and -)'
        },
        resources: {
            education: {
                code: 'Education code',
                education: 'Education',
                priority: 'Priority',
                name: 'Education name'
            },
            general: {
                actions: 'Actions',
                copied_to_clipboard: 'Copied to clipboard',
                duplicate: 'Duplicate',
                delete: 'Delete',
                edited_successfully: 'edited successfully',
                go_back: 'Go back',
                last_updated: 'Last updated',
                not_published: 'Not published',
                published: 'Published',
                save: 'Save',
                save_and_go_back: 'Save and go back',
                status: 'Status',
                upload: {
                    select_file: 'Select file',
                    show_preview: 'Show preview',
                    hide_preview: 'Hide preview',
                    remove: 'remove'
                }
            },
            model_manager: {
                root_node: 'Root node',
                errors: {
                    no_spaces_use_underscores:
                        'The directory name cannot contain any spaces. Use an underscore instead.',
                    no_dir_filled_in: 'There is no directory name filled in',
                    cannot_add_contains_spaces: 'Cannot add directory, the name contains spaces'
                },
                warning_dialog: {
                    title: 'You have unpublished changes on this model',
                    content:
                        'You have made changes to the model that are not published. Are you sure you want to continue?',
                    confirm: 'Continue',
                    cancel: 'Cancel'
                },
                folder_form_dialog: {
                    title: 'Add a new directory',
                    content:
                        'To add a new directory we need some default information about what kind of item this will be. Please fill in the form below and click on add directory.',
                    confirm: 'Submit',
                    cancel: 'Cancel'
                },
                node_form_dialog: {
                    title: 'Add a new item',
                    content:
                        'To add a new item we need some default information about what kind of item this will be. Please fill in the form below and click on add item.',
                    type: 'type',
                    confirm: 'Submit',
                    cancel: 'Cancel'
                },
                update_affected_presets_dialogs: {
                    warning_dialog: {
                        title: 'Are you sure you want to update affected presets?',
                        content:
                            'All affected presets are going to be updated. This may have non-reversible consequences. Do you want to continue?'
                    },
                    results_dialog: {
                        title: 'These presets were updated:',
                        content_not_found: 'No affected presets were found',
                        close: 'Close'
                    }
                }
            },
            package: {
                products: {
                    created_at: 'Created at',
                    name: 'Product name',
                    sku: 'SKU',
                    comparison_label: 'Comparison label',
                    short_description: 'Short description',
                    root_model: 'Root model',
                    preset_code: 'Preset code',
                    hints: {
                        no_presets_for_model: 'No presets for this model'
                    }
                },
                package_id: 'Package ID',
                public_name: 'Public name',
                order: 'Order',
                is_favourite: 'Is Favourite',
                is_default: 'Is Default in Wizard',
                short_description: 'Short description',
                long_description: 'Long description',
                actions: 'Actions',
                create_a_package: 'Create a package',
                definition: 'Definition',
                edit: 'Edit a package',
                package: 'Package',
                type: 'Package type',
                overlay_text: 'Overlay text',
                overlay_text_bottom: 'Bottom Overlay text',
                prices: 'Prices',
                pricing: 'Pricing',
                price_original: 'Package price (original)',
                price_sell: 'Package price (sell)',
                price_tooltip: 'Price tooltip',
                wp_slug: 'WP slug',
                duplicate_package: 'Duplicate Package'
            },
            packageGroup: {
                title: 'Package Group',
                create: 'Create a package group',
                edit: 'Edit a package group',
                collection_id: 'Collection ID',
                internal_name: 'Internal name',
                public_name: 'Public name',
                order: 'Order',
                is_favourite: 'Is Favourite',
                is_default: 'Is Default in Wizard',
                is_free_design: 'Is Free Design',
                short_description: 'Short description',
                long_description: 'Long description',
                usps: 'USP text',
                usps_helper_text: '"+" for checkmark | "-" for crossmark | "*" for grey checkmark',
                overlay_text: 'Overlay text',
                wp_slug: 'WP slug',
                pricing_title: 'Pricing',
                final_price: 'Final price',
                base_price: 'Base price',
                price_tooltip: 'Price tooltip',
                product_preset: 'Product (preset)',
                preset: {
                    add: 'Add product (preset)',
                    cancel: 'Cancel',
                    code: 'Code',
                    sku: 'Sku',
                    submit: 'Submit'
                },
                package: {
                    add: 'Add related package',
                    add_button: 'Add',
                    close: 'Close',
                    hint: 'All existing packages were added',
                    name: 'Name',
                    type: 'Type',
                    delete_confirm_popup: {
                        title: 'Are you sure you want to delete this relation?',
                        content: 'Please, click "Continue" if you do'
                    }
                },
                duplicate_package_group: 'Duplicate Package Group'
            },
            PackageProduct: {
                add: 'Add package product',
                edit: 'Edit package product',
                is_included: 'Is included in discount',
                is_main: 'Is main product',
                is_optional: 'Is optional product'
            },
            PackageGalleryItem: {
                add: 'Add gallery item',
                edit: 'Edit gallery item',
                title: 'Title',
                path: 'Path',
                alt: 'Alt'
            },
            packageGroupCollection: {
                create: 'Create a package group collection',
                edit: 'Edit a package group collection',
                collection: 'Collection',
                internal_name: 'Internal name',
                public_name: 'Public name',
                order: 'Order',
                is_favourite: 'Is Favourite',
                is_default: 'Is Default in Wizard',
                short_description: 'Short description',
                long_description: 'Long description',
                usps: 'USP text',
                usps_helper_text: '"+" for checkmark | "-" for crossmark'
            },
            PackageGroupCollectionGalleryItem: {
                add: 'Add gallery item',
                edit: 'Edit gallery item',
                title: 'Title',
                path: 'Path',
                alt: 'Alt'
            },
            PackageGroupGalleryItem: {
                add: 'Add gallery item',
                edit: 'Edit gallery item',
                title: 'Title',
                path: 'Path',
                alt: 'Alt'
            },
            contactPersons: {
                name: 'Name',
                email: 'Email',
                ssn: 'SSN',
                phone: 'Phone number',
                retailers: 'Retailers',
                classes: 'Classes',
                year: 'Year',
                discount_amount: 'Discount amount',
                discount_percentage: 'Discount percentage',
                retailer_name: 'Retailer name',
                class_name: 'Class name',
                create: 'Create a Contact Person',
                edit: 'Edit a Contact Person',
                title: 'Contact Person',
                add_retailer_class: 'Add retailer or class'
            },
            preset: {
                added_successfully: 'Override has been successfully added.',
                create_a_preset: 'Create a preset',
                edit: 'Edit a preset',
                edited_successfully: 'Override has been successfully edited.',
                publish: 'Publish',
                internal_name: 'Preset name',
                public_name: 'Display name',
                preset: 'Preset',
                published_code: 'Preset code display',
                removed_successfully: 'Override has been successfully deleted.',
                empty_submit: 'Submitted an empty form, please add an override and try again.',
                manager: {
                    all: 'All',
                    delete_preset_node: 'Delete preset override',
                    hide_multiple_nodes: 'Hide/show this node AND all child nodes',
                    hide_single_node: 'Hide/show this node',
                    preset_export_error: 'Encountered an error while exporting the preset',
                    preset_export_succes: 'The preset has been exported succesfully',
                    selected_node: 'Selected node',
                    sync: 'Sync'
                },
                publish_dialog: {
                    title: 'Are you sure you want to publish the preset model?',
                    content:
                        'The current preset model is going to be exported. This may have non-reversible consequences. Do you want to continue?',
                    confirm: 'Confirm',
                    cancel: 'Cancel',
                    button: 'Publish model'
                },
                warning_dialog: {
                    title: 'You have unpublished changes on this model',
                    content:
                        'You have made changes to the model that are not published. Are you sure you want to continue?',
                    confirm: 'Continue',
                    cancel: 'Cancel'
                },
                delete_preset_dialog: {
                    title: 'Are you sure you want to remove this preset node?',
                    content: 'Deleting this preset node cannot be reversed.',
                    confirm: 'Delete',
                    cancel: 'Cancel'
                },
                duplicate_preset: 'Duplicate Preset'
            },
            retailer: {
                active_periods: 'Active periods',
                address: 'Address (street + house number)',
                code: 'Code',
                city: 'City',
                country: 'Country',
                classes: 'Classes',
                create: 'Create a retailer',
                edit: 'Edit a retailer',
                embroidery_1: 'Custom 1',
                embroidery_2: 'Custom 2',
                embroidery_3: 'Custom 3',
                enter_new_tag: 'Enter a new tag',
                filter_active_periods: 'Filter active periods',
                hero_banner: 'Hero banner',
                internal_note: 'Internal note',
                logo: 'logo',
                link_class: 'Link a class',
                marketing_url: 'Url',
                name: 'Retailer name',
                no_classes: 'No classes connected to this retailer.',
                personalization_logo: 'Personalization logo',
                post_code: 'Postal code',
                representative_discount_amount: 'Discount amount',
                representative_discount_percentage: 'Discount percentage',
                representative_email: 'E-mailaddress',
                representative_name: 'Name',
                representative_ssn: 'Social security number',
                representative_telephone: 'Phone number',
                representative: 'Representative',
                retailer: 'Retailer',
                students_amount: 'Amount of students',
                tags: 'Tags',
                upload: 'Upload retailers',
                priority: 'Priority'
            },
            retailerClass: {
                code: 'Class ID',
                class: 'Class',
                edit: 'Edit a retailerClass',
                embroidery_1: 'Embroidery 1',
                embroidery_2: 'Embroidery 2',
                embroidery_3: 'Embroidery 3',
                name: 'Name',
                no_contacts: 'No contact persons connected to this class.',
                retailer: 'Retailer',
                students_amount: 'Amount of students',
                representatives: 'Contact persons',
                year: 'Year',
                upload: 'Upload classes',
                priority: 'Priority'
            },
            retailerClassRepresentatives: {
                upload: 'Upload contact persons',
                add_representative: 'Add representative',
                edit_representative: 'edit representative'
            },
            retailerPeriod: {
                add: 'Add retailer period',
                edit: 'Edit retailer period',
                from_date: 'Date from',
                to_date: 'Date to',
                grace_payment_date: 'Grace Payment Date'
            },
            retailerYear: {
                edit: 'Edit a retailerYear',
                year: 'Year',
                name: 'Year / Season'
            },
            tag: {
                create: 'Create tag',
                edit: 'Edit tag',
                name: 'Tag',
                tag: 'Tag name',
                occurrences: 'This tag is used in the following personalization rules:'
            },
            rule: {
                create: 'Create Rule',
                edit: 'Edit rule',
                actions: 'Actions',
                always_apply: 'Always apply',
                break: 'Stop applying other personalization rules',
                label: 'Label',
                personalization: 'Personalization',
                priority: 'Priority',
                rule: 'Rule',
                rules: 'Rules',
                actions_tab: {
                    path_tree: {
                        node_path_title: '1. Select node',
                        node_path_alert: 'Choose a model to see nodes tree',
                        attribute_path_title: '2. Select attribute',
                        attribute_path_alert: 'Choose a node to see the attributes tree',
                        invalid_path_alert: 'Choose a valid path to see the attributes tree',
                        path_hint: 'Path',
                        tab_title_tree: 'Select node by tree',
                        tab_title_input: 'Select node by text',
                        value_override_title: '3. Override attribute value'
                    }
                }
            }
        },
        theme: {
            name: 'Theme',
            dark: 'Dark',
            light: 'Light',
            proudNerds: 'ProudNerds'
        },
        cache: {
            productsCache: 'Products autocomplete',
            productsCacheSuccess: 'Products cache is flushed successfully',
            actionsCache: 'Actions definitions',
            actionsCacheSuccess: 'Actions cache is flushed successfully',
            cacheFlushedSuccessfully: 'Products and actions caches are flushed successfully',
            flush: 'Flush',
            title: 'Cache management'
        }
    }
}

export default customEnglishMessages
