import { useAuth0 } from '@auth0/auth0-react'
import RestoreIcon from '@mui/icons-material/Restore'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, IconButton, Snackbar, Tooltip, Typography } from '@mui/material'
import { forwardRef, useState } from 'react'
import { AppBar, MenuItemLink, useRefresh, UserMenu, useTranslate } from 'react-admin'
import Logo from './Logo'

import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
import { useFlushProductsCacheMutation } from 'apollo/personalization/mutations/FlushProductsCache.generated'
import { sxStyles } from './styles/sx'

const SNACKBAR_TIMEOUT = 4000

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate()
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('manager.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sx={sxStyles.menuItemLink}
            sidebarIsOpen
        />
    )
})

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
)

const CustomAppBar = (props: any) => {
    const { user } = useAuth0()
    const [isFlushed, setIsFlushed] = useState<boolean>(false)
    const reload = useRefresh()
    const translate = useTranslate()

    const [flushProductsCache, { loading: flushProductsCacheLoading }] =
        useFlushProductsCacheMutation()
    const [flushActionsCache, { loading: flushActionsCacheLoading }] =
        useFlushActionsCacheMutation()

    const handleFlushButtonClick = () => {
        setIsFlushed(true)
        Promise.all([flushProductsCache(), flushActionsCache()]).then(() => {
            reload()
        })
    }

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Box sx={{ flex: 1 }} />
            <Logo />
            <Box sx={{ flex: 1 }} />
            {user && (
                <Typography
                    sx={{
                        marginRight: '0.5em'
                    }}
                >
                    {user?.nickname}
                </Typography>
            )}
            <Tooltip title="Flush cache">
                <IconButton
                    aria-label="flush"
                    disabled={flushProductsCacheLoading || flushActionsCacheLoading}
                    onClick={handleFlushButtonClick}
                >
                    <RestoreIcon />
                </IconButton>
            </Tooltip>

            <Snackbar
                open={isFlushed}
                onClose={() => setIsFlushed(false)}
                autoHideDuration={SNACKBAR_TIMEOUT}
                message={translate('manager.cache.cacheFlushedSuccessfully')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </AppBar>
    )
}

export default CustomAppBar
