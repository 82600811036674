import {
    TextInput,
    BooleanInput,
    useTranslate,
    required,
    TabbedForm,
    FormTab,
    useRecordContext
} from 'react-admin'
import { Grid, Typography } from '@mui/material'

import DefaultToolbar from '../../components/common/DefaultToolbar'
import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'

const RetailerYearForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()

    const { control } = useForm({
        defaultValues: useRecordContext()
    })

    const { isDirty } = useFormState({ control })

    useEffect(() => {
        setIsPristineFormCallback(isDirty)
    }, [isDirty, setIsPristineFormCallback])

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')}>
                <Grid container>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.retailerYear.year')}
                            </Typography>
                        </Grid>
                        <TextInput
                            fullWidth
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.retailerYear.name')}
                            validate={requiredValidate}
                        />
                        <BooleanInput
                            source="active"
                            label={translate('manager.resources.general.published')}
                            defaultValue={true}
                        />
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerYearForm
