interface LogoProps {
    className?: string
}

const Logo = (props: LogoProps) => {
    const src = '/proud-nerds-logo.png'
    const alt = 'proud-nerds-logo'
    const height = '50'
    return <img {...props} src={src} alt={alt} height={height} />
}

export default Logo
