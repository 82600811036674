import { COLORS } from 'layout/theme/colors'

export const sxStyles = {
    menuItemLink: {
        ':root': {
            borderLeft: '0rem'
        },
        '&.RaMenuItemLink-active': {
            borderLeft: `0.5rem solid ${COLORS.theme.proud_nerds_gold.main}`
        }
    }
} as const
