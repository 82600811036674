/* eslint-disable no-unused-vars */
import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle, Grid2, IconButton } from '@mui/material'
import { ComponentProps } from 'react'
type DialogTopProps = {
    title: string
    onClose: () => void
    dialogTitleProps?: ComponentProps<typeof DialogTitle>
}
export const DialogTop = ({ onClose, title, dialogTitleProps }: DialogTopProps) => {
    return (
        <Grid2 container justifyContent="space-between">
            <Grid2 size={11}>
                <DialogTitle
                    sx={{
                        paddingTop: 16,
                        paddingLeft: 16,
                        paddingRight: 0
                    }}
                    {...dialogTitleProps}
                >
                    {title}
                </DialogTitle>
            </Grid2>
            <Grid2 size={1}>
                <IconButton
                    aria-label="close"
                    sx={{
                        height: 50,
                        width: 50
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Grid2>
        </Grid2>
    )
}
