import _ from 'lodash'
import { changeNodeAtPath, getNodeAtPath, TreeItem } from 'react-sortable-tree'
import {
    DescendantsHaveNodesOfPresetsProps,
    HasNodesOfPresetsProps,
    INode,
    INodeWithPreset,
    IPresetNode
} from 'ts/interfaces'

export const EMPTY_DEFINITION = '{}'

const formatTreeSinglePresetNode = (node: INodeWithPreset, expanded?: boolean) => {
    const attributes =
        typeof node?.node_attributes === 'string'
            ? JSON.parse(node.node_attributes)
            : node.node_attributes

    return {
        id: node.id,
        parent_id: node.parent_id,
        node_attributes: attributes,
        title: attributes?.code,
        expanded: expanded,
        has_nodes_of_presets: node?.has_nodes_of_presets,
        descendants_have_nodes_of_presets: node?.descendants_have_nodes_of_presets,
        presetNode: node?.presetNode,
        presetNodeId: node?.presetNodeId,
        presetId: node?.presetId
    }
}

export const formatPresetTreeState = (node: INodeWithPreset, expanded?: boolean) => {
    // TODO: More layers? (sync with node.gql)
    return {
        ...formatTreeSinglePresetNode(node, expanded),
        children: node?.children?.map((child: INodeWithPreset) => ({
            ...formatTreeSinglePresetNode(child, expanded),
            children: child?.children?.map((child2: INodeWithPreset) => ({
                ...formatTreeSinglePresetNode(child2, expanded),
                children: child2?.children?.map((child3: INodeWithPreset) => ({
                    ...formatTreeSinglePresetNode(child3, expanded),
                    children: child3?.children?.map((child4: INodeWithPreset) => ({
                        ...formatTreeSinglePresetNode(child4, expanded),
                        children: child4?.children?.map((child5: INodeWithPreset) => ({
                            ...formatTreeSinglePresetNode(child5, expanded),
                            children: child5?.children?.map((child6: INodeWithPreset) => ({
                                ...formatTreeSinglePresetNode(child6, expanded),
                                children: child6?.children?.map((child7: INodeWithPreset) => ({
                                    ...formatTreeSinglePresetNode(child7, expanded),
                                    children: child7?.children?.map((child8: INodeWithPreset) => ({
                                        ...formatTreeSinglePresetNode(child8, expanded),
                                        children: child8?.children?.map(
                                            (child9: INodeWithPreset) => ({
                                                ...formatTreeSinglePresetNode(child9, expanded),
                                                children: child9?.children?.map(
                                                    (child10: INodeWithPreset) => ({
                                                        ...formatTreeSinglePresetNode(
                                                            child10,
                                                            expanded
                                                        )
                                                    })
                                                )
                                            })
                                        )
                                    }))
                                }))
                            }))
                        }))
                    }))
                }))
            }))
        }))
    }
}

export const updatePresetTree = (
    tree: TreeItem[],
    id: number | string,
    nodeData: INodeWithPreset
) => {
    if (nodeData?.children?.length) {
        return tree?.some(function iter(o) {
            if (o.id === id) {
                if ('node_attributes' in nodeData) {
                    o.children = nodeData?.children?.map((child: INode) =>
                        formatPresetTreeState(child)
                    )
                    return true
                } else {
                    return true
                }
            }
            return Array.isArray(o.children) && o.children?.some(iter)
        })
    }
}

// Remove preset node data from both the selected node and the parent nodes
export const removePresetNodeDataFromTree = (treeState: TreeItem[], removedNode: TreeItem) => {
    treeState = deleteHasDescendantsFromParentNodes(treeState, removedNode)
    removedNode = deletePresetNodeProps(removedNode)
    return {
        treeData: changeNodeAtPath({
            newNode: removedNode,
            treeData: treeState,
            path: removedNode.path,
            getNodeKey: ({ node }) => node.id,
            ignoreCollapsed: false
        })
    }
}

const deletePresetNodeProps = (node: TreeItem) => {
    // Unset and delete all presetNode data from the node
    node.has_nodes_of_presets = []
    delete node.presetNode
    delete node.presetNodeId
    return node
}

const deleteHasDescendantsFromParentNodes = (tree: TreeItem, removedNode: TreeItem) => {
    // Loop over each item in the tree
    return _.cloneDeepWith(tree, (value, key) => {
        if (key === 'descendants_have_nodes_of_presets') {
            // Clone array to make sure its immutable, otherwise splice won't work
            const descendantsClone = [...value]

            // Find index of presetNode that needs to be removed from the array
            const index = findPresetCombinationInArray(
                descendantsClone,
                removedNode.presetId,
                removedNode.presetNodeId
            )

            // Remove found index from descendants array
            descendantsClone.splice(index, 1)

            // Return new value for descendants_have_nodes_of_presets
            return descendantsClone
        }
    })
}

export const findPresetCombinationInArray = (
    arr: DescendantsHaveNodesOfPresetsProps[] | HasNodesOfPresetsProps[],
    presetId: number,
    presetNodeId: number
) => {
    // Find index by comparing current node id's with each item in the DescendantsHaveNodesOfPresets or HasNodesOfPresets array
    return arr.findIndex(
        (item) => presetId === item.preset_id && presetNodeId === item.preset_node_id
    )
}

export const findPresetByPresetIdInArray = (
    arr: DescendantsHaveNodesOfPresetsProps[] | HasNodesOfPresetsProps[],
    presetId: number
) => {
    // Find index by comparing current node with each item in the descendants_have_nodes_of_presets array
    return arr.findIndex((item) => presetId === item.preset_id)
}

// Add presetNode data to node AND update hasDescendants arrays for all parent nodes
export const addPresetNodeDataToTree = (
    treeState: TreeItem[],
    node: TreeItem,
    newPresetNode: TreeItem
) => {
    const { id } = newPresetNode
    const newNode = addPresetDataToNodeObject(node, newPresetNode, id)
    const newTree = addHasDescendantsToParentNodes(treeState, node, id)
    return {
        treeData: changeNodeAtPath({
            newNode: newNode,
            treeData: newTree,
            path: node.path,
            getNodeKey: ({ node }) => node.id,
            ignoreCollapsed: false
        })
    }
}

// Add presetsData to Node
const addPresetDataToNodeObject = (node: TreeItem, presetNode: TreeItem, presetNodeId: number) => {
    node = adPresetNodeAndPresetNodeIdToNode(node, presetNode)
    node = addItemToHasNodesOfPresets(node, presetNodeId)
    return node
}

const adPresetNodeAndPresetNodeIdToNode = (node: TreeItem, presetNode: TreeItem) => {
    // Set presetNode Data
    const newNode = _.cloneDeep(node)
    newNode.presetNodeId = presetNode.id
    newNode.presetNode = presetNode
    return newNode
}

// fills has_nodes_of_presets array with new preset item
const addItemToHasNodesOfPresets = (node: TreeItem, presetNodeId: number) => {
    const item = { preset_id: node.presetId, preset_node_id: presetNodeId }

    // Clone has_nodes_of_presets (This is to prevent immutable object errors), Push item onto clone array,
    // then re-assign to node
    const hasNodesOfPresetsClone = Array.from(node.has_nodes_of_presets)
    hasNodesOfPresetsClone.push(item)
    node.has_nodes_of_presets = hasNodesOfPresetsClone

    // Return
    return node
}

const addHasDescendantsToParentNodes = (tree: TreeItem[], node: TreeItem, presetNodeId: number) => {
    const item = { preset_id: node.presetId, preset_node_id: presetNodeId }
    const path = Array.from(node.path) as string[]

    // Loop over each node in path
    while (path && path.length > 0) {
        // Remove last item of path array each iteration, first iteration is the node itself so we can skip that
        path.pop()

        // Get parent node data
        const currentNode = getNodeAtPath({
            treeData: tree,
            getNodeKey: ({ node }) => node.id,
            path: path,
            ignoreCollapsed: false
        })

        if (!!currentNode && !!currentNode.node.descendants_have_nodes_of_presets) {
            // Clone descendants_have_nodes_of_presets, push item and re-assign to original descendants_have_nodes_of_presets
            const descendantsClone = Array.from(
                currentNode?.node?.descendants_have_nodes_of_presets
            )
            descendantsClone.push(item)
            currentNode.node.descendants_have_nodes_of_presets = descendantsClone

            // Change node and re-assign to tree for next iteration
            tree = changeNodeAtPath({
                newNode: currentNode?.node,
                treeData: tree,
                path: path,
                getNodeKey: ({ node }) => node.id,
                ignoreCollapsed: false
            })
        }
    }
    return tree
}

export const removeVisibilityOverlay = (
    treeState: TreeItem[],
    node: TreeItem,
    presetNode: IPresetNode
): TreeItem[] => {
    // Remove presetNode from node
    if (!presetNode.definition || presetNode.definition === EMPTY_DEFINITION) {
        treeState = deleteHasDescendantsFromParentNodes(treeState, node)
        node = deletePresetNodeProps(node)
    } else {
        // Update removed prop only
        node.presetNode = presetNode
    }

    // Return new treeState
    return changeNodeAtPath({
        newNode: node,
        treeData: treeState,
        path: node?.path,
        getNodeKey: ({ node }) => node.id,
        ignoreCollapsed: false
    })
}

export const addVisibilityOverlay = (
    treeState: TreeItem[],
    node: TreeItem,
    presetNode: IPresetNode
): TreeItem[] => {
    // Add preset data to node and parent nodes
    node = adPresetNodeAndPresetNodeIdToNode(node, presetNode)
    node = addItemToHasNodesOfPresets(node, node.presetNodeId)
    treeState = addHasDescendantsToParentNodes(treeState, node, node.presetNodeId)

    // Return new treeState
    return changeNodeAtPath({
        newNode: node,
        treeData: treeState,
        path: node?.path,
        getNodeKey: ({ node }) => node.id,
        ignoreCollapsed: false
    })
}

export const expandNodesByPath = (tree: TreeItem[], node: TreeItem, key?: number): TreeItem[] => {
    const path = Array.from(node.path) as string[]

    if (!!path) {
        path.forEach(() => {
            // Remove last item of path array each iteration, first iteration is the node itself so we can skip that
            path.pop()

            // Get parent node data
            const parentNode = getNodeAtPath({
                treeData: tree,
                getNodeKey: ({ node }) => node.id,
                path: path,
                ignoreCollapsed: false
            })

            if (!!parentNode) {
                // Set expanded
                parentNode.node.expanded = true

                // Add key to props
                parentNode.node.react_key = key

                // Update expanded and tree
                tree = changeNodeAtPath({
                    newNode: parentNode?.node,
                    treeData: tree,
                    path: path,
                    getNodeKey: ({ node }) => node.id,
                    ignoreCollapsed: false
                })
            }
        })
    }
    // Return
    return tree
}
