import { useState } from 'react'
import { DashboardMenuItem, MenuProps, useTranslate } from 'react-admin'

import AccountTreeIcon from '@mui/icons-material/AccountTree'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ImageIcon from '@mui/icons-material/Image'

// import retailerClass from '../resources/retailerClass'
// import education from '../resources/education'
import packageGroup from '../resources/packageGroup'
import packages from '../resources/packages'
import retailerTag from '../resources/retailerTag'
// import retailerYear from '../resources/retailerYear'
import collections from '../resources/packageGroupCollection'
import personalization from '../resources/personalizationRule'
import presets from '../resources/presets'
import retailers from '../resources/retailers'
// import retailerClassRepresentative from '../resources/retailerClassRepresentative'
import { Box } from '@mui/material'
import { MenuItemLinkWrapper } from 'components/common/MenuItemLinkWrapper'
import SubMenu from './SubMenu'

type MenuName = 'menuPackages' | 'menuRetailers' | 'menuModels'

const Menu = ({ dense = false }: MenuProps) => {
    const translate = useTranslate()
    const [state, setState] = useState({
        menuPackages: false,
        menuModels: false,
        menuRetailers: false
    })
    const handleToggle = (menu: MenuName) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }))
    }

    return (
        <Box
            component="div"
            sx={{
                marginTop: 1,
                marginBottom: 1
                // transition: theme.transitions.create('width', {
                //     easing: theme.transitions.easing.sharp,
                //     duration: theme.transitions.duration.leavingScreen
                // })
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggleCallback={() => handleToggle('menuPackages')}
                isOpen={state.menuPackages}
                name={translate('manager.menu.package_manager')}
                icon={<ChevronRightIcon />}
                dense={dense}
            >
                <MenuItemLinkWrapper
                    name="Package"
                    to={{
                        pathname: '/packageGroupCollection',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.collections`)}
                    leftIcon={<collections.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'PackageGroup'}
                    to={{
                        pathname: '/packageGroup',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.packageGroup`)}
                    leftIcon={<packageGroup.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'PackageGroupCollection'}
                    to={{
                        pathname: '/Package',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.packages`)}
                    leftIcon={<packages.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggleCallback={() => handleToggle('menuRetailers')}
                isOpen={state.menuRetailers}
                name={translate('manager.menu.retailer_manager')}
                icon={<ChevronRightIcon />}
                dense={dense}
            >
                <MenuItemLinkWrapper
                    name={'Retailer'}
                    to={{
                        pathname: '/retailer',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.retailers`)}
                    leftIcon={<retailers.icon />}
                    dense={dense}
                />
                {/*<MenuItemLinkWrapper*/}
                {/*    name={'RetailerClass'}*/}
                {/*    to={{*/}
                {/*        pathname: '/retailerClass',*/}
                {/*        state: { _scrollToTop: true }*/}
                {/*    }}*/}
                {/*    primaryText={translate(`manager.menu.classes`)}*/}
                {/*    leftIcon={<retailerClass.icon />}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
                {/*<MenuItemLinkWrapper*/}
                {/*    name={'RetailerYear'}*/}
                {/*    to={{*/}
                {/*        pathname: '/retailerYear',*/}
                {/*        state: { _scrollToTop: true }*/}
                {/*    }}*/}
                {/*    primaryText={translate(`manager.menu.years`)}*/}
                {/*    leftIcon={<retailerYear.icon />}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
                {/*<MenuItemLinkWrapper*/}
                {/*    name={'Education'}*/}
                {/*    to={{*/}
                {/*        pathname: '/education',*/}
                {/*        state: { _scrollToTop: true }*/}
                {/*    }}*/}
                {/*    primaryText={translate(`manager.menu.educations`)}*/}
                {/*    leftIcon={<education.icon />}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
                <MenuItemLinkWrapper
                    name={'RetailerTag'}
                    to={{
                        pathname: '/retailerTag',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.tags`)}
                    leftIcon={<retailerTag.icon />}
                    dense={dense}
                />
                {/*<MenuItemLinkWrapper*/}
                {/*    name={'RetailerClassRepresentative'}*/}
                {/*    to={{*/}
                {/*        pathname: '/retailerClassRepresentative',*/}
                {/*        state: { _scrollToTop: true }*/}
                {/*    }}*/}
                {/*    primaryText={translate(`manager.menu.contactPersons`)}*/}
                {/*    leftIcon={<retailerClassRepresentative.icon />}*/}
                {/*    dense={dense}*/}
                {/*/>*/}
            </SubMenu>
            <SubMenu
                handleToggleCallback={() => handleToggle('menuModels')}
                isOpen={state.menuModels}
                name={translate('manager.menu.model_manager')}
                icon={<ChevronRightIcon />}
                dense={dense}
            >
                <MenuItemLinkWrapper
                    name={'Model'}
                    to={{
                        pathname: '/model',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.model`)}
                    leftIcon={<AccountTreeIcon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'Preset'}
                    to={{
                        pathname: '/preset',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.presets`)}
                    leftIcon={<presets.icon />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLinkWrapper
                name={'Rule'}
                to={{
                    pathname: '/rule',
                    state: { _scrollToTop: true }
                }}
                primaryText={translate(`manager.menu.personalization`)}
                leftIcon={<personalization.icon />}
                dense={dense}
            />
            <MenuItemLinkWrapper
                name={'Gallery'}
                to={{
                    pathname: '/gallery',
                    state: { _scrollToTop: true }
                }}
                primaryText={translate(`manager.menu.gallery`)}
                leftIcon={<ImageIcon />}
                dense={dense}
            />
        </Box>
    )
}

export default Menu
