import { useState } from 'react'
import { Create, useCreatePath, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import PackageGroupForm from './PackageGroupForm'

const PackageGroupCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)
    const createPath = useCreatePath()

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/packageGroup" />
            <Create
                title={translate('manager.resources.packageGroup.create')}
                {...props}
                redirect={createPath({ resource: 'packageGroup', type: 'list' })}
            >
                <PackageGroupForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default PackageGroupCreate
