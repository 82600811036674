import _ from 'lodash'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-admin'
import { TreeItem } from 'react-sortable-tree'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, ButtonProps, Tooltip } from '@mui/material'

import { useCreatePresetNodeMutation } from 'apollo/configurator/mutations/CreatePresetNode.generated'
import { useDeletePresetNodeMutation } from 'apollo/configurator/mutations/DeletePresetNode.generated'
import { useUpdatePresetNodeMutation } from 'apollo/configurator/mutations/UpdatePresetNode.generated'

import {
    addVisibilityOverlay,
    EMPTY_DEFINITION,
    removeVisibilityOverlay
} from 'components/presetManager/helpers/TreeHelperFunctions'
import { PresetModelContext } from 'context/preset-model/PresetModelContext'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'
import { INodeWithPreset, IPresetNode } from 'ts/interfaces'

interface VisibilityButtonProps {
    node: TreeItem
    presetId: number
    className?: string
    sx?: ButtonProps['sx']
    includingChildNodes?: boolean
    reRenderCallback: () => void
}

enum QueryType {
    CREATE,
    DELETE,
    UPDATE
}

const VisibilityButton = ({
    node,
    presetId,
    className,
    sx,
    includingChildNodes,
    reRenderCallback
}: VisibilityButtonProps) => {
    const DEBOUNCE_TIMER_MS = 250
    const translate = useTranslate()
    const [visible, setVisible] = useState(!!node?.presetNode?.removed)
    const { state: presetModelState, setState: setPresetModelState } =
        useContext(PresetModelContext)
    const [localTreeState, setLocalTreeState] = useState(presetModelState.treeState)

    const [createPresetNodeQuery] = useCreatePresetNodeMutation()
    const [deletePresetNodeQuery] = useDeletePresetNodeMutation()
    const [updatePresetNodeQuery] = useUpdatePresetNodeMutation()

    // Async function because we need toggleRemovedForSingleChildNode to be fired AFTER getQueryOperationType.
    // Otherwise nodeData is already updated and QueryType will be based on future data.
    const handleClick = async () => {
        // Toggle visibility
        setVisible(!visible)

        // State is not updated here so removed is opposite of what it actually is
        const isRemoved = !visible

        // local node variable to prevent referencing
        const localNode = _.cloneDeep(node)

        // Get type of operation (query) to perform
        await getQueryOperationType(localNode, isRemoved).then((queryType) => {
            // Toggle clicked node
            toggleRemovedForSingleChildNode(localNode, isRemoved, queryType)
        })

        // Toggle all child nodes
        if (includingChildNodes)
            await toggleRemovedForAllChildNodes(node, isRemoved).then(() => {
                // Re-render entire tree so the colors match for each child node. This unfortunately closes the tree aswell.
                reRenderCallback()
            })
    }

    // Get type of query based on the current values of the presetNode.
    const getQueryOperationType = async (
        treeNode: TreeItem,
        isRemoved: boolean
    ): Promise<QueryType> => {
        const hasPresetOverride = !!treeNode?.presetNode
        const presetOnlyConsistsOfVisiblityOverride =
            hasPresetOverride &&
            (treeNode.presetNode?.definition === EMPTY_DEFINITION ||
                !treeNode.presetNode?.definition)

        if (!hasPresetOverride) return QueryType.CREATE
        else
            return presetOnlyConsistsOfVisiblityOverride && !isRemoved
                ? QueryType.DELETE
                : QueryType.UPDATE
    }

    // Loops over all children of the clicked node and updates child nodes
    const toggleRemovedForAllChildNodes = async (treeNode: TreeItem, isRemoved: boolean) => {
        _.cloneDeepWith(treeNode, (value) => {
            if (value?.hasOwnProperty('children') && _.isArray(value?.children)) {
                if (value?.children) {
                    value.children.forEach(async (n) => {
                        await getQueryOperationType(n, isRemoved).then((queryType) => {
                            toggleRemovedForSingleChildNode(n, isRemoved, queryType)
                        })
                    })
                }
            }
        })
    }

    // Executes a query based on querytype which will create, delete or update a preset.
    const toggleRemovedForSingleChildNode = (
        treeNode: TreeItem,
        isRemoved: boolean,
        queryType: QueryType
    ) => {
        switch (queryType) {
            case QueryType.CREATE:
                createPresetNode(parseInt(treeNode?.id), isRemoved)
                break
            case QueryType.DELETE:
                deletePresetNode(treeNode?.presetNode)
                break
            case QueryType.UPDATE:
                updatePresetNode(isRemoved, treeNode?.presetNode)
                break
        }
    }

    const handleRemoveOverlay = (presetNode: IPresetNode) => {
        // Add overlay based on the "removed" value of the presetNode and return the new tree
        const newTree = removeVisibilityOverlay(localTreeState, node, presetNode)

        setLocalTreeState(newTree)

        // Update tree in state
        setPresetModelState((prev) => ({
            ...prev,
            treeState: newTree,
            reRenderTree: true,
            hasChanges: true,
            selectedNode: node as unknown as INodeWithPreset
        }))
    }

    const handleAddOverlay = (presetNode: IPresetNode) => {
        // Add overlay based on the "removed" value of the presetNode and return the new tree
        const newTree = addVisibilityOverlay(presetModelState.treeState, node, presetNode)

        setLocalTreeState(newTree)

        // Update tree in state
        setPresetModelState((prev) => ({
            ...prev,
            treeState: newTree,
            reRenderTree: true,
            hasChanges: true,
            selectedNode: node as unknown as INodeWithPreset
        }))
    }

    /*
     * Query's
     */
    const createPresetNode = async (parentNodeId: number, isRemoved: boolean) => {
        // Initialize variables
        const variables: any = {
            definition: EMPTY_DEFINITION,
            presetId: presetId,
            parentNodeId: parentNodeId,
            removed: isRemoved
        }

        // Create a new preset ONLY for the removed field override.
        await createPresetNodeQuery({ variables: variables }).then(({ data }) => {
            // Only for single node visiblity button
            if (data?.configuratorCreatePresetNode && !includingChildNodes) {
                // Add color overlay after creating
                handleAddOverlay(data.configuratorCreatePresetNode)
            }
        })
    }

    const updatePresetNode = (isRemoved: boolean, configuratorPresetNode?: IPresetNode) => {
        if (!configuratorPresetNode) return

        // Initialize variables
        const variables: any = {
            definition: configuratorPresetNode?.definition,
            configuratorUpdatePresetNodeId: configuratorPresetNode?.id,
            removed: isRemoved
        }

        // Existing preset. Update removed variable.
        updatePresetNodeQuery({ variables: variables }).then((data) => {
            const presetNode = data?.data?.configuratorUpdatePresetNode as IPresetNode

            // Only for single node visiblity button
            if (!includingChildNodes) {
                // Remove or add color overlay after updating
                if (presetNode?.removed) handleAddOverlay(presetNode)
                else handleRemoveOverlay(presetNode)
            }
        })
    }

    const deletePresetNode = (presetNode?: IPresetNode) => {
        if (!presetNode) return

        deletePresetNodeQuery({ variables: { id: presetNode.id } }).then(() => {
            // Only for single node visiblity button
            if (!includingChildNodes) {
                // Remove color overlay after updating
                handleRemoveOverlay(presetNode)
            }
        })
    }

    return (
        <Tooltip
            arrow
            placement="left"
            title={
                includingChildNodes
                    ? translate('manager.resources.preset.manager.hide_multiple_nodes')
                    : translate('manager.resources.preset.manager.hide_single_node')
            }
        >
            <Button
                // Use debounce to minimize handleClick calls when excessive button clicking
                onClick={_.debounce(() => {
                    handleClick()
                }, DEBOUNCE_TIMER_MS)}
                sx={sx}
                className={className}
            >
                {!visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                {includingChildNodes && (
                    <Box
                        component="p"
                        sx={{
                            position: 'relative',
                            alignSelf: 'center',
                            textAlign: 'center',
                            width: '100%',
                            fontSize: '11px',
                            marginLeft: '1px',
                            color: `${proudNerdsTheme.palette.text.secondary}`
                        }}
                    >
                        {translate('manager.resources.preset.manager.all')}
                    </Box>
                )}
            </Button>
        </Tooltip>
    )
}

export default VisibilityButton
