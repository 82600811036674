import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagesPackageGalleryItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PackagesPackageGalleryItemFilterInput>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  perPage: Types.Scalars['Int'];
  orderBy?: Types.InputMaybe<Array<Types.PackagesQueryPackageGalleryItemsOrderByOrderByClause> | Types.PackagesQueryPackageGalleryItemsOrderByOrderByClause>;
}>;


export type PackagesPackageGalleryItemsQuery = { __typename?: 'Query', data?: { __typename?: 'packagesPackageGalleryItemPaginator', data: Array<{ __typename?: 'packagesPackageGalleryItem', id: string, alt: string, created_at: any, package_id: number, path: string, title: string, updated_at: any, url?: string | null }>, paginatorInfo: { __typename?: 'packagesPaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } | null };


export const PackagesPackageGalleryItemsDocument = gql`
    query packagesPackageGalleryItems($filter: packagesPackageGalleryItemFilterInput, $page: Int, $perPage: Int!, $orderBy: [packagesQueryPackageGalleryItemsOrderByOrderByClause!]) {
  data: packagesPackageGalleryItems(
    filter: $filter
    page: $page
    first: $perPage
    orderBy: $orderBy
  ) {
    data {
      id
      alt
      created_at
      package_id
      path
      title
      updated_at
      url
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePackagesPackageGalleryItemsQuery__
 *
 * To run a query within a React component, call `usePackagesPackageGalleryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesPackageGalleryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesPackageGalleryItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagesPackageGalleryItemsQuery(baseOptions: Apollo.QueryHookOptions<PackagesPackageGalleryItemsQuery, PackagesPackageGalleryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesPackageGalleryItemsQuery, PackagesPackageGalleryItemsQueryVariables>(PackagesPackageGalleryItemsDocument, options);
      }
export function usePackagesPackageGalleryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesPackageGalleryItemsQuery, PackagesPackageGalleryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesPackageGalleryItemsQuery, PackagesPackageGalleryItemsQueryVariables>(PackagesPackageGalleryItemsDocument, options);
        }
export type PackagesPackageGalleryItemsQueryHookResult = ReturnType<typeof usePackagesPackageGalleryItemsQuery>;
export type PackagesPackageGalleryItemsLazyQueryHookResult = ReturnType<typeof usePackagesPackageGalleryItemsLazyQuery>;
export type PackagesPackageGalleryItemsQueryResult = Apollo.QueryResult<PackagesPackageGalleryItemsQuery, PackagesPackageGalleryItemsQueryVariables>;