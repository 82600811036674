import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Card, CardActions, CircularProgress, createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Head } from 'Head'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Notification, useTranslate } from 'react-admin'
import { useForm } from 'react-hook-form'
import backgroundimage from '../img/background.jpg'
import Logo from './Logo'
import { darkTheme } from './theme/DarkTheme'
import { lightTheme } from './theme/LightTheme'
import { proudNerdsTheme } from './theme/ProudNerdsTheme'

interface FormValues {
    username?: string
    password?: string
    permissions?: string
}

const Login = () => {
    const [loading] = useState(false)
    const translate = useTranslate()
    const { loginWithRedirect } = useAuth0()
    const { handleSubmit } = useForm<FormValues>()

    const onSubmit = () => {
        loginWithRedirect()
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: `url(${backgroundimage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <Card
                    sx={{
                        minWidth: 300,
                        marginTop: '6em'
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Logo />
                    </Box>
                    <CardActions
                        sx={{
                            padding: '0 1em 1em 1em'
                        }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && <CircularProgress size={25} thickness={2} />}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </Card>
                <Notification />
            </Box>
        </form>
    )
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string
}

const theme = () => {
    let newTheme

    switch (process.env.REACT_APP_THEME) {
        case 'dark':
            newTheme = darkTheme
            break
        case 'light':
            newTheme = lightTheme
            break
        case 'proudNerds':
            newTheme = proudNerdsTheme
            break
        default:
            newTheme = lightTheme
    }

    return newTheme
}

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createTheme(theme())}>
        <Head />
        <Login {...props} />
    </ThemeProvider>
)

export default LoginWithTheme
