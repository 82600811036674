/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react'

export type InitialErrorStateType = {
    message: string
    hasError: boolean
}

export interface IInitialState {
    state: InitialErrorStateType
    setErrorState: (state: InitialErrorStateType) => void
}

const initialState = {
    state: {
        message: '',
        hasError: false
    },
    setErrorState: (_state: InitialErrorStateType) => {}
}

const ErrorContext = createContext(initialState)

const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setErrorState] = useState(initialState.state)
    return (
        <ErrorContext.Provider value={{ state, setErrorState }}>{children}</ErrorContext.Provider>
    )
}

export { ErrorContext, ErrorProvider, initialState }
