import { BulkDeleteButton, useListContext } from 'react-admin'

import { Delete } from '@mui/icons-material'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

// Bulk delete with Confirm
type BulkActionButtonsProps = {
    isWithActionsFlush?: boolean
    customize?: {
        mutation?: (selectedIds: string[]) => Promise<void>
        onEnd?: () => void
    }
} & React.ComponentProps<typeof BulkDeleteButton>
const BulkActionButtons = ({
    isWithActionsFlush,
    customize: { mutation: customMutation, onEnd: customOnEnd } = {},
    ...props
}: BulkActionButtonsProps) => {
    const [flushActionsCache] = useFlushActionsCacheMutation()
    const { selectedIds, onSelect } = useListContext()

    const handleDeleteButtonClick = () => (isWithActionsFlush ? flushActionsCache() : undefined)

    if (customMutation) {
        return (
            <Delete
                sx={{
                    color: 'error.main',
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}
                onClick={async () => {
                    await customMutation(selectedIds)
                    await handleDeleteButtonClick()
                    onSelect([])
                    customOnEnd && customOnEnd()
                }}
            />
        )
    }

    return <BulkDeleteButton onClick={handleDeleteButtonClick} {...props} />
}
export default BulkActionButtons
