import { Dialog, Typography } from '@mui/material'
import { RaRecord, useRefresh } from 'ra-core'
import { useState } from 'react'
import { Create, Datagrid, FunctionField, List, SimpleForm, useTranslate } from 'react-admin'
import styled from 'styled-components'

import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import RetailerPeriodFormInputs from './RetailerPeriodFormInputs'
import RetailerPeriodListToolbar from './RetailerPeriodListToolbar'

type RetailerPeriodListProps = {
    filter?: RaRecord
}

const RetailerPeriodList = (props: RetailerPeriodListProps) => {
    const RESOURCE = 'RetailerPeriod' as const

    // for some reason we need a small r here
    const resourceTranslation = 'retailerPeriod'
    const [open, setOpen] = useState(false)
    const reload = useRefresh()
    const translate = useTranslate()
    const retailerId = props?.filter?.retailer_id

    // Toggle modal state
    const toggleModal = (): void => {
        setOpen(!open)
    }

    // Closes modal and re-renders after saving
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    return (
        <>
            <ListWrapper>
                <List
                    resource={RESOURCE}
                    actions={
                        <RetailerPeriodListToolbar
                            resource={resourceTranslation}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    sx={{
                        width: '100%',
                        margin: '2rem 0'
                    }}
                    sort={{ field: 'from_date', order: 'ASC' }}
                    filter={props.filter}
                >
                    <Datagrid
                        body={<CustomDatagridBody bulkActionButtons={false} />}
                        rowClick="edit"
                    >
                        <DateFieldCustom
                            label={translate(`manager.resources.${resourceTranslation}.from_date`)}
                            source="from_date"
                            transform={(value) => formatDateString(value)}
                        />
                        <DateFieldCustom
                            label={translate(`manager.resources.${resourceTranslation}.to_date`)}
                            source="to_date"
                            transform={(value) => formatDateString(value)}
                        />
                        <DateFieldCustom
                            label={translate(
                                `manager.resources.${resourceTranslation}.grace_payment_date`
                            )}
                            source="grace_payment_date"
                            transform={(value) => formatDateString(value)}
                        />
                        <FunctionField
                            source="active"
                            label={translate('manager.resources.general.status')}
                            render={(record: RaRecord) => (
                                <DatagridPublishedColumnContent record={record} />
                            )}
                        />
                        <DateFieldCustom
                            label={translate('manager.resources.general.last_updated')}
                            source="updated_at"
                            showTime
                            transform={(value) => formatDateString(value, true)}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <Dialog open={open} onClose={() => toggleModal()}>
                <Create resource={RESOURCE} mutationOptions={{ onSuccess: handleSuccess }}>
                    <SimpleForm
                        sx={{
                            width: 500
                        }}
                        defaultValues={{ retailer_id: retailerId }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {translate(`manager.resources.${resourceTranslation}.add`)}
                        </Typography>
                        <RetailerPeriodFormInputs />
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

export default RetailerPeriodList
