import DefaultToolbar from 'components/common/DefaultToolbar'
import { JSX } from 'react'
import { TabbedForm, useTranslate } from 'react-admin'
import { useLocation, useNavigate } from 'react-router'
import { ConfigTabContent } from './ConfigTabContent'
import { GeneralTabContent } from './GeneralTabContent'

const GENERAL_TAB_PATH = 'general'

export const PackageCreateForm: ({
    packageType,
    setPackageType,
    packageSchema,
    loading
}) => JSX.Element = ({ packageType, setPackageType, packageSchema, loading }) => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const handleSubmit = (hasError: boolean) => {
        if (hasError && !location.pathname.includes(GENERAL_TAB_PATH)) {
            navigate(location.pathname + GENERAL_TAB_PATH)
        }
    }

    return (
        <TabbedForm toolbar={<DefaultToolbar isWithActionsFlush onClick={handleSubmit} />}>
            <TabbedForm.Tab label={translate('manager.tabs.config')}>
                <ConfigTabContent
                    isEdit={false}
                    packageSchema={packageSchema}
                    loading={loading}
                    setPackageType={setPackageType}
                />
            </TabbedForm.Tab>
            <TabbedForm.Tab label={translate('manager.tabs.general')} path={GENERAL_TAB_PATH}>
                <GeneralTabContent packageType={packageType} />
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
