import Inventory2Icon from '@mui/icons-material/Inventory2'
import PackageCreate from './PackageCreate/PackageCreate'
import PackageEdit from './PackageEdit/PackageEdit'
import PackageList from './PackageList'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PackageList,
    edit: PackageEdit,
    create: PackageCreate,
    icon: Inventory2Icon
}
