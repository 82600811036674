import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { useEffect, useState } from 'react'
import {
    SaveButton,
    Toolbar,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRedirect,
    useRefresh,
    useResourceContext,
    useTranslate
} from 'react-admin'

import { useCreateRetailerClassRetailerTagMutation } from 'apollo/retailer_manager/mutations/CreateRetailerClassRetailerTag.generated'
import { useDeleteRetailerClassRetailerTagMutation } from 'apollo/retailer_manager/mutations/DeleteRetailerClassRetailerTag.generated'
import { useRetailersRetailerClassQuery } from 'apollo/retailer_manager/queries/RetailersRetailerClass.generated'

import { ChoiceType } from 'types'

export const styles = {
    toolbar: { marginTop: 0 },
    button: { marginRight: '1rem' }
}

const RetailerClassEditToolbar = (props) => {
    const translate = useTranslate()
    const redirect = useRedirect()
    const refresh = useRefresh()
    const notify = useNotify()
    const resource = useResourceContext()
    const dataProvider = useDataProvider()
    const [createRetailerClassRetailerTag] = useCreateRetailerClassRetailerTagMutation()
    const [deleteRetailerClassRetailerTag] = useDeleteRetailerClassRetailerTagMutation()
    const [initialTags, setInitialTags] = useState<ChoiceType[]>([])
    const record = useRecordContext()

    // Retreive all tags related to this retailerId
    const { data: retailerClass } = useRetailersRetailerClassQuery({
        variables: {
            id: (record?.id || 0).toString()
        }
    })

    // Format and set values
    useEffect(() => {
        if (retailerClass?.retailersRetailerClass?.tags) {
            setInitialTags(
                retailerClass.retailersRetailerClass.tags.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.tag
                    })
                )
            )
        }
    }, [retailerClass])

    // Custom save so that we can mutate the tags seperately
    const handleSave = (values, redirectAfterSave) => {
        const currentTags = values.tags
        const retailerClassId = values.id

        // DELETED values
        const deletedDiff =
            initialTags &&
            initialTags.filter((a) => {
                return !currentTags.find((b) => a.id === b.id)
            })

        // ADDED values
        const addedDiff =
            currentTags &&
            currentTags.filter((a) => {
                return !initialTags.find((b) => a.id === b.id)
            })

        if (deletedDiff) {
            // Delete relation for removed tags
            deletedDiff.forEach((tag) => {
                deleteRetailerClassRetailerTag({
                    variables: {
                        retailerClassId: parseInt(retailerClassId),
                        retailerTagId: parseInt(tag.id)
                    }
                })
            })
        }

        if (addedDiff) {
            // Add relation for new tags
            addedDiff.forEach((tag) => {
                createRetailerClassRetailerTag({
                    variables: {
                        retailerClassId: parseInt(retailerClassId),
                        retailerTagId: parseInt(tag.id)
                    }
                }).catch((error) => {
                    console.error(error)
                })
            })
        }

        // Update retailer
        dataProvider
            .update(props.resource, {
                id: values.id,
                data: values,
                previousData: record
            })
            .then(() => {
                if (redirectAfterSave) {
                    redirect(props.basePath)
                    refresh()
                }
            })
            .finally(() =>
                notify(
                    `${props.resource} ${translate(
                        'manager.resources.general.edited_successfully'
                    )}`
                )
            )
    }

    return (
        <Toolbar {...props} sx={{ marginTop: 0 }}>
            <SaveButton
                sx={{
                    marginRight: '1rem'
                }}
                label={translate('manager.resources.general.save')}
                onSubmit={(values) => {
                    handleSave(values, false)
                }}
            />
            <SaveButton
                label={translate('manager.resources.general.save_and_go_back')}
                color="secondary"
                icon={<SaveAltIcon />}
                mutationOptions={{
                    onSuccess: () => {
                        redirect('list', resource)
                    }
                }}
            />
        </Toolbar>
    )
}

export default RetailerClassEditToolbar
