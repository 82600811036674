import { Card, CardContent, CardHeader } from '@mui/material'

const Dashboard: React.FC = (props) => {
    return (
        <Card {...props}>
            <CardHeader title="Dashboard" />
            <CardContent>Welcome to the Manager</CardContent>
        </Card>
    )
}

export default Dashboard
