import { Edit, Loading, required, SimpleForm, TextInput, useTranslate } from 'react-admin'

import { usePackagesPackageGalleryItemQuery } from 'apollo/package_manager/queries/PackageGalleryItem.generated'

import { Typography } from '@mui/material'

import UploadField from 'components/formfields/uploadField/UploadField'
import { useNavigate, useParams } from 'react-router'
import PackageGalleryItemEditToolbar from './PackageGalleryItemEditToolbar'

const PackageGalleryItemEdit = (props) => {
    const RESOURCE = 'PackageGalleryItem'
    const params = useParams()
    const id = params?.id ?? ''
    const translate = useTranslate()

    // do not use redirect from useRedirect (React-Admin) here because it will fail to load the images in the list on redirect
    const navigate = useNavigate()
    const onSuccess = (response: any) => {
        navigate(`/Package/${response.package_id}/images`)
    }

    const record = usePackagesPackageGalleryItemQuery({
        variables: {
            packagesPackageGalleryItemId: id
        }
    })

    if (!record.data?.packagesPackageGalleryItem) return <Loading />

    return (
        <Edit
            {...props}
            undoable={false}
            redirect={false}
            resource={RESOURCE}
            mutationOptions={{ onSuccess }}
            mutationMode="pessimistic"
            basePath={props.basepath}
            title={translate(`manager.resources.${RESOURCE}.edit`)}
        >
            <SimpleForm
                defaultValues={{ id: props?.location?.state?.id }}
                toolbar={<PackageGalleryItemEditToolbar resource={RESOURCE} />}
            >
                <Typography variant="h6" gutterBottom>
                    {translate(`manager.resources.${RESOURCE}.edit`)}
                </Typography>
                <TextInput
                    source="title"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.title`)}
                    validate={requiredValidate}
                />
                <TextInput
                    source="alt"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.alt`)}
                    validate={requiredValidate}
                />
                <UploadField
                    source="path"
                    label={translate(`manager.resources.${RESOURCE}.path`)}
                    data={record.data?.packagesPackageGalleryItem?.path}
                    url={record.data?.packagesPackageGalleryItem?.path ?? ''}
                    validateRequired
                />
            </SimpleForm>
        </Edit>
    )
}

const requiredValidate = [required()]

export default PackageGalleryItemEdit
