import React, { createContext, useState } from 'react'
import { TreeItem } from 'react-sortable-tree'
import { INodeWithPreset } from 'ts/interfaces'

export type PresetModelInitialStateProps = {
    hasChanges: boolean
    hasEdited: boolean
    currentModel: string
    currentModelCode: string
    updatedModel: boolean
    showEditedMessage: boolean
    treeState: TreeItem[]
    selectedNode: INodeWithPreset
    reRenderForm: boolean
    reRenderTree: boolean
}

const presetModelInitialState = {
    state: {
        hasChanges: true,
        hasEdited: false,
        currentModel: '',
        currentModelCode: '',
        updatedModel: false,
        showEditedMessage: false,
        treeState: [] as TreeItem[],
        selectedNode: {} as INodeWithPreset,
        reRenderForm: false,
        reRenderTree: false
    },
    setState: () => {}
}

const PresetModelContext = createContext<{
    state: PresetModelInitialStateProps
    setState: React.Dispatch<React.SetStateAction<PresetModelInitialStateProps>>
}>(presetModelInitialState)

const PresetModelProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}): React.ReactElement => {
    const [state, setState] = useState(presetModelInitialState.state)

    return (
        <PresetModelContext.Provider value={{ state, setState }}>
            {children}
        </PresetModelContext.Provider>
    )
}

export { PresetModelContext, presetModelInitialState, PresetModelProvider }
