import { required, useRecordContext } from 'ra-core'
import { useEffect, useState } from 'react'
import { BooleanInput, DateInput, useTranslate } from 'react-admin'

import { InputVariant } from '../../types'

const RetailerPeriodFormInputs = (props) => {
    const translate = useTranslate()
    const record = useRecordContext()

    // Parse date to yyyy-mm-dd format
    const parse = (date: string) => {
        if (date === '') {
            return null
        }
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear()
        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day
        return [year, month, day].join('-')
    }

    /**
     * Date incrementation/decrementation function
     * @param dateStr date in format "yyyy-mm-dd"
     * @param operation incrementaion ('+') or decrementation ('-')
     * @returns date in format "yyyy-mm-dd" incremented/decremented by one day
     */
    const crementDate = (dateStr: string, operation: '+' | '-') => {
        var parts = dateStr.split('-')
        var dt = new Date(
            parseInt(parts[0], 10), // year
            parseInt(parts[1], 10) - 1, // month (starts with 0)
            parseInt(parts[2], 10) // date
        )
        operation === '+' ? dt.setDate(dt.getDate() + 1) : dt.setDate(dt.getDate() - 1)
        parts[0] = '' + dt.getFullYear()
        parts[1] = '' + (dt.getMonth() + 1)
        if (parts[1].length < 2) {
            parts[1] = '0' + parts[1]
        }
        parts[2] = '' + dt.getDate()
        if (parts[2].length < 2) {
            parts[2] = '0' + parts[2]
        }
        return parts.join('-')
    }

    const [maxFromDate, setMaxFromDate] = useState('')
    const [minFromDate, setMinFromDate] = useState('')

    const handleFromDateInputChange = (e) => {
        const incrementedDate = crementDate(e.target.value, '+')
        setMaxFromDate(incrementedDate)
    }
    const handleToDateInputChange = (e) => {
        const decrementedDate = crementDate(e.target.value, '-')
        setMinFromDate(decrementedDate)
    }
    useEffect(() => {
        if (record?.from_date) {
            const incrementedDate = crementDate(record.from_date, '+')
            setMaxFromDate(incrementedDate)
        }
    }, [record?.from_date])

    useEffect(() => {
        if (record?.to_date) {
            const decrementedDate = crementDate(record.to_date, '-')
            setMinFromDate(decrementedDate)
        }
    }, [record?.to_date])

    return (
        <>
            <DateInput
                parse={parse}
                source="from_date"
                label={translate(`manager.resources.retailerPeriod.from_date`)}
                fullWidth
                validate={requiredValidate}
                variant={InputVariant.OUTLINED}
                inputProps={{
                    max: minFromDate
                }}
                onChange={handleFromDateInputChange}
            />
            <DateInput
                parse={parse}
                source="to_date"
                label={translate(`manager.resources.retailerPeriod.to_date`)}
                fullWidth
                validate={requiredValidate}
                variant={InputVariant.OUTLINED}
                inputProps={{
                    min: maxFromDate
                }}
                onChange={handleToDateInputChange}
            />
            <DateInput
                parse={parse}
                source="grace_payment_date"
                label={translate(`manager.resources.retailerPeriod.grace_payment_date`)}
                fullWidth
                variant={InputVariant.OUTLINED}
                inputProps={{
                    min: parse(record?.to_date)
                }}
            />
            <BooleanInput
                source="active"
                label={translate('manager.resources.general.published')}
                row
                defaultValue={true}
            />
        </>
    )
}

const requiredValidate = [required()]

export default RetailerPeriodFormInputs
