import education from 'resources/education'
import collections from '../resources/packageGroupCollection'
import products from '../resources/packageProduct'
import packages from '../resources/packages'
import presets from '../resources/presets'
import retailerClass from '../resources/retailerClass'
import retailerClassRepresentative from '../resources/retailerClassRepresentative'
import retailerPeriod from '../resources/retailerPeriod'
import retailer from '../resources/retailers'
import retailerTag from '../resources/retailerTag'
import retailerYear from '../resources/retailerYear'
import packageGalleryItem from '../resources/packageGalleryItem'
import packageGroup from '../resources/packageGroup'
import packageGroupCollectionGalleryItem from '../resources/packageGroupCollectionGalleryItem'
import packageGroupGalleryItem from '../resources/packageGroupGalleryItem'
import rule from '../resources/personalizationRule'

export const resources = {
    Education: education,
    Package: packages,
    PackageGalleryItem: packageGalleryItem,
    PackageGroupCollection: collections,
    PackageProduct: products,
    PackageGroup: packageGroup,
    PackageGroupCollectionGalleryItem: packageGroupCollectionGalleryItem,
    PackageGroupGalleryItem: packageGroupGalleryItem,
    Rule: rule,
    Preset: presets,
    Retailer: retailer,
    RetailerClass: retailerClass,
    RetailerClassRepresentative: retailerClassRepresentative,
    RetailerPeriod: retailerPeriod,
    RetailerTag: retailerTag,
    RetailerYear: retailerYear
}

export type ResourceKey = keyof typeof resources
