import { darken, lighten } from '@mui/material'
import { COLORS, DEFAULT_TONAL_OFFSET } from './colors'

export const proudNerdsTheme = {
    palette: {
        primary: {
            main: COLORS.theme.proud_nerds_gold.main,
            light: COLORS.theme.proud_nerds_gold.light,
            dark: COLORS.theme.proud_nerds_black.light,
            contrastText: COLORS.white
        },
        secondary: {
            main: COLORS.theme.proud_nerds_black.main,
            light: COLORS.theme.proud_nerds_grey.dark,
            dark: COLORS.theme.proud_nerds_black.light,
            contrastText: COLORS.white
        },
        error: {
            main: COLORS.theme.error_red.main,
            light: COLORS.theme.error_red.light,
            dark: COLORS.theme.error_red.dark,
            contrastText: COLORS.white
        },
        warning: {
            main: COLORS.theme.warning_yellow.main,
            light: COLORS.theme.warning_yellow.light,
            dark: COLORS.theme.warning_yellow.dark,
            contrastText: COLORS.white
        },
        background: {
            default: darken(COLORS.white, 0.01)
        },
        text: {
            primary: lighten(COLORS.black, DEFAULT_TONAL_OFFSET),
            secondary: lighten(COLORS.black, 0.5)
        },
        type: 'light' as 'light'
    },
    shape: {
        borderRadius: 10
    },
    sidebar: {
        width: 220
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    minWidth: '2rem'
                },
                contained: {
                    backgroundColor: COLORS.white,
                    color: COLORS.theme.proud_nerds_gold.main,
                    boxShadow: 'none'
                },
                containedPrimary: {
                    color: COLORS.white,
                    backgroundColor: COLORS.theme.proud_nerds_gold.main,
                    boxShadow: 'none'
                }
            }
        },
        RaLayout: {
            styleOverrides: {
                content: {
                    paddingBottom: '4rem'
                }
            }
        },
        RaEdit: {
            styleOverrides: {
                root: {
                    marginBottom: '2rem'
                }
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: `none`
                },
                active: {
                    borderLeft: `0.5rem solid ${COLORS.theme.proud_nerds_gold.main}`
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    backgroundColor: COLORS.white,
                    borderRight: `0.1rem solid ${COLORS.theme.grey.light}`
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none'
                },
                root: {
                    border: `0.1rem solid ${COLORS.theme.grey.light}`,
                    backgroundClip: 'padding-box'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&:hover:active::after': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'currentColor',
                        opacity: 0.3,
                        borderRadius: 'inherit'
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: COLORS.theme.proud_nerds_gold.main
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: lighten(COLORS.black, 0.5),
                    backgroundColor: COLORS.white
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    border: 'none',
                    justifyContent: 'center',
                    alignSelf: 'center'
                },
                message: {
                    color: COLORS.white
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    width: '100%',
                    marginTop: 0,
                    bottom: 0
                }
            }
        },
        MuiTableCellHead: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold'
                }
            }
        }
    }
}

export type ProudNerdsTheme = typeof proudNerdsTheme
