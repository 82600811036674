import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fab from '@mui/material/Fab'
import Input from '@mui/material/Input'

import { useDuplicateModelMutation } from 'apollo/configurator/mutations/DuplicateModel.generated'
import { RootNodesDocument } from 'apollo/configurator/queries/RootNodes.generated'
import { ErrorContext } from 'context/error/ErrorContext'
import React, { ChangeEvent, useContext } from 'react'

interface IDuplicateModelButton {
    id: number
}

const DuplicateModelButton = ({ id }: IDuplicateModelButton) => {
    const { setErrorState } = useContext(ErrorContext)
    const [inputValue, setInputValue] = React.useState('')
    const [inputError, setInputError] = React.useState(true)

    const [duplicateModel, { loading }] = useDuplicateModelMutation({
        onError(error) {
            setErrorState({ hasError: true, message: error.message })
        },
        refetchQueries: [{ query: RootNodesDocument }]
    })

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        const pattern = /^[\w]{3,}$/
        if (pattern.test(value)) {
            setInputError(false)
            setInputValue(value)
        } else {
            setInputError(true)
            setInputValue(value)
        }
    }

    const handleConfirm = () => {
        if (inputError) return
        duplicateModel({
            variables: { source: +id as unknown as number, destination: inputValue }
        }).then(() => {
            setInputValue('')
            setInputError(true)
        })
        setOpen(false)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 1
                }}
            >
                <Box
                    sx={{
                        margin: 4,
                        position: 'relative'
                    }}
                >
                    <Fab aria-label="save" color="primary" onClick={handleClickOpen}>
                        <FileCopyIcon />
                    </Fab>
                    {loading && (
                        <CircularProgress
                            size={68}
                            sx={{
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1
                            }}
                        />
                    )}
                </Box>
            </Box>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {'Please add a code for the new model'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Only letters and underscores allowed.
                        <br />
                        Duplication process can take up to a few minutes depending on the complexity
                        of the model.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-input-wrapper">
                        <Input
                            value={inputValue}
                            onChange={inputChange}
                            inputProps={{ 'aria-label': 'new model code' }}
                            error={inputError}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        Duplicate
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DuplicateModelButton
