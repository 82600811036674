import { RaRecord, useNotify } from 'ra-core'
import {
    Datagrid,
    FunctionField,
    List,
    Pagination,
    TextField,
    useRedirect,
    useTranslate
} from 'react-admin'

import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'

import FileCopyIcon from '@mui/icons-material/FileCopy'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@mui/material'
import Chip from '@mui/material/Chip'
import MaterialTextfield from '@mui/material/TextField'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import { ChangeEvent, MouseEvent, useState } from 'react'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { useDuplicatePackageGroupMutation } from '../../apollo/package_manager/mutations/DuplicatePackageGroup.generated'
import PackageGroupListFilter from './PackageGroupListFilters'

type PackageGroupListProps = {
    filter?: Record<string, any>
}

const PackageGroupList = (props: PackageGroupListProps) => {
    const RESOURCE = 'PackageGroup'
    const translate = useTranslate()
    const sx = {
        chip: { marginRight: '1px' },
        list: {
            '& .MuiToolbar-gutters': { width: 'auto' },
            '& .RaDatagrid-headerCell': {
                fontWeight: 700
            }
        },
        listInCollection: {
            width: '100%',
            '& .MuiPaper-root': { border: 0 }
        },
        fileIcon: {
            '&:hover': {
                transform: `scale(1.1)`
            }
        },
        dialogActions: {
            padding: 0,
            paddingBottom: '1rem'
        },
        spinner: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px'
        }
    }
    const [duplicateId, setDuplicateId] = useState('')
    const [publicName, setPublicName] = useState('')
    const [internalName, setInternalName] = useState('')
    const [open, setOpen] = useState(false)
    const [duplicatePackageGroupMutation, { loading: isLoadingDuplication }] =
        useDuplicatePackageGroupMutation()
    const redirect = useRedirect()
    const notify = useNotify()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handlePublicNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublicName(e.target.value)
    }

    const handleInternalNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInternalName(e.target.value)
    }

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        setPublicName('')
        setInternalName('')
        setDuplicateId(id)
        togglePopup()
    }

    const handlePopupSubmit = () => {
        duplicatePackageGroupMutation({
            variables: { id: duplicateId, internal_name: internalName, public_name: publicName }
        })
            .then((result) => {
                redirect('edit', '/PackageGroup', result.data?.duplicatePackageGroup?.id)
            })
            .catch((error) => notify(error.message, { type: 'error' }))
    }

    return (
        <>
            <List
                resource={RESOURCE}
                sort={{ field: 'internal_name', order: 'ASC' }}
                empty={false}
                perPage={DEFAULT_PER_PAGE}
                filter={props?.filter}
                filters={PackageGroupListFilter}
                sx={{
                    ...sx.list,
                    ...(props?.filter && sx.listInCollection)
                }}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid
                    body={
                        <CustomDatagridBody
                            bulkActionButtons={<BulkActionButtons isWithActionsFlush />}
                        />
                    }
                    rowClick="edit"
                >
                    <TextField
                        label={translate('manager.resources.packageGroup.public_name')}
                        source="public_name"
                    />
                    <TextField
                        label={translate('manager.resources.packageGroup.internal_name')}
                        source="internal_name"
                    />
                    <FunctionField
                        label={translate('manager.resources.packageGroup.product_preset')}
                        render={(record: RaRecord) =>
                            record?.presets && record.presets.length > 0 ? (
                                record.presets.map((t) => (
                                    <Chip size="small" label={t.code} sx={sx.chip} />
                                ))
                            ) : (
                                <> - </>
                            )
                        }
                    />
                    <FunctionField
                        sortBy="active"
                        source="active"
                        label={translate('manager.resources.general.status')}
                        render={(record: RaRecord) => (
                            <DatagridPublishedColumnContent record={record} />
                        )}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                    <FunctionField
                        label={translate('manager.resources.general.actions')}
                        render={(record: RaRecord) =>
                            record?.id && (
                                <Tooltip title={translate('manager.resources.general.duplicate')}>
                                    <FileCopyIcon
                                        sx={sx.fileIcon}
                                        onClick={(e: MouseEvent<SVGSVGElement>) =>
                                            handleDuplicateClick(e, record.id.toString())
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Datagrid>
            </List>
            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.packageGroup.duplicate_package_group')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.package.public_name')}
                        onChange={handlePublicNameChange}
                    />
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.packageGroup.internal_name')}
                        onChange={handleInternalNameChange}
                    />
                    <DialogActions sx={sx.dialogActions}>
                        <Button onClick={togglePopup} variant="contained">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={!publicName || !internalName || isLoadingDuplication}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingDuplication && <CircularProgress size={24} sx={sx.spinner} />}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PackageGroupList
