import { useState } from 'react'
import { Create, useResourceContext, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import PersonalizationForm from './PersonalizationRuleForm'

const PersonalizationRuleCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)
    const resource = useResourceContext()

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/rule" />
            <Create
                title={translate(`manager.resources.${resource?.toLowerCase()}.create`)}
                undoable={false}
                {...props}
            >
                <PersonalizationForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default PersonalizationRuleCreate
