import { PackagesPackageType } from 'apollo/types.generated'
import DefaultToolbar from 'components/common/DefaultToolbar'
import { TabbedForm, useTranslate } from 'react-admin'
import { ConfigTabContent } from '../PackageCreate/ConfigTabContent'
import { GeneralTabContent } from '../PackageCreate/GeneralTabContent'
import { ImagesTabContent } from '../PackageCreate/ImagesTabContent'

export const PackageEditForm = ({ packageType, setPackageType, packageSchema, loading }) => {
    const translate = useTranslate()

    return (
        <TabbedForm toolbar={<DefaultToolbar isWithActionsFlush />}>
            <TabbedForm.Tab label={translate('manager.tabs.general')}>
                <GeneralTabContent packageType={packageType} />
            </TabbedForm.Tab>
            {/* Trailing slash (images/) below is to enable redirect location sync for tab */}
            {packageType === PackagesPackageType.Package && (
                <TabbedForm.Tab
                    label={translate('manager.tabs.images')}
                    path="images"
                    syncWithLocation
                >
                    <ImagesTabContent />
                </TabbedForm.Tab>
            )}
            <TabbedForm.Tab label={translate('manager.tabs.config')} path={'config'}>
                <ConfigTabContent
                    isEdit={true}
                    packageSchema={packageSchema}
                    loading={loading}
                    setPackageType={setPackageType}
                />
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
