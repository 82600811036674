import { ToolbarProps } from '@mui/material'
import { DeleteButton, SaveButton, Toolbar, useRecordContext } from 'react-admin'

// TODO: refactor to be more generic so it is possible to copy paste more easily for a new component, maybe use {{children}}
const PackageProductEditToolbar = (props: ToolbarProps) => {
    const record = useRecordContext()

    return (
        <Toolbar
            {...props}
            sx={{
                justifyContent: 'space-between'
            }}
        >
            <SaveButton />
            <DeleteButton redirect={`/Package/${record?.package_id}`} />
        </Toolbar>
    )
}

export default PackageProductEditToolbar
