import { Typography } from '@mui/material'
import { COLORS } from 'layout/theme/colors'
import { RaRecord, useRecordContext } from 'ra-core'
import { useTranslate } from 'react-admin'

interface DatagridPublishedColumnContentProps {
    record: RaRecord
}

const DatagridPublishedColumnContent = ({}: DatagridPublishedColumnContentProps) => {
    const translate = useTranslate()
    const record = useRecordContext()

    return record?.active ? (
        <Typography
            variant="body2"
            sx={{
                color: COLORS.theme.success_green.main
            }}
        >
            {translate('manager.resources.general.published')}
        </Typography>
    ) : (
        <Typography
            variant="body2"
            sx={{
                color: COLORS.theme.error_red.main
            }}
        >
            {translate('manager.resources.general.not_published')}
        </Typography>
    )
}
export default DatagridPublishedColumnContent
