import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@mui/material'
import _ from 'lodash'
import { RaRecord, useRecordContext, useRefresh } from 'ra-core'
import { useState } from 'react'
import {
    ArrayField,
    Datagrid,
    FunctionField,
    TextField,
    TopToolbar,
    useTranslate
} from 'react-admin'
import styled from 'styled-components'
import { ChoiceType } from 'types'

import AutoCompleteSelectWithData from 'components/formfields/AutoCompleteSelectWithData'
import WarningDialog from 'components/modelManager/molecules/popups/WarningDialog'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'

import { usePackagesCreatePackageGroupPackageMutation } from 'apollo/package_manager/mutations/PackagesCreatePackageGroupPackage.generated'
import { usePackagesDeletePackageGroupPackageMutation } from 'apollo/package_manager/mutations/PackagesDeletePackageGroupPackage.generated'
import { useGetAllPackagesQuery } from 'apollo/package_manager/queries/GetAllPackages.generated'
import BulkActionButtons from 'components/common/BulkActionButtons'

const PackageGroupPackageList = (props) => {
    const [isOpenedPopup, setIsOpenedPopup] = useState<boolean>(false)
    const [isOpenedConfirmPopup, setIsOpenedConfirmPopup] = useState<boolean>(false)
    const [deletePackageID, setDeletePackageID] = useState<number>(0)

    const record = useRecordContext()
    const refresh = useRefresh()
    const translate = useTranslate()
    const sx = {
        dialogActions: {
            padding: 0,
            paddingBottom: '1rem'
        },
        deleteIcon: {
            '&:hover': {
                transform: `scale(1.1)`
            }
        }
    }

    const { data: packages } = useGetAllPackagesQuery({
        variables: { first: 10000 }
    })

    // Filter all unique packages (packages that are not already added)
    const filteredPackages = _.differenceBy(
        packages?.packagesPackages?.data,
        record?.packages,
        'id'
    )

    const getInitialSelectedPackageId = () => {
        return filteredPackages?.length > 0 ? filteredPackages[0]?.id : 0
    }

    const [selectedPackageId, setSelectedPackageId] = useState(getInitialSelectedPackageId())
    const [packagesCreatePackageGroupPackageMutation] =
        usePackagesCreatePackageGroupPackageMutation({
            variables: {
                groupId: Number(record?.id),
                packageId: Number(selectedPackageId)
            }
        })

    const [packagesDeletePackageGroupPackageMutation] =
        usePackagesDeletePackageGroupPackageMutation()

    const toggleModal = () => {
        setIsOpenedPopup(!isOpenedPopup)
    }

    const handleAutocompleteChange = (selectedItem) => {
        setSelectedPackageId(Number(selectedItem))
    }

    const handleCloseConfirmPopup = () => {
        setIsOpenedConfirmPopup(false)
    }

    const handleContinueConfirmPopup = async () => {
        await packagesDeletePackageGroupPackageMutation({
            variables: { groupId: Number(record?.id), packageId: deletePackageID }
        })
        setIsOpenedConfirmPopup(false)
        refresh()
    }

    const handleBulkDelete = async (selectedIds: string[]) => {
        selectedIds.map(async (id) => {
            await packagesDeletePackageGroupPackageMutation({
                variables: {
                    groupId: Number(record?.id),
                    packageId: Number(id)
                }
            })
        })
    }

    const handleAddRelationFormSubmit = async () => {
        await packagesCreatePackageGroupPackageMutation({
            variables: {
                groupId: Number(record?.id),
                packageId: Number(selectedPackageId)
            }
        })
        setIsOpenedPopup(false)
        refresh()
    }

    const handleDeleteClick = (event, id: number) => {
        event?.stopPropagation() // Stops rowClick from firing
        setDeletePackageID(id)
        setIsOpenedConfirmPopup(true)
    }

    return (
        <>
            <ListWrapper>
                <TopToolbar>
                    <Button color="primary" variant={'outlined'} onClick={toggleModal}>
                        <AddIcon />
                        {translate('manager.resources.packageGroup.package.add')}
                    </Button>
                </TopToolbar>
                <ArrayField source="packages">
                    <Datagrid
                        rowClick={(_, __, record) => `/Package/${record.id}`}
                        bulkActionButtons={
                            <BulkActionButtons
                                isWithActionsFlush
                                customize={{
                                    mutation: handleBulkDelete,
                                    onEnd: () => refresh()
                                }}
                            />
                        }
                    >
                        <TextField
                            source="public_name"
                            label={translate('manager.resources.packageGroup.package.name')}
                        />
                        <TextField
                            source="type"
                            label={translate('manager.resources.packageGroup.package.type')}
                        />
                        <FunctionField
                            source="active"
                            label={translate('manager.resources.general.status')}
                            render={(record: RaRecord) => (
                                <DatagridPublishedColumnContent record={record} />
                            )}
                        />
                        <FunctionField
                            label={translate('manager.resources.general.actions')}
                            render={(record: RaRecord) =>
                                record?.id && (
                                    <Tooltip title={translate('manager.resources.general.delete')}>
                                        <DeleteIcon
                                            sx={sx.deleteIcon}
                                            onClick={(event) =>
                                                handleDeleteClick(event, Number(record?.id))
                                            }
                                        />
                                    </Tooltip>
                                )
                            }
                        />
                    </Datagrid>
                </ArrayField>
            </ListWrapper>

            <Dialog fullWidth open={isOpenedPopup} onClose={() => toggleModal()}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.packageGroup.package.add')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {filteredPackages.length === 0 && (
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            color="textSecondary"
                        >
                            {translate('manager.resources.packageGroup.package.hint')}
                        </Typography>
                    )}
                    <AutoCompleteSelectWithData
                        required
                        format
                        source="package_id"
                        data={filteredPackages ?? []}
                        customChoices={
                            (filteredPackages &&
                                filteredPackages.map(
                                    (item): ChoiceType => ({
                                        id: item?.id,
                                        name: `${item?.public_name} | ${item?.type}`
                                    })
                                )) ??
                            []
                        }
                        onChange={handleAutocompleteChange}
                        disabled={filteredPackages.length === 0}
                    />
                    <DialogActions sx={sx.dialogActions}>
                        <Button onClick={toggleModal} variant="contained">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handleAddRelationFormSubmit}
                            variant="contained"
                            color="primary"
                            disabled={selectedPackageId === 0}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <WarningDialog
                open={isOpenedConfirmPopup}
                handleClose={handleCloseConfirmPopup}
                handleContinue={handleContinueConfirmPopup}
                maxWidth="md"
                title={translate(
                    'manager.resources.packageGroup.package.delete_confirm_popup.title'
                )}
                content={translate(
                    'manager.resources.packageGroup.package.delete_confirm_popup.content'
                )}
            />
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    padding-bottom: 2rem;

    & .MuiPaper-root {
        border: 0;
    }
`

export default PackageGroupPackageList
