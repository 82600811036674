import { CircularProgress, Grid } from '@mui/material'
import { required, SelectInput, useTranslate } from 'react-admin'

import { PackagesPackageType } from 'apollo/types.generated'
import { formStyles } from 'layout/styles/formStyles'
import { useWatch } from 'react-hook-form'
import PackageSchemaForm from '../PackageSchemaForm'

export interface ConfigTabContentProps {
    isEdit: boolean
    setPackageType: (type: PackagesPackageType) => void
    packageSchema: string
    loading: boolean
}

export const ConfigTabContent: React.FC<ConfigTabContentProps> = ({
    isEdit,
    setPackageType,
    packageSchema,
    loading
}) => {
    const translate = useTranslate()
    const watch = useWatch()
    const requiredValidate = [required()]

    return (
        <Grid container sx={formStyles.fullWidth}>
            <Grid item md={6}>
                <SelectInput
                    source="type"
                    variant="outlined"
                    disabled={isEdit}
                    choices={[
                        { id: 'package', name: 'Package' },
                        { id: 'megadeal', name: 'Megadeal' }
                    ]}
                    defaultValue="package"
                    fullWidth
                    validate={requiredValidate}
                    label={translate('manager.resources.package.type')}
                    onChange={(event) => {
                        setPackageType(event.target.value as PackagesPackageType)
                    }}
                />
                {packageSchema && (
                    <PackageSchemaForm
                        packageSchema={packageSchema}
                        definition={watch.definition}
                    />
                )}
                {loading && <CircularProgress />}
            </Grid>
        </Grid>
    )
}
