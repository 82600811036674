import { useNotify, useRecordContext } from 'ra-core'
import { BooleanInput, FormTab, required, TabbedForm, TextInput, useTranslate } from 'react-admin'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

// MUI
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Button, Grid2, Typography } from '@mui/material'

import { formStyles } from 'layout/styles/formStyles'
import { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import ConfiguratorNodesSelect from '../../components/formfields/ConfiguratorNodesSelect'
import PresetManager from './PresetManager'
import PresetToolbar from './PresetToolbar'

const PresetForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const notify = useNotify()
    const record = useRecordContext(props)
    const publishedCode = record?.published_code
    const [presetManagerPortal, setPresetManagerPortal] = useState<HTMLElement | null>(null)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(publishedCode)
        notify('manager.resources.general.copied_to_clipboard', {
            type: 'info',
            undoable: false,
            messageArgs: {},
            autoHideDuration: 1000
        })
    }

    const { control } = useForm()

    // Access the `isDirty` state of the form
    const { isDirty } = useFormState({ control })
    useEffect(() => {
        setPresetManagerPortal(document.getElementById('preset-manager-portal'))
    }, [])

    // Call the callback when the `isDirty` state changes
    useEffect(() => {
        setIsPristineFormCallback(!isDirty) // `!isDirty` corresponds to `pristine`
    }, [isDirty, setIsPristineFormCallback])

    return (
        <PresetFormWrapper>
            <TabbedForm {...props} toolbar={<PresetToolbar isWithActionsFlush />}>
                <FormTab label={translate('manager.tabs.general')}>
                    <Grid2 container>
                        <Grid2 size={7}>
                            <Grid2 size={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('manager.resources.preset.preset')}
                                </Typography>
                            </Grid2>
                            <TextInput
                                fullWidth
                                source="internal_name"
                                variant="outlined"
                                label={translate('manager.resources.preset.internal_name')}
                                validate={requiredValidate}
                            />
                            <TextInput
                                fullWidth
                                source="public_name"
                                variant="outlined"
                                label={translate('manager.resources.preset.public_name')}
                                validate={requiredValidate}
                            />
                            <ConfiguratorNodesSelect disabled={props.isEdit} required />
                            <BooleanInput
                                source="active"
                                label={translate('manager.resources.general.published')}
                                defaultValue={true}
                            />
                            {/*
                             * Temp fix
                             * https://riffonline.atlassian.net/browse/CLS-2470
                             * // TODO: Fix in BE and remove
                             */}
                            <BooleanInput
                                source="published"
                                defaultValue={false}
                                sx={formStyles.hidden}
                            />
                        </Grid2>
                        <Grid2 size={1} />
                        <Grid2 size={4}>
                            <Box mt={2} mb={2}>
                                <Typography variant="h6" gutterBottom>
                                    {`${translate('manager.resources.preset.published_code')}:`}
                                </Typography>

                                <Typography variant="body1" gutterBottom>
                                    {publishedCode ? `${publishedCode} ` : '-'}
                                    {publishedCode && (
                                        <Button variant="text" onClick={() => copyToClipboard()}>
                                            <ContentCopyIcon />
                                        </Button>
                                    )}
                                </Typography>
                            </Box>
                        </Grid2>
                    </Grid2>
                </FormTab>
                {record?.id && (
                    <FormTab label={translate('manager.tabs.model')} path="model">
                        <div id="preset-manager-portal" className="preset-manager-portal" />
                    </FormTab>
                )}
            </TabbedForm>
            {presetManagerPortal &&
                // We move the Presetmanager from outside of the main form, to inside of the main form with react portal
                // This needs to be done to prevent event bubbling. Otherwise the preset manager submit button will never fire the submitEvent when clicked
                ReactDOM.createPortal(<PresetManager {...props} />, presetManagerPortal)}
        </PresetFormWrapper>
    )
}

const PresetFormWrapper = styled.section`
    .preset-manager-portal {
        width: 100%;
    }
`

const requiredValidate = [required()]

export default PresetForm
