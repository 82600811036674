import { IChangeEvent } from '@rjsf/mui/dist'
import { RJSFSchema } from '@rjsf/utils'

/**
 * Serializes the key-value pairs from an IChangeEvent's formData into a JSON string.
 *
 * @param data - The IChangeEvent object containing the formData to serialize.
 * @returns A JSON string containing the key-value pairs from the formData.
 */
export const serializeData = (data: IChangeEvent<any, RJSFSchema, any>) => {
    const formattedData = data?.formData?.attributes?.map(
        ({ key, value_path, value_string, value_boolean, value_number }) => {
            return {
                key,
                value: value_path || value_string || value_boolean || value_number
            }
        }
    )

    return JSON.stringify(formattedData)
}
