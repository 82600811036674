import { useTheme } from '@mui/material'
import MuiDialogActions from '@mui/material/DialogActions'
import { ComponentProps } from 'react'

type GalleryPopupActionsProps = {
    children: React.ReactNode
} & ComponentProps<typeof MuiDialogActions>
export const GallerPopupActions = ({ children, ...props }: GalleryPopupActionsProps) => {
    const theme = useTheme()
    const sx = {
        margin: 0,
        padding: theme.spacing(1)
    }
    return (
        <MuiDialogActions {...props} sx={sx}>
            {children}
        </MuiDialogActions>
    )
}

export default GallerPopupActions
