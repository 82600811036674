import {
    TextInput,
    BooleanInput,
    useTranslate,
    required,
    TabbedForm,
    FormTab,
    NumberInput,
    useRecordContext
} from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { useForm, useFormState } from 'react-hook-form'
import DefaultToolbar from 'components/common/DefaultToolbar'
import { useEffect } from 'react'

const EducationForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const { control } = useForm({
        defaultValues: useRecordContext()
    })
    const { isDirty } = useFormState({ control })

    // Update form pristine state when it changes
    useEffect(() => {
        setIsPristineFormCallback(!isDirty)
    }, [isDirty, setIsPristineFormCallback])

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')}>
                <Grid container spacing={0}>
                    <Grid item md={7}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.education.education')}
                        </Typography>
                        <TextInput
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.education.name')}
                            validate={requiredValidate}
                            fullWidth
                        />
                        <TextInput
                            source="code"
                            variant="outlined"
                            label={translate('manager.resources.education.code')}
                            validate={requiredValidate}
                            fullWidth
                        />
                        <NumberInput
                            source="priority"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.priority')}
                            fullWidth
                        />
                        <BooleanInput
                            source="active"
                            label={translate('manager.resources.general.published')}
                            defaultValue={true}
                        />
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default EducationForm
