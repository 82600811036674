import { Dialog } from '@mui/material'
import { usePackagesPackageGalleryItemsQuery } from 'apollo/package_manager/queries/PackageGalleryItems.generated'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import RelatedResourceToolbar from 'components/common/RelatedResourceToolbar'
import UploadField from 'components/formfields/uploadField/UploadField'
import { required, useCreatePath, useRefresh, useTranslate } from 'ra-core'
import { useState } from 'react'
import { Create, Datagrid, List, SimpleForm, TextField, TextInput } from 'react-admin'
import styled from 'styled-components'

type PackageGalleryItemListProps = {
    filter?: Record<string, any>
}

const PackageGalleryItemList = (props: PackageGalleryItemListProps) => {
    const RESOURCE = 'PackageGalleryItem'
    const [open, setOpen] = useState(false)
    const reload = useRefresh()
    const sx = {
        list: {
            width: '100%',
            margin: '2rem 0'
        },
        simpleForm: {
            width: 600
        }
    }
    const translate = useTranslate()
    const package_id = props?.filter?.package_id

    const toggleModal = (): void => setOpen(!open)
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    const createPath = useCreatePath()
    const createPackageGalleryItemPath = useCreatePath()

    const { data } = usePackagesPackageGalleryItemsQuery({
        variables: {
            filter: {
                package_id
            },
            page: 1,
            perPage: 10
        }
    })

    return (
        <>
            <ListWrapper>
                <List
                    actions={
                        <RelatedResourceToolbar
                            resource={RESOURCE}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    pagination={false}
                    sx={sx.list}
                >
                    <Datagrid
                        // unsure why, but we need to take the data from data?.data?.data
                        // @ts-ignore
                        data={data?.data?.data}
                        body={
                            <CustomDatagridBody
                                rowProps={{
                                    onClickOverride: (id: string) => {
                                        const path = createPackageGalleryItemPath({
                                            resource: 'PackageGalleryItem',
                                            type: 'edit',
                                            id
                                        })
                                        window.open(`#${path}`, '_self')
                                    }
                                }}
                                bulkActionButtons={false}
                                resource={'PackageGalleryItem'}
                            />
                        }
                        rowClick="edit"
                    >
                        <TextField
                            sortable={false}
                            source="title"
                            label={translate(`manager.resources.${RESOURCE}.title`)}
                        />
                        <TextField
                            sortable={false}
                            source="alt"
                            label={translate(`manager.resources.${RESOURCE}.alt`)}
                        />
                        <TextField
                            sortable={false}
                            source="path"
                            label={translate(`manager.resources.${RESOURCE}.path`)}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <Dialog open={open} onClose={() => toggleModal()}>
                <Create
                    resource={RESOURCE}
                    mutationOptions={{
                        onSuccess: handleSuccess
                    }}
                    redirect={createPath({ resource: 'packageGalleryItem', type: 'list' })}
                    {...props}
                >
                    <SimpleForm sx={sx.simpleForm} defaultValues={{ package_id }}>
                        <TextInput
                            fullWidth
                            source="title"
                            variant="outlined"
                            label={translate(`manager.resources.${RESOURCE}.title`)}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="alt"
                            variant="outlined"
                            label={translate(`manager.resources.${RESOURCE}.alt`)}
                            validate={requiredValidate}
                        />
                        <UploadField
                            url=""
                            data=""
                            source="path"
                            label={translate(`manager.resources.${RESOURCE}.path`)}
                            validateRequired
                        />
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

const requiredValidate = [required()]

export default PackageGalleryItemList
