import { Dialog } from '@mui/material'
import { required, useRefresh, useTranslate } from 'ra-core'
import { useState } from 'react'
import { Create, Datagrid, List, SimpleForm, TextField, TextInput } from 'react-admin'
import styled from 'styled-components'

import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import RelatedResourceToolbar from 'components/common/RelatedResourceToolbar'
import UploadField from 'components/formfields/uploadField/UploadField'

type PackageGroupCollectionGalleryItemListProps = {
    filter?: Record<string, any>
}

const PackageGroupCollectionGalleryItemList = (
    props: PackageGroupCollectionGalleryItemListProps
) => {
    const RESOURCE = 'PackageGroupCollectionGalleryItem'
    const [open, setOpen] = useState(false)
    const reload = useRefresh()
    const sx = {
        list: {
            width: '100%',
            margin: '2rem 0'
        },
        simpleForm: {
            width: 600
        }
    }
    const translate = useTranslate()
    const collectionId = props?.filter?.collection_id

    const toggleModal = (): void => setOpen(!open)
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    return (
        <>
            <ListWrapper>
                <List
                    resource={RESOURCE}
                    actions={
                        <RelatedResourceToolbar
                            resource={RESOURCE}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    pagination={false}
                    sx={sx.list}
                    sort={{ field: 'collection_id', order: 'ASC' }}
                    filter={props.filter}
                >
                    <Datagrid
                        body={<CustomDatagridBody bulkActionButtons={false} />}
                        rowClick="edit"
                    >
                        <TextField
                            sortable={false}
                            source="title"
                            label={translate(`manager.resources.${RESOURCE}.title`)}
                        />
                        <TextField
                            sortable={false}
                            source="alt"
                            label={translate(`manager.resources.${RESOURCE}.alt`)}
                        />
                        <TextField
                            sortable={false}
                            source="path"
                            label={translate(`manager.resources.${RESOURCE}.path`)}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <Dialog open={open} onClose={() => toggleModal()}>
                <Create
                    resource={RESOURCE}
                    mutationOptions={{
                        onSuccess: handleSuccess
                    }}
                >
                    <SimpleForm sx={sx.simpleForm} defaultValues={{ collection_id: collectionId }}>
                        <TextInput
                            fullWidth
                            source="title"
                            variant="outlined"
                            label={translate(`manager.resources.${RESOURCE}.title`)}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="alt"
                            variant="outlined"
                            label={translate(`manager.resources.${RESOURCE}.alt`)}
                            validate={requiredValidate}
                        />
                        <UploadField
                            url=""
                            data=""
                            source="path"
                            label={translate(`manager.resources.${RESOURCE}.path`)}
                            validateRequired
                        />
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

const requiredValidate = [required()]

export default PackageGroupCollectionGalleryItemList
