import { RaRecord, useNotify, useTranslate } from 'ra-core'
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Pagination,
    TextField,
    useRedirect
} from 'react-admin'

import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Tooltip } from '@mui/material'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import { MouseEvent } from 'react'
import { useDuplicateRuleMutation } from '../../apollo/personalization/mutations/DuplicateRule.generated'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'
import PersonalizationRuleListFilter from './PersonalizationRuleListFilters'

const PersonalizationRuleList = (props: ListProps) => {
    const translate = useTranslate()
    const [duplicateRuleMutation] = useDuplicateRuleMutation()
    const redirect = useRedirect()
    const notify = useNotify()

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        duplicateRuleMutation({
            variables: { id: id }
        })
            .then((result) => {
                redirect('edit', '/Rule', result.data?.duplicateRule?.id)
            })
            .catch((error) => notify(error.message, { type: 'error' }))
    }

    return (
        <List
            {...props}
            sx={{
                '& .RaDatagrid-headerCell': {
                    fontWeight: 700
                }
            }}
            sort={{ field: 'label', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filters={PersonalizationRuleListFilter}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid
                body={<CustomDatagridBody />}
                rowClick="edit"
                bulkActionButtons={<BulkActionButtons />}
            >
                <TextField source="label" label={translate('manager.resources.rule.label')} />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record: RaRecord) => (
                        <DatagridPublishedColumnContent record={record} />
                    )}
                />
                <FunctionField
                    label={translate('manager.resources.general.actions')}
                    render={(record: RaRecord) =>
                        record?.id && (
                            <Tooltip title={translate('manager.resources.general.duplicate')}>
                                <FileCopyIcon
                                    sx={{
                                        '&:hover': {
                                            transform: `scale(1.1)`
                                        }
                                    }}
                                    onClick={(e: MouseEvent<SVGSVGElement>) =>
                                        handleDuplicateClick(e, record.id.toString())
                                    }
                                />
                            </Tooltip>
                        )
                    }
                />
            </Datagrid>
        </List>
    )
}
export default PersonalizationRuleList
