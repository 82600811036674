import { Grid, Typography } from '@mui/material'
import {
    BooleanInput,
    FormTab,
    TabbedForm,
    TextInput,
    required,
    useRecordContext,
    useTranslate
} from 'react-admin'
import { useForm, useFormState } from 'react-hook-form'

import CustomToolBar from 'components/common/CustomToolBar'
import DefaultToolbar from 'components/common/DefaultToolbar'
import { formStyles } from 'layout/styles/formStyles'
import { useEffect } from 'react'
import PersonalizationRuleFormActions from './PersonalizationRuleFormActions'
import PersonalizationRuleFormTabRules from './PersonalizationRuleFormTabRules'

const PersonalizationRuleForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const record = useRecordContext()

    const DEFAULT_JSON_STRING_VALUE = '{}'
    const rules = record?.rules ?? DEFAULT_JSON_STRING_VALUE
    const actions = record?.actions ?? DEFAULT_JSON_STRING_VALUE

    const { control } = useForm()

    // Access the `isDirty` state of the form
    const { isDirty } = useFormState({ control })

    // Call the callback when the `isDirty` state changes
    useEffect(() => {
        setIsPristineFormCallback(!isDirty) // `!isDirty` corresponds to `pristine`
    }, [isDirty, setIsPristineFormCallback])

    return (
        <TabbedForm {...props} toolbar={!!props.isEdit ? <CustomToolBar /> : <DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')}>
                <Grid container sx={formStyles.fullWidth}>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.rule.personalization')}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <TextInput
                                source="label"
                                variant="outlined"
                                label={translate('manager.resources.rule.label')}
                                validate={requiredValidate}
                                fullWidth
                            />
                            <BooleanInput
                                source="active"
                                label={translate('manager.resources.general.published')}
                                defaultValue={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label={translate('manager.resources.rule.rules')} path="rules">
                <PersonalizationRuleFormTabRules rules={rules} />
            </FormTab>
            <FormTab label={translate('manager.resources.rule.actions')} path="actions">
                <PersonalizationRuleFormActions actions={actions} />
            </FormTab>
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default PersonalizationRuleForm
