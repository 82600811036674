import { Grid, Typography } from '@mui/material'
import { useRetailersRetailersQuery } from 'apollo/retailer_manager/queries/RetailersRetailers.generated'
import { useRetailersRetailerYearsQuery } from 'apollo/retailer_manager/queries/RetailersRetailerYears.generated'
import DefaultToolbar from 'components/common/DefaultToolbar'
import AutoCompleteSelectWithData from 'components/formfields/AutoCompleteSelectWithData'
import { Variables } from 'components/variables'
import { formStyles } from 'layout/styles/formStyles'
import { useEffect } from 'react'
import {
    BooleanInput,
    FormTab,
    NumberInput,
    regex,
    required,
    TabbedForm,
    TextInput,
    useRecordContext,
    useTranslate
} from 'react-admin'
import { useForm, useFormState } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import RetailerClassRepresentativeListAsArrayField from 'resources/retailerClassRepresentative/RetailerClassRepresentativeListAsArrayField'
import { ChoiceType } from 'types'
import RetailerClassEditToolbar from './RetailerClassEditToolbar'
import RetailerClassTagsInput from './RetailerClassTagsInput'

const RetailerClassForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const { data: retailers = {} } = useRetailersRetailersQuery({
        variables: {
            first: 10000
        },
        fetchPolicy: 'cache-and-network' // Set a fetch policy to retrieve newly added data
    })
    const { data: years = {} } = useRetailersRetailerYearsQuery({
        fetchPolicy: 'cache-and-network'
    })
    const translate = useTranslate()
    const location = useLocation()
    const { retailerId } = location?.state || {}
    const validateAjatId = regex(/^[\w-]+$/, translate('manager.regex.az09'))

    const record = useRecordContext()

    const { control } = useForm({
        defaultValues: record
    })
    const { isDirty } = useFormState({ control })

    // Update form pristine state when it changes
    useEffect(() => {
        setIsPristineFormCallback(!isDirty)
    }, [isDirty, setIsPristineFormCallback])

    return (
        <TabbedForm
            {...props}
            toolbar={props.isEdit ? <RetailerClassEditToolbar {...props} /> : <DefaultToolbar />}
        >
            <FormTab label={translate('manager.tabs.general')}>
                <Grid container>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.retailerClass.class')}
                            </Typography>
                        </Grid>
                        <TextInput
                            source="code"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.code')}
                            validate={[required(), validateAjatId]}
                            fullWidth
                            helperText={translate('manager.regex.az09')}
                            disabled={props.isEdit}
                            defaultValue={props.isEdit ? undefined : 'placeholder_id'}
                            sx={{
                                ...(props.isEdit && { display: 'none' })
                            }}
                        />
                        <TextInput
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.name')}
                            validate={requiredValidate}
                            fullWidth
                        />
                        <Grid container>
                            <Grid item md={6} paddingRight={1}>
                                <AutoCompleteSelectWithData
                                    defaultValue={retailerId ? Number(retailerId) : undefined}
                                    required
                                    format // For some reason this value needs to be formatted to a string to print the options.
                                    source="retailer_id"
                                    data={
                                        (retailers?.retailersRetailers?.data &&
                                            retailers.retailersRetailers.data) ??
                                        []
                                    }
                                    customChoices={
                                        (retailers?.retailersRetailers?.data &&
                                            retailers.retailersRetailers.data.map(
                                                (item): ChoiceType => ({
                                                    id: item.id,
                                                    name: `${item.name} | ${item?.code}`
                                                })
                                            )) ??
                                        []
                                    }
                                />
                            </Grid>
                            <Grid item md={6} paddingLeft={1}>
                                <AutoCompleteSelectWithData
                                    required
                                    source="retailer_year_id"
                                    data={
                                        (years?.retailersRetailerYears?.data &&
                                            years.retailersRetailerYears.data) ??
                                        []
                                    }
                                />
                            </Grid>
                        </Grid>
                        <TextInput
                            source="embroidery_1"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.embroidery_1')}
                            fullWidth
                        />
                        <TextInput
                            source="embroidery_2"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.embroidery_2')}
                            fullWidth
                        />
                        <TextInput
                            source="embroidery_3"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.embroidery_3')}
                            fullWidth
                        />
                        <NumberInput
                            source="students_amount"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.students_amount')}
                            fullWidth
                        />
                        <NumberInput
                            source="priority"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.priority')}
                            fullWidth
                        />
                        {record?.id && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    {translate('manager.resources.retailer.tags')}
                                </Typography>
                                <RetailerClassTagsInput retailerClassId={record?.id || 0} />
                            </>
                        )}
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <Grid item md={12} mt={3} mb={3}>
                            <BooleanInput
                                row
                                source="active"
                                label={translate('manager.resources.general.published')}
                                defaultValue={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.contact_persons')} path="contact-persons">
                    <Grid container sx={formStyles.fullWidth}>
                        <RetailerClassRepresentativeListAsArrayField {...props} />
                    </Grid>
                </FormTab>
            )}
            <FormTab label={translate('manager.tabs.variables')} path="variables">
                <Variables record={record} entityType="retailer_class" />
            </FormTab>
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerClassForm
