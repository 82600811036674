import { RaRecord, useNotify, useTranslate } from 'ra-core'
import {
    Button,
    CreateButton,
    Datagrid,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    ListProps,
    Pagination,
    sanitizeListRestProps,
    TextField,
    TopToolbar
} from 'react-admin'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { useState } from 'react'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { useRetailersImportRetailersMutation } from '../../apollo/upload/mutations/RetailersImportRetailers.generated'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import UploadDialog from '../../components/common/UploadDialog'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'
import RetailerListFilters from './RetailerListFilters'

const ListActions = (props) => {
    const t = useTranslate()
    const { className, maxResults, forceUpdate, ...rest } = props
    const [openDialog, setOpenDialog] = useState(false)
    const [uploadRetailers] = useRetailersImportRetailersMutation()
    const notify = useNotify()

    const handleUpload = (files) => {
        if (files?.[0]) {
            uploadRetailers({
                variables: {
                    file: files?.[0]
                }
            })
                .then(() => {
                    setOpenDialog(false)
                    forceUpdate()
                })
                .catch((error) => notify(error.message, { type: 'error' }))
        }
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton />
            <ExportButton />
            <Button
                onClick={() => {
                    setOpenDialog(true)
                }}
                label={t('manager.resources.retailer.upload')}
            >
                <CloudUploadIcon />
            </Button>
            <UploadDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                acceptedFiles={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]}
                handleSave={handleUpload}
                filesLimit={1}
            />
        </TopToolbar>
    )
}

const RetailerList = (props: ListProps) => {
    const translate = useTranslate()

    // Reload page after retailers are updated so that all the imported data is showing.
    const forceUpdate = () => window.location.reload()

    return (
        <List
            {...props}
            sort={{ field: 'name', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filters={RetailerListFilters}
            actions={<ListActions forceUpdate={forceUpdate} />}
            sx={{
                '& .RaDatagrid-headerCell': {
                    fontWeight: 700
                }
            }}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid
                body={<CustomDatagridBody />}
                rowClick="edit"
                bulkActionButtons={<BulkActionButtons />}
            >
                <TextField source="name" label={translate('manager.resources.retailer.name')} />
                <TextField
                    source="country"
                    label={translate('manager.resources.retailer.country')}
                />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record: RaRecord) => (
                        <DatagridPublishedColumnContent record={record} key={record?.id} />
                    )}
                />
                {/*<FunctionField*/}
                {/*    label={translate('manager.resources.retailer.active_periods')}*/}
                {/*    render={(record?: Record | undefined) =>*/}
                {/*        record?.periods && record?.periods?.length > 0 ? (*/}
                {/*            record.periods.map((period) =>*/}
                {/*                period.active ? (*/}
                {/*                    <div style={{ whiteSpace: 'nowrap' }}>*/}
                {/*                        <Chip*/}
                {/*                            size="small"*/}
                {/*                            label={formatDateString(period.from_date)}*/}
                {/*                        />*/}
                {/*                        <span> - </span>*/}
                {/*                        <Chip*/}
                {/*                            size="small"*/}
                {/*                            label={formatDateString(period.to_date)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                ) : (*/}
                {/*                    false*/}
                {/*                )*/}
                {/*            )*/}
                {/*        ) : (*/}
                {/*            <> - </>*/}
                {/*        )*/}
                {/*    }*/}
                {/*/>*/}
                {/*<TextField source="code" label={translate('manager.resources.retailer.code')} />*/}
                {/*<TextField*/}
                {/*    source="priority"*/}
                {/*    label={translate('manager.resources.retailer.priority')}*/}
                {/*/>*/}
                {/*<FunctionField*/}
                {/*    label={translate('manager.resources.retailer.tags')}*/}
                {/*    render={(record?: Record | undefined) =>*/}
                {/*        record?.tags && record.tags.length > 0 ? (*/}
                {/*            record.tags.map((t) => (*/}
                {/*                <Chip*/}
                {/*                    size="small"*/}
                {/*                    label={t.tag}*/}
                {/*                    className={classes.chip}*/}
                {/*                    key={record.id}*/}
                {/*                />*/}
                {/*            ))*/}
                {/*        ) : (*/}
                {/*            <> - </>*/}
                {/*        )*/}
                {/*    }*/}
                {/*/>*/}
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default RetailerList
