// Packages
import { ApolloError } from '@apollo/client'
import React, { useContext } from 'react'
import { useNotify, useTranslate } from 'react-admin'

// MUI
import BackupIcon from '@mui/icons-material/Backup'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

// GraphQL
import { usePresetExportModelMutation } from 'apollo/configurator/mutations/PresetExportModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

// Custom
import { Box, useTheme } from '@mui/material'
import { PresetModelContext } from 'context/preset-model/PresetModelContext'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'

interface ExportButtonProps {
    id: number
    presetId: number
    published: boolean
    isWithActionsFlush?: boolean
}

const PresetExportButton = ({ id, presetId, published, isWithActionsFlush }: ExportButtonProps) => {
    const { state, setState } = useContext(PresetModelContext)
    const theme = useTheme()
    const translate = useTranslate()
    const notify = useNotify()

    const [open, setOpen] = React.useState(false)
    const [isPublished, setIsPublihsed] = React.useState(published)
    const [mutationError, setMutationError] = React.useState(false)

    const buttonSx = {
        ...(isPublished &&
            !state.hasChanges &&
            !mutationError && {
                backgroundColor: `${theme.palette.success.main}`,
                color: `${proudNerdsTheme.palette.secondary.contrastText}`,
                '&:hover': {
                    backgroundColor: `${theme.palette.success.dark}`
                }
            }),
        ...(mutationError && {
            backgroundColor: `${theme.palette.error.main}`,
            color: `${theme.palette.secondary.contrastText}`,
            '&:hover': {
                backgroundColor: `${theme.palette.error.dark}`
            }
        }),
        ...(!mutationError &&
            (!isPublished || state.hasChanges) && {
                backgroundColor: `${theme.palette.warning.main}`,
                color: `${theme.palette.secondary.contrastText}`,
                '&:hover': {
                    backgroundColor: `${theme.palette.error.dark}`
                }
            })
    }

    const [exportModel, { loading }] = usePresetExportModelMutation({
        onCompleted: () => {
            handleExportSucces()
        },
        onError: (error) => {
            handleExportFailed(error)
        }
    })
    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleExportSucces = () => {
        setIsPublihsed(true)
        setMutationError(false)
        setState((prev) => ({
            ...prev,
            hasEdited: false,
            hasChanges: false,
            showEditedMessage: false
        }))
        notify('manager.resources.preset.manager.preset_export_succes', { type: 'success' })
    }

    const handleExportFailed = (error?: ApolloError | undefined) => {
        setMutationError(true)
        if (error) {
            notify('manager.resources.preset.manager.preset_export_error', { type: 'error' })
            console.error('handleExportFailed ->', error.message)
        }
    }

    const handleConfirm = async () => {
        await exportModel({
            variables: { configuratorExportModelId: `${id}`, preset: parseInt(`${presetId}`) }
        })
        if (isWithActionsFlush) flushActionsCache()
        setOpen(false)
        setState((prev) => ({
            ...prev,
            hasEdited: false,
            hasChanges: false,
            showEditedMessage: false
        }))
    }

    return (
        <Box
            sx={{
                margin: 4,
                position: 'relative'
            }}
        >
            <Button
                color="primary"
                variant="contained"
                sx={buttonSx}
                onClick={handleClickOpen}
                startIcon={<BackupIcon />}
            >
                {translate('manager.resources.preset.publish_dialog.button')}
                {loading && (
                    <CircularProgress
                        size={20}
                        sx={{
                            marginLeft: 4,
                            color: `${proudNerdsTheme.palette.secondary.contrastText}`
                        }}
                    />
                )}
            </Button>
            <Dialog open={open} keepMounted onClose={handleClose}>
                <DialogTitle>
                    {translate('manager.resources.preset.publish_dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('manager.resources.preset.publish_dialog.content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        {translate('manager.resources.preset.publish_dialog.cancel')}
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        {translate('manager.resources.preset.publish_dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default PresetExportButton
