import {
    Button,
    DeleteButton,
    SaveButton,
    Toolbar,
    useRecordContext,
    useRedirect,
    useTranslate
} from 'react-admin'

const PackageGroupCollectionGalleryItemEditToolbar = (props: any) => {
    const record = useRecordContext()
    const backUrl = `/PackageGroupCollection/${record?.collection_id}/images`
    const sx = {
        toolbar: {
            display: 'flex',
            flexFlow: 'row wrap',
            margin: '0 auto',
            width: '100%'
        },
        buttonMargin: {
            marginRight: '1rem'
        },
        buttonRight: {
            margin: '0 0 0 auto'
        }
    }
    const redirect = useRedirect()
    const translate = useTranslate()
    const goBack = () => {
        redirect('edit', backUrl, '')
    }

    return (
        <Toolbar {...props} sx={sx.toolbar}>
            <Button
                sx={sx.buttonMargin}
                onClick={goBack}
                label={translate('manager.resources.general.go_back')}
            />
            <SaveButton />
            <DeleteButton sx={sx.buttonRight} redirect={backUrl + '/'} />
        </Toolbar>
    )
}

export default PackageGroupCollectionGalleryItemEditToolbar
