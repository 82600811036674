import { TypeKind } from 'graphql'
import gql from 'graphql-tag'
import inflection from 'inflection'
import jp from 'jsonpath/jsonpath.min'
import _ from 'underscore'
import { resourceMap } from '../resourceMap'
import overridesMap from './overrides/overridesMap'
import { PackagesQueryPackagesOrderByColumn } from './types.generated'

export const isSubObject = (field, types) => {
    return (
        field.type.kind === TypeKind.OBJECT ||
        (field.type.kind === TypeKind.LIST &&
            (field.type.ofType.kind === TypeKind.OBJECT ||
                field.type.ofType.kind === TypeKind.LIST)) ||
        (field.type.kind === TypeKind.NON_NULL &&
            (field.type.ofType.kind === TypeKind.OBJECT ||
                field.type.ofType.kind === TypeKind.LIST)) ||
        ((field.type.ofType && field.type.ofType.kind) === TypeKind.NON_NULL &&
            ((field.type.ofType && field.type.ofType.ofType && field.type.ofType.ofType.kind) ===
                TypeKind.LIST ||
                (field.type.ofType && field.type.ofType.ofType && field.type.ofType.ofType.kind) ===
                    TypeKind.OBJECT))
    )
}

const getType = (field) => {
    if (field.type.kind === TypeKind.LIST || field.type.kind === TypeKind.NON_NULL) {
        let ofType = field.type.ofType
        // case of not null array
        if (typeof ofType === 'object') {
            return getOfType(ofType)
        } else {
            return ofType
        }
    }
    return field.type
}

const getOfType = (ofType) => {
    if (ofType.ofType) {
        return getOfType(ofType.ofType)
    }
    return ofType
}

const isResource = (field, resources) => {
    const type = getType(field)
    const res = resources.some((r) => r.type.name === type.name)
    return res
}
const buildFieldList = (
    introspectionResults,
    resource,
    aorFetchType,
    depth = 0,
    originalResource
) => {
    const types = introspectionResults.types
    const resources = introspectionResults.resources
    const maxDepth = 4
    const join = resource?.type?.fields
        .filter((field) => !field.name.startsWith('__'))
        .map((field) => {
            try {
                if (isSubObject(field, types) && depth < maxDepth) {
                    let typeToCheck = getType(field)
                    if (isResource(field, resources)) {
                        const type = types.find((t) => t.name === typeToCheck.name)
                        const resource = introspectionResults.resources.find(
                            (r) => r.type.name === type.name
                        )
                        if (type && resource && !(field.type.name === originalResource.type.name)) {
                            const subFields = buildFieldList(
                                introspectionResults,
                                resource,
                                aorFetchType,
                                depth + 1,
                                originalResource
                            )
                            if (subFields.length && depth + 1 < maxDepth) {
                                return `${field.name} { ${subFields} }`
                            } else {
                                return false
                            }
                        }
                    } else {
                        const type = types.find((t) => t.name === typeToCheck.name)
                        if (type && type?.fields) {
                            // eslint-disable-next-line
                            let subFields = type.fields
                                .map((_type) => {
                                    if (!isSubObject(_type, types)) {
                                        return _type.name
                                    } else {
                                        if (isResource(_type, resources)) {
                                            const resource = introspectionResults.resources.find(
                                                (r) => r.type.name === _type.type.name
                                            )
                                            if (_type && resource) {
                                                const subFields = buildFieldList(
                                                    introspectionResults,
                                                    resource,
                                                    aorFetchType,
                                                    depth + 1,
                                                    originalResource
                                                )
                                                return `${_type?.name} { ${subFields} }`
                                            }
                                        } else {
                                            return null
                                        }
                                        return null
                                    }
                                })
                                .filter((item) => item)
                            subFields = _.without(subFields, '_id')
                            if (subFields.length >= 1) {
                                return `${field.name} { ${subFields} }`
                            } else {
                                return false
                            }
                        }
                    }
                    return false
                }
                if (field.name === '_id' && depth >= 1) {
                    return false
                } else {
                    return field.name
                }
            } catch (err) {
                console.log(err)
            }
            return false
        })
        .filter((f) => f !== false)
        .join(' ')
    return join
}

export const queryBuilder = (introspectionResults) => (aorFetchType, resourceName, params) => {
    const prefix = resourceMap?.[resourceName]?.servicePrefix
    const resource = introspectionResults.resources.find(
        (r) => r.type.name === `${prefix}${resourceName}`
    )
    let result = {}
    let fieldList = buildFieldList(introspectionResults, resource, aorFetchType, 0, resource)
    let name = resource[aorFetchType].name
    let queryName = inflection.camelize(name, true)

    function getArgsSignature(args) {
        let argsList = args
            .map(function (arg) {
                let name = arg['name']
                let type = arg['type']
                let kind = type['kind']
                let ofType = type['ofType']
                let nameOfType = null
                if (ofType) {
                    nameOfType = ofType['name']
                } else {
                    nameOfType = type['name']
                }

                let nonNull = kind === 'NON_NULL' ? '!' : ''
                let string = `$${name}: ${nameOfType}${nonNull}`
                return string
            })
            .join(', ')
        return argsList
    }

    function getMutationArgs(args) {
        let mutationArgs = ''
        args.forEach(function (arg) {
            let name = arg['name']
            mutationArgs += ` ${name}: $${name}`
        })
        return mutationArgs
    }

    function getMutationParams(argsNames) {
        let mutationParams = {}
        for (const [key, value] of Object.entries(params.data)) {
            if (argsNames.includes(key)) {
                mutationParams[key] = value
            }
        }
        return mutationParams
    }

    /**
     * Override a query fields that is declared in overrides map
     * @param queryName
     */
    function setFieldsOverrides(queryName: string): void {
        if (overridesMap[queryName]) {
            fieldList = overridesMap[queryName]
        }
    }

    switch (aorFetchType) {
        case 'GET_LIST':
        case 'GET_MANY':
        case 'GET_MANY_REFERENCE':
            params.page = params.pagination.page
            params.perPage = params.pagination.perPage
            params.orderBy = {
                column: params?.sort?.field?.toUpperCase(),
                order: params?.sort?.order?.toUpperCase()
            }

            // TODO: Fix the mismatch of field/column in the Backend (configuratorPresets query)
            // Replace column with field in orderBy param
            if (name === 'configuratorPresets') {
                params.orderBy.field = params.orderBy.column
                delete params.orderBy.column
            }

            if (name === 'packagesPackages' && params.orderBy.column === 'ID') {
                params.orderBy.column = PackagesQueryPackagesOrderByColumn.CreatedAt
                params.orderBy.order = 'DESC'
            }

            if (
                name === 'retailersRetailerPeriods' &&
                typeof params.filter.id === 'string' &&
                Number(params.filter.id) === params.filter.retailer_id
            ) {
                delete params.filter.id
            }

            const orderByObj = resource?.[aorFetchType]?.args?.find(
                (field) => field.name === 'orderBy'
            )

            const orderByType =
                orderByObj && jp.query(orderByObj, '$..[?(@.kind === "INPUT_OBJECT")]')?.[0]?.name

            if (params?.filter) {
                for (const [key, value] of Object.entries(params.filter)) {
                    if (typeof value == 'string' && key !== 'ajat_id') {
                        params.filter[key] = `%${value}%`
                    }
                }
            }

            setFieldsOverrides(queryName)

            const getManyQuery = gql`query ${queryName}($filter: ${prefix}${resourceName}FilterInput, $page: Int, $perPage: Int!, $orderBy: [${orderByType}!] ) {
                data: ${queryName}(filter: $filter, page: $page, first: $perPage, orderBy: $orderBy) {
                data {
                    id: id
                    ${fieldList}
                    }
                    paginatorInfo {
                        count
                        currentPage
                        firstItem
                        hasMorePages
                        lastItem
                        lastPage
                        perPage
                        total
                    }
                }
                }`

            result = {
                query: getManyQuery,
                variables: params,
                parseResponse: function (response) {
                    return {
                        data: response.data.data.data,
                        total: response.data.data.paginatorInfo.total
                    }
                }
            }
            break
        case 'GET_ONE':
            setFieldsOverrides(queryName)

            result = {
                query: gql`query ${name}($id: ID!) {
                data: ${name}(id: $id) {
                ${fieldList}
                id: id
                }
                }`,
                variables: { id: params.id },
                parseResponse: (response) => ({
                    data: response.data.data,
                    id: response?.data?.data?.id
                })
            }
            break
        case 'UPDATE':
            setFieldsOverrides(queryName)
            let args = resource[aorFetchType].args
            let argsList = getArgsSignature(args)
            let mutationArgs = getMutationArgs(args)
            let query = gql`mutation ${name}(${argsList}) {
                data: ${name}(${mutationArgs}) {
                    ${fieldList}
                    id: id
                }
            }`

            let argsNames = args.map(function (e) {
                return e.name
            })
            let mutationParams = getMutationParams(argsNames)

            result = {
                query: query,
                variables: mutationParams,
                parseResponse: (response) => ({
                    data: response.data.data,
                    id: response?.data?.data?.id
                })
            }
            break

        case 'CREATE':
            setFieldsOverrides(queryName)
            let createArgs = resource[aorFetchType].args
            let createArgsList = getArgsSignature(createArgs)
            let createMutationArgs = getMutationArgs(createArgs)
            let createQuery = gql`mutation ${name}(${createArgsList}) {
            data: ${name}(${createMutationArgs}) {
            ${fieldList}
                id: id
                }
            }`

            let createArgsNames = createArgs.map(function (e) {
                return e.name
            })
            let createMutationParams = getMutationParams(createArgsNames)

            result = {
                query: createQuery,
                variables: createMutationParams,
                parseResponse: (response) => ({
                    data: response.data.data,
                    id: response?.data?.data?.id
                })
            }
            break
        case 'DELETE':
            result = {
                query: gql`mutation ${name}($id: ID!) {
                    data: ${name}(id: $id) {
                        id: id
                    }
                }`,
                variables: { id: params.id },
                parseResponse: (response) => ({
                    data: response.data.data,
                    id: response?.data?.data?.id
                })
            }
            break
        case 'DELETE_MANY':
            result = {
                query: gql`mutation ${name}($id: [ID!]!) {
                data: ${name}(id: $id) {
                id: id
                }
                }`,
                variables: { id: params.ids },
                parseResponse: (response) => ({
                    data: response.data.data,
                    id: response?.data?.data?.id
                })
            }
            break
        default:
            console.info(`not implemented ${aorFetchType}`)
            return undefined
    }

    return result
}
