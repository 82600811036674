import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Button from '@mui/material/Button'
import CheckBox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fab from '@mui/material/Fab'
import { useDownloadModelLazyQuery } from 'apollo/configurator/queries/DownloadModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
// import { ModelContext } from 'context/model/ModelContext'
import { Box } from '@mui/material'
import { proudNerdsTheme } from 'layout/theme/ProudNerdsTheme'
import React /* , { useContext } */ from 'react'
import { exportData, handleConfirm } from './helpers/ImportExportModel'

interface IDownloadButton {
    id: number
}

const DownloadButton = ({ id }: IDownloadButton) => {
    // const { state } = useContext(ModelContext)
    const [open, setOpen] = React.useState(false)
    const [inclPresets, setInclPresets] = React.useState(false)

    const [downloadModel, { loading }] = useDownloadModelLazyQuery({
        onCompleted: (data) => {
            exportData({
                model: JSON.parse(data.configuratorExportJSONModel?.model ?? '[]'),
                presets: JSON.parse(data.configuratorExportJSONModel?.presets ?? '[]')
            })
        },
        fetchPolicy: 'network-only'
    })

    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 1,
                    rotation: '180deg'
                }}
            >
                <Box sx={{ margin: 4, position: 'relative' }}>
                    <Fab
                        aria-label="save"
                        color="primary"
                        sx={{
                            backgroundColor: proudNerdsTheme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: proudNerdsTheme.palette.primary.dark
                            }
                        }}
                        onClick={handleClickOpen}
                    >
                        <CloudDownloadIcon />
                    </Fab>
                    {loading && (
                        <CircularProgress
                            size={68}
                            sx={{
                                color: proudNerdsTheme.palette.primary.light,
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1
                            }}
                        />
                    )}
                </Box>
            </Box>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {'Export the model to a file and Download.'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <CheckBox
                            checked={inclPresets}
                            style={{ top: '-1px', position: 'relative' }}
                            onChange={(evt, val) => {
                                setInclPresets(val)
                            }}
                        ></CheckBox>{' '}
                        Include Presets?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirm(downloadModel, id, inclPresets)
                            flushActionsCache()
                            setOpen(false)
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DownloadButton
