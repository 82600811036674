import { Toolbar } from '@mui/material'
import { Toolbar as RaToolbar, SaveButton, useRecordContext } from 'react-admin'

import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
import PresetExportButton from './PresetExportButton'

const PresetManagerToolbar = (props) => {
    const record = useRecordContext()
    const modelId = record?.model?.id
    const presetId = record?.id as number
    const published = record?.published
    const [flushActionsCache] = useFlushActionsCacheMutation()
    const handleSaveButtonClick = () => (props.isWithActionsFlush ? flushActionsCache() : undefined)

    return (
        <Toolbar sx={{ position: 'fixed', bottom: 0, margin: 0, zIndex: 2 }} disableGutters>
            <RaToolbar {...props} sx={{ margin: 0 }}>
                <SaveButton onClick={handleSaveButtonClick} alwaysEnable={true} />
                {modelId ? (
                    <PresetExportButton
                        id={modelId}
                        presetId={presetId}
                        published={published}
                        isWithActionsFlush={props.isWithActionsFlush}
                    />
                ) : null}
            </RaToolbar>
        </Toolbar>
    )
}
export default PresetManagerToolbar
