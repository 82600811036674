import LayersIcon from '@mui/icons-material/Layers'
import PresetCreate from './PresetCreate'
import PresetEdit from './PresetEdit'
import PresetList from './PresetList'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PresetList,
    edit: PresetEdit,
    create: PresetCreate,
    icon: LayersIcon
}
