import TextField from '@mui/material/TextField'
import { useInput, useTranslate } from 'react-admin'

const TextAreaInput = (props) => {
    const translate = useTranslate()
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput(props)

    return (
        <TextField
            {...field}
            style={{ width: '100%' }}
            label={props.label}
            variant="outlined"
            multiline={props.multiline}
            rows={props.rows}
            error={!!(isTouched && invalid)}
            helperText={isTouched && invalid && translate('manager.required')}
            required={props.required}
        />
    )
}

export default TextAreaInput
