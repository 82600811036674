import { Dialog } from '@mui/material'
import AvailableProductsSelect from 'components/formfields/AvailableProductsSelect'
import { BooleanInput, Create, SimpleForm, useRefresh, useTranslate } from 'react-admin'

export const PackageProductDialog = ({ open, toggleModal, props, availableProducts }) => {
    const RESOURCE = 'PackageProduct'
    const translate = useTranslate()
    const packageId = props?.filter?.package_id
    const reload = useRefresh()
    /*
     * Closes modal and re-renders after saving
     */
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    return (
        <Dialog open={open} onClose={() => toggleModal()} maxWidth={false}>
            <Create resource={RESOURCE} mutationOptions={{ onSuccess: handleSuccess }} {...props}>
                <SimpleForm
                    sx={{
                        width: 900
                    }}
                    defaultValues={{
                        sku: undefined,
                        package_id: packageId,
                        is_main: false,
                        is_optional: false,
                        is_included: false,
                        active: false,
                        type: undefined
                    }}
                >
                    <AvailableProductsSelect
                        isPackage={props.isPackage}
                        required
                        products={availableProducts}
                    />
                    <BooleanInput
                        row
                        source="is_main"
                        label={translate(`manager.resources.${RESOURCE}.is_main`)}
                    />
                    <BooleanInput
                        row
                        source="is_optional"
                        label={translate(`manager.resources.${RESOURCE}.is_optional`)}
                    />
                    <BooleanInput
                        row
                        source="is_included"
                        label={translate(`manager.resources.${RESOURCE}.is_included`)}
                    />
                    <BooleanInput
                        row
                        source="active"
                        label={translate('manager.resources.general.published')}
                    />
                </SimpleForm>
            </Create>
        </Dialog>
    )
}
