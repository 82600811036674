import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Chip from '@mui/material/Chip'
import { RaRecord, useNotify } from 'ra-core'
import { useState } from 'react'
import {
    Button,
    CreateButton,
    Datagrid,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    ListProps,
    Pagination,
    sanitizeListRestProps,
    TextField,
    TopToolbar,
    useTranslate
} from 'react-admin'
import styled from 'styled-components'

import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'

import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import UploadDialog from 'components/common/UploadDialog'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { useRetailersImportRetailerClassRepresentativesMutation } from '../../apollo/upload/mutations/RetailersImportRetailerClassRepresentatives.generated'
import RetailerClassRepresentativeListFilter from './RetailerClassRepresentativeListFilter'

const ListActions = (props) => {
    const t = useTranslate()
    const { className, maxResults, forceUpdate, ...rest } = props
    const [openDialog, setOpenDialog] = useState(false)
    const [uploadRetailerClassRepresentatives] =
        useRetailersImportRetailerClassRepresentativesMutation()
    const notify = useNotify()

    const handleUpload = (files) => {
        if (files?.[0]) {
            uploadRetailerClassRepresentatives({
                variables: {
                    file: files?.[0]
                }
            })
                .then(() => {
                    setOpenDialog(false)
                    forceUpdate()
                })
                .catch((error) => notify(error.message, { type: 'error' }))
        }
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton />
            <ExportButton />
            <Button
                onClick={() => {
                    setOpenDialog(true)
                }}
                label={t('manager.resources.retailerClassRepresentatives.upload')}
            >
                <CloudUploadIcon />
            </Button>
            <UploadDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                acceptedFiles={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]}
                handleSave={handleUpload}
                filesLimit={1}
            />
        </TopToolbar>
    )
}
const RetailerClassRepresentativeList = (props: ListProps) => {
    const translate = useTranslate()
    const RESOURCE = 'RetailerClassRepresentative'

    const forceUpdate = () => window.location.reload()

    return (
        <ListWrapper>
            <List
                sx={{
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 700
                    }
                }}
                resource={RESOURCE}
                sort={{ field: 'name', order: 'ASC' }}
                empty={false}
                perPage={DEFAULT_PER_PAGE}
                filters={RetailerClassRepresentativeListFilter}
                actions={<ListActions forceUpdate={forceUpdate} />}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid
                    body={<CustomDatagridBody />}
                    rowClick="edit"
                    bulkActionButtons={<BulkActionButtons />}
                >
                    <TextField
                        source="name"
                        label={translate('manager.resources.contactPersons.name')}
                    />
                    <TextField
                        source="email"
                        label={translate('manager.resources.contactPersons.email')}
                    />
                    <TextField
                        source="ssn"
                        label={translate('manager.resources.contactPersons.ssn')}
                    />
                    <TextField
                        source="telephone"
                        label={translate('manager.resources.contactPersons.phone')}
                    />
                    <FunctionField
                        label={translate('manager.resources.contactPersons.retailers')}
                        render={(record: RaRecord) => {
                            return record?.retailers && record.retailers.length > 0 ? (
                                record.retailers.map((retailer) => (
                                    <Chip
                                        size="small"
                                        label={retailer.name}
                                        sx={{ margin: '2px' }}
                                    />
                                ))
                            ) : (
                                <> — </>
                            )
                        }}
                    />
                    <FunctionField
                        label={translate('manager.resources.contactPersons.classes')}
                        render={(record: RaRecord) => {
                            return record?.classes && record.classes.length > 0 ? (
                                record.classes.map((_classes) => (
                                    <Chip
                                        size="small"
                                        label={_classes.name}
                                        sx={{ margin: '2px' }}
                                    />
                                ))
                            ) : (
                                <> — </>
                            )
                        }}
                    />
                    <TextField
                        source="discount_amount"
                        label={translate('manager.resources.contactPersons.discount_amount')}
                    />
                    <TextField
                        source="discount_percentage"
                        label={translate('manager.resources.contactPersons.discount_percentage')}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                </Datagrid>
            </List>
        </ListWrapper>
    )
}

const ListWrapper = styled.section`
    & .MuiToolbar-root {
        > form {
            flex-wrap: initial;
        }
        > div.MuiToolbar-root {
            width: auto;
        }
    }
`
export default RetailerClassRepresentativeList
