import { GetTreeItemChildrenFn, TreeItem } from 'react-sortable-tree'

interface SiblingIds {
    previousSiblingId: string | null
    nextSiblingId: string | null
}

export const getSiblingIds = (
    node: TreeItem,
    siblings: TreeItem[] | GetTreeItemChildrenFn
): SiblingIds => {
    let previousSiblingId: string | null = null
    let nextSiblingId: string | null = null

    if (typeof siblings !== 'function') {
        const siblingIndex = siblings.findIndex((sibling) => sibling.id === node.id)

        if (siblingIndex !== -1) {
            previousSiblingId = siblings[siblingIndex - 1]?.id?.toString() || null
            nextSiblingId = siblings[siblingIndex + 1]?.id?.toString() || null
        }
    }

    return { previousSiblingId, nextSiblingId }
}
