import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography
} from '@mui/material'
import _ from 'lodash'
import { RaRecord, useRecordContext } from 'ra-core'
import { useState } from 'react'
import {
    ArrayField,
    Datagrid,
    FunctionField,
    TextField,
    TopToolbar,
    useRefresh,
    useTranslate
} from 'react-admin'

import AutoCompleteSelectWithData from 'components/formfields/AutoCompleteSelectWithData'

import { useCreateClassRepresentativeMutation } from 'apollo/contact_person_manager/mutations/CreateClassRepresentative.generated'
import { useCreateRetailerRepresentativeMutation } from 'apollo/contact_person_manager/mutations/CreateRetailerRepresentative.generated'
import { useDeleteClassRepresentativeMutation } from 'apollo/contact_person_manager/mutations/DeleteClassRepresentative.generated'
import { useDeleteRetailerRepresentativeMutation } from 'apollo/contact_person_manager/mutations/DeleteRetailerRepresentative.generated'
import { useRetailersRetailerClassesQuery } from 'apollo/contact_person_manager/queries/RetailersRetailerClasses.generated'
import { useRetailersRetailersQuery } from 'apollo/retailer_manager/queries/RetailersRetailers.generated'
import { formStyles } from 'layout/styles/formStyles'

const RetailerClassList = (props) => {
    const translate = useTranslate()
    const record = useRecordContext(props)
    const reload = useRefresh()

    const [open, setOpen] = useState(false)
    const [selectedRetailerID, setSelectedRetailerID] = useState<number | null>(null)
    const [selectedClassID, setSelectedClassID] = useState<number | null>(null)

    const [
        createRetailerRepresentativeMutation,
        { loading: isLoadingCreateRetailerRepresentativeMutation }
    ] = useCreateRetailerRepresentativeMutation()
    const [
        deleteRetailerRepresentativeMutation,
        { loading: isLoadingDeleteRetailerRepresentativeMutation }
    ] = useDeleteRetailerRepresentativeMutation()

    const [createClassRepresentativeMutation] = useCreateClassRepresentativeMutation()
    const [
        deleteClassRepresentativeMutation,
        { loading: isLoadingDeleteClassRepresentativeMutation }
    ] = useDeleteClassRepresentativeMutation()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handleRetailerAutocompleteChange = ({ id }) => {
        setSelectedRetailerID(Number(id))
    }

    const handleClassAutocompleteChange = ({ id }) => {
        setSelectedClassID(Number(id))
    }

    const handlePopupSubmit = () => {
        if (selectedRetailerID && !selectedClassID && record)
            createRetailerRepresentativeMutation({
                variables: {
                    representativeId: +record.id,
                    retailerId: selectedRetailerID
                }
            })
                .then(() => {
                    reload()
                })
                .catch((error) => {
                    console.error(error)
                })

        if (selectedClassID && record) {
            createClassRepresentativeMutation({
                variables: {
                    representativeId: +record.id,
                    retailerClassId: selectedClassID
                }
            })
                .then(() => {
                    reload()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const handleRetailerDeleteClick = (event, id) => {
        if (record) {
            deleteRetailerRepresentativeMutation({
                variables: {
                    representativeId: +record?.id,
                    retailerId: id
                }
            })
                .then(() => {
                    reload()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const handleClassDeleteClick = (event, id) => {
        if (record) {
            deleteClassRepresentativeMutation({
                variables: {
                    representativeId: +record?.id,
                    retailerClassId: id
                }
            })
                .then(() => {
                    reload()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const { data: retailers = {} } = useRetailersRetailersQuery({
        variables: {
            first: 10000
        },
        fetchPolicy: 'cache-and-network'
    })

    const filteredRetailers = _.differenceBy(
        retailers?.retailersRetailers?.data,
        record?.retailers,
        'id'
    )

    const { data: classes = {} } = useRetailersRetailerClassesQuery({
        variables: {
            filter: {
                retailer_id: selectedRetailerID
            }
        },
        fetchPolicy: 'cache-and-network'
    })

    const filteredClasses = _.differenceBy(
        classes?.retailersRetailerClasses?.data,
        record?.classes,
        'id'
    )

    return (
        <>
            <TopToolbar>
                <Button color="primary" variant="outlined" onClick={togglePopup}>
                    <AddIcon />
                    {translate(`manager.resources.contactPersons.add_retailer_class`)}
                </Button>
            </TopToolbar>

            <Grid container spacing={4} sx={formStyles.fullWidth} style={{ marginBottom: 30 }}>
                <Grid item md={6} style={{ position: 'relative' }}>
                    <ArrayField source="retailers">
                        <Datagrid
                            sx={{
                                ...(isLoadingDeleteRetailerRepresentativeMutation && {
                                    opacity: 0.5,
                                    cursor: 'progress',
                                    pointerEvents: 'none'
                                })
                            }}
                        >
                            <TextField
                                source="name"
                                label={translate('manager.resources.contactPersons.retailer_name')}
                            />
                            <FunctionField
                                label={translate('manager.resources.general.actions')}
                                render={(record: RaRecord) =>
                                    record?.id && (
                                        <Tooltip
                                            title={translate('manager.resources.general.delete')}
                                        >
                                            <DeleteIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        transform: `scale(1.1)`
                                                    }
                                                }}
                                                onClick={(event) =>
                                                    handleRetailerDeleteClick(event, +record?.id)
                                                }
                                            />
                                        </Tooltip>
                                    )
                                }
                            />
                        </Datagrid>
                    </ArrayField>
                    {isLoadingDeleteRetailerRepresentativeMutation && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                            }}
                        />
                    )}
                </Grid>
                <Grid item md={6} style={{ position: 'relative' }}>
                    <ArrayField source="classes">
                        <Datagrid
                            sx={{
                                ...(isLoadingDeleteClassRepresentativeMutation && {
                                    opacity: 0.5,
                                    cursor: 'progress',
                                    pointerEvents: 'none'
                                })
                            }}
                        >
                            <TextField
                                source="name"
                                label={translate('manager.resources.contactPersons.class_name')}
                            />
                            <FunctionField
                                label={translate('manager.resources.general.actions')}
                                render={(record: RaRecord) =>
                                    record?.id && (
                                        <Tooltip
                                            title={translate('manager.resources.general.delete')}
                                        >
                                            <DeleteIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        transform: `scale(1.1)`
                                                    }
                                                }}
                                                onClick={(event) =>
                                                    handleClassDeleteClick(event, +record?.id)
                                                }
                                            />
                                        </Tooltip>
                                    )
                                }
                            />
                        </Datagrid>
                    </ArrayField>
                    {isLoadingDeleteClassRepresentativeMutation && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                            }}
                        />
                    )}
                </Grid>
            </Grid>

            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.contactPersons.add_retailer_class')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <AutoCompleteSelectWithData
                        required
                        format
                        source="retailers"
                        data={filteredRetailers ?? []}
                        onChange={handleRetailerAutocompleteChange}
                        disabled={filteredRetailers.length === 0}
                    />
                    <AutoCompleteSelectWithData
                        format
                        source="classes"
                        data={filteredClasses ?? []}
                        onChange={handleClassAutocompleteChange}
                        disabled={!filteredClasses || filteredClasses?.length === 0}
                    />
                    <DialogActions
                        sx={{
                            padding: 0,
                            paddingBottom: '1rem'
                        }}
                    >
                        <Button onClick={togglePopup} variant="contained">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={
                                !selectedRetailerID || isLoadingCreateRetailerRepresentativeMutation
                            }
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingCreateRetailerRepresentativeMutation && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px'
                                    }}
                                />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RetailerClassList
