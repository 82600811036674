import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { RaRecord, useTranslate } from 'ra-core'
import { Datagrid, FunctionField, List, ListProps, Pagination, TextField } from 'react-admin'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globalVariables'
import RetailerYearListFilter from './EducationListFilters'

const EducationList = (props: ListProps) => {
    const translate = useTranslate()
    return (
        <List
            {...props}
            perPage={DEFAULT_PER_PAGE}
            filters={RetailerYearListFilter}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            sx={{
                '& .RaDatagrid-headerCell': {
                    fontWeight: 700
                }
            }}
        >
            <Datagrid
                sort={{ field: 'name', order: 'DESC' }}
                bulkActionButtons={<BulkActionButtons />}
                body={<CustomDatagridBody />}
                rowClick="edit"
            >
                <TextField source="name" label={translate('manager.resources.education.name')} />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record: RaRecord) => (
                        <DatagridPublishedColumnContent record={record} />
                    )}
                />
                <TextField
                    source="priority"
                    label={translate('manager.resources.education.priority')}
                />
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default EducationList
