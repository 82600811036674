/* eslint-disable no-unused-vars */
import { JSX, useContext, useEffect, useState } from 'react'
import SortableTree, { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import rfdc from 'rfdc'
// MUI
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, Button, CircularProgress, Grid } from '@mui/material'

// GQL
import { usePresetNodeQuery } from 'apollo/configurator/queries/PresetNode.generated'

import { RemoveButton } from 'components/modelManager/atoms/buttons/Buttons'
import {
    findPresetByPresetIdInArray,
    formatPresetTreeState
} from 'components/presetManager/helpers/TreeHelperFunctions'
import { PresetModelContext } from 'context/preset-model/PresetModelContext'
import { COLORS } from 'layout/theme/colors'
import { useTranslate } from 'react-admin'
import { INodeWithPreset, IPresetTreeView, RemoveAbleItemProps } from 'ts/interfaces'
import { getNodeStyle, getNodeTitle } from './PresetNodeRenderHelper'
import RemovePresetNodePopup from './RemovePresetNodePopup'
import { TreeViewWrapper } from './styling/TreeViewWrapper'
import VisibilityButton from './VisiblityButton'

const TreeView = ({ handleNodeClick, reRenderCallback, presetId, rootNode }: IPresetTreeView) => {
    const sx = {
        root: {
            height: '100%',
            position: 'relative',
            overflow: 'scroll',
            marginBottom: '50px'
        },
        nodeActionButton: {
            height: '100%',
            color: `${COLORS.theme.grey.main}`,
            '&:hover': {
                color: `${COLORS.theme.grey.dark}`,
                backgroundColor: 'transparent'
            }
        }
    }
    const { refetch: refetchPresetNodeData } = usePresetNodeQuery({
        variables: { configuratorPresetNodeId: 0 as any },
        skip: true
    })

    const translate = useTranslate()
    const [uniqueKey, setUniqueKey] = useState<number>(0)
    const [syncing, setSyncing] = useState<boolean>(false)
    const [treeState, setTreeState] = useState<TreeItem[] | null>()
    const [selectedNode, setSelectedNode] = useState<INodeWithPreset | null>(rootNode as any)
    const { state: presetModelState, setState: setPresetModelState } =
        useContext(PresetModelContext)

    const [removeableItem, setRemoveableItem] = useState<RemoveAbleItemProps | null>()

    // Initialize tree with initial rootnode
    useEffect(() => {
        if (!!rootNode) intializeTree(rootNode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootNode])

    const deepCloneWithCustomLogic = async (
        node: TreeItem,
        customizer: (node: TreeItem) => Promise<TreeItem>
    ): Promise<TreeItem> => {
        // Clone the current node
        const clonedNode = rfdc()(node)

        // If the node has children, recursively clone them
        if (clonedNode.children && Array.isArray(clonedNode.children)) {
            clonedNode.children = await Promise.all(
                clonedNode.children.map((child) => deepCloneWithCustomLogic(child, customizer))
            )
        }

        // Apply the customizer function to the cloned node
        return customizer(clonedNode)
    }

    const intializeTree = async (rootNode: any): Promise<TreeItem[]> => {
        if (rootNode) {
            // Make sure the tree expands when edited, else the tree would collapse constantly.
            const tree = [formatPresetTreeState(rootNode, !!presetModelState.hasEdited)]

            //TODO remove the comments and fix this
            // Parse preset nodes onto node objects
            const newTree = await parsePresetNodes(tree)

            // Set tree state after it has been parsed, automatically updates local treeState by useEffect
            setTreeState(newTree)

            // Update treeState in context
            setPresetModelState((prev) => ({
                ...prev,
                treeState: newTree, // TODO: Why tree and not newTree here? newTree breaks hasPreset it seems
                reRenderTree: false
            }))

            // If there are no changes, select the first node. This shows the form directly on model selection.

            !presetModelState.hasEdited && handleNodeClick(newTree?.[0]?.id, 'null')

            // return
            return newTree
        } else {
            handleNodeClick(0, 'null')
            setPresetModelState((prev) => ({ ...prev, currentModel: '' }))
        }
        return []
    }

    // Align local treeState with presetModelContext treeState
    useEffect(() => {
        if (presetModelState.treeState !== treeState) setTreeState(presetModelState.treeState)
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.treeState])

    // Handle re-render from context
    useEffect(() => {
        if (!!presetModelState.reRenderTree) {
            setPresetModelState((prev) => ({ ...prev, reRenderTree: false, hasChanges: true }))
            reRender()
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.reRenderTree])

    // Update selectedNode in PresetModelContext
    useEffect(() => {
        if (!!selectedNode && selectedNode !== presetModelState.selectedNode)
            setPresetModelState((prev) => ({
                ...prev,
                hasChanges: true,
                selectedNode: selectedNode,
                reRenderForm: true
            }))
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode])

    // Re-render entire component with callback or locally
    const reRender = async (entireComponent = false): Promise<void> => {
        if (entireComponent) {
            reRenderCallback()
        } else {
            setSyncing(true)
            setPresetModelState((prev) => ({
                ...prev,
                reRenderTree: false
            }))
            await parsePresetNodes(presetModelState.treeState)
                .then((newTree) => setTreeState(newTree))
                .finally(
                    async () => await reRenderSortableTreeComponent().then(() => setSyncing(false))
                )
        }
    }

    // Update key
    const reRenderSortableTreeComponent = async (): Promise<void> => {
        setUniqueKey(uniqueKey + 1)
    }

    // Adds presetData to the node objects in the tree.
    // Which then can be used in the generateNodeProps function of react-sortable-tree
    const parsePresetNodes = async (tree: TreeItem[]): Promise<TreeItem[]> => {
        let previousObject: TreeItem = {} as TreeItem

        const addPresetsToTree = async (node: TreeItem): Promise<TreeItem> => {
            if (node && node.hasOwnProperty('expanded') && node !== previousObject) {
                previousObject = node
                const parsedSinglePresetNode = await parseSinglePresetNode(node)
                return parsedSinglePresetNode
            }
            return node
        }

        const parsedTree = await Promise.all(
            tree.map(async (node) => {
                return deepCloneWithCustomLogic(node, addPresetsToTree)
            })
        )

        return parsedTree
    }

    // Parses a presetNode onto a node object
    const parseSinglePresetNode = async (node: TreeItem): Promise<TreeItem> => {
        if (!node) return node

        const { has_nodes_of_presets: hasNodesOfPresets } = node
        const index = findPresetByPresetIdInArray(hasNodesOfPresets, presetId)

        if (index === -1) return node

        // Assign presetId to every node object
        node.presetId = presetId

        // A preset exists for this node
        if (index > -1) {
            // get the presetNodeId from the hasNodesOfPresets array by index
            const presetNodeId = hasNodesOfPresets?.[index]?.preset_node_id

            // Assign presetNodeId to node object
            node.presetNodeId = presetNodeId

            // fetch presetNode
            await refetchPresetNodeData({
                configuratorPresetNodeId: presetNodeId
            })?.then(({ data: { configuratorPresetNode } }) => {
                // Assign presetNode to node object
                node.presetNode = configuratorPresetNode
            })
        }

        return node
    }

    const getNodeActions = (node: TreeItem): JSX.Element[] => {
        const { id, parent_id, presetNode } = node
        const buttons: JSX.Element[] = []

        // All visiblity button
        !!node.children &&
            node.children.length > 0 &&
            buttons.push(
                <VisibilityButton
                    node={node}
                    presetId={presetId}
                    sx={sx.nodeActionButton}
                    reRenderCallback={() => reRender(true)}
                    includingChildNodes
                />
            )

        // Visiblity button
        buttons.push(
            <VisibilityButton
                presetId={presetId}
                node={node}
                sx={sx.nodeActionButton}
                reRenderCallback={() => reRender(true)}
            />
        )

        // Remove button
        !!presetNode &&
            buttons.push(
                <RemoveButton
                    key={id}
                    sx={sx.nodeActionButton}
                    onClick={() => {
                        setRemoveableItem({
                            id: +id,
                            parent_id: parent_id as any,
                            node: node as TreeItem
                        })
                    }}
                />
            )

        // Return
        return buttons
    }

    const generateNodeProps = (extendedNode) => {
        const node = extendedNode?.node
        const { id, parent_id } = node

        // Add path to node object
        node.path = extendedNode.path

        return {
            style: getNodeStyle(node),
            title: getNodeTitle(node),
            buttons: getNodeActions(node),
            onClick: () => {
                handleNodeClick(id, parent_id)
                setSelectedNode(node)
            }
        }
    }

    if (!treeState) return <CircularProgress />
    return (
        <Box sx={sx.root}>
            {treeState && (
                <TreeViewWrapper>
                    <Grid container justifyContent="flex-end">
                        <Grid item md={3}>
                            <Button
                                className="refresh-button"
                                onClick={() => reRender(true)}
                                endIcon={<ReplayIcon />}
                            >
                                {translate('manager.resources.preset.manager.sync')}
                            </Button>
                        </Grid>
                        <Grid item md={12}>
                            {!syncing && (
                                <SortableTree
                                    key={uniqueKey}
                                    canDrag={false}
                                    isVirtualized={false}
                                    scaffoldBlockPxWidth={30}
                                    getNodeKey={({ node }) => node?.id}
                                    treeData={treeState}
                                    onChange={(treeData: any) => {
                                        setTreeState(treeData)
                                        setPresetModelState((prev) => ({
                                            ...prev,
                                            hasChanges: true,
                                            treeState: treeData
                                        }))
                                    }}
                                    theme={{ rowHeight: 55 }}
                                    generateNodeProps={(node: ExtendedNodeData) =>
                                        generateNodeProps(node)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </TreeViewWrapper>
            )}
            {!!removeableItem && (
                <RemovePresetNodePopup
                    removeableItem={removeableItem}
                    treeState={treeState}
                    onSubmitCallback={(tree: TreeItem[]) => {
                        setTreeState(tree)
                        setPresetModelState((prev) => ({
                            ...prev,
                            hasChanges: true,
                            treeState: tree,
                            reRenderForm: true,
                            reRenderTree: true // Because it is possible that has_descendants color needs to be removed from parent nodes
                        }))
                    }}
                    setRemovableItemCallback={(item: RemoveAbleItemProps | null) => {
                        setRemoveableItem(item)
                    }}
                />
            )}
        </Box>
    )
}

export default TreeView
