import { useTheme } from '@material-ui/core'
import MuiDialogContent from '@mui/material/DialogContent'
import { ComponentProps } from 'react'

type GalleryPopContentProps = {
    children: React.ReactNode
} & ComponentProps<typeof MuiDialogContent>
const GalleryPopupContent = ({ children, ...props }: GalleryPopContentProps) => {
    const theme = useTheme()
    const sx = {
        padding: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        maxHeight: 'calc(100vh - 200px)'
    }
    return (
        <MuiDialogContent {...props} {...sx}>
            {children}
        </MuiDialogContent>
    )
}

export default GalleryPopupContent
