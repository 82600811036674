import { DropzoneDialog } from 'material-ui-dropzone'

interface UploadDialogProps {
    open: boolean
    acceptedFiles: string[]
    handleSave: (files: any) => void
    handleClose: () => void
    filesLimit: number
}

const UploadDialog = ({
    open,
    acceptedFiles,
    handleSave,
    handleClose,
    filesLimit
}: UploadDialogProps) => {
    return (
        <DropzoneDialog
            open={open}
            onSave={handleSave}
            acceptedFiles={acceptedFiles}
            showPreviews={true}
            maxFileSize={5000000}
            onClose={handleClose}
            showAlerts={false}
            filesLimit={filesLimit}
        />
    )
}

export default UploadDialog
