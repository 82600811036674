import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@mui/material'
import { RaRecord, useRecordContext, useRefresh } from 'ra-core'
import { useState } from 'react'
import {
    ArrayField,
    Datagrid,
    FunctionField,
    TextField,
    TopToolbar,
    useTranslate
} from 'react-admin'
import styled from 'styled-components'

import ConfiguratorPresetsSelect from 'components/formfields/ConfiguratorPresetsSelect'
import WarningDialog from 'components/modelManager/molecules/popups/WarningDialog'

import { usePackagesCreatePackageGroupPresetMutation } from 'apollo/package_manager/mutations/PackagesCreatePackageGroupPreset.generated'
import { usePackagesDeletePackageGroupPresetMutation } from 'apollo/package_manager/mutations/PackagesDeletePackageGroupPreset.generated'
import BulkActionButtons from 'components/common/BulkActionButtons'
import AvailableProductsSelectSingle from 'components/formfields/AvailableProductsSelectSingle'

const PackageGroupProductList = () => {
    const RESOURCE = 'PackageGroupProduct'
    const record = useRecordContext()
    const id = record?.id
    const [isOpenedPopup, setIsOpenedPopup] = useState<boolean>(false)
    const [isOpenedConfirmPopup, setIsOpenedConfirmPopup] = useState<boolean>(false)
    const [deletePresetId, setDeletePresetId] = useState<number>(0)
    const [code, setCode] = useState<string>()
    const [sku, setSku] = useState<string>()

    const refresh = useRefresh()
    const translate = useTranslate()
    const sx = {
        dialogActions: {
            padding: 0,
            paddingBottom: '1rem'
        },
        deleteIcon: {
            '&:hover': {
                transform: `scale(1.1)`
            }
        }
    }

    const [packagesCreatePackageGroupPresetMutation] = usePackagesCreatePackageGroupPresetMutation()
    const [packagesDeletePackageGroupPresetMutation] = usePackagesDeletePackageGroupPresetMutation()

    const toggleModal = () => {
        setSku('')
        setCode('')
        setIsOpenedPopup(!isOpenedPopup)
    }

    const handleCloseConfirmPopup = () => {
        setIsOpenedConfirmPopup(false)
    }

    const handleContinueConfirmPopup = () => {
        packagesDeletePackageGroupPresetMutation({
            variables: { id: `${deletePresetId}` }
        }).then(() => {
            setIsOpenedConfirmPopup(false)
            refresh()
        })
    }

    const handleBulkDelete = async (selectedIds: string[]) => {
        selectedIds.map(async (id) => {
            await packagesDeletePackageGroupPresetMutation({
                variables: {
                    id
                }
            })
        })
    }

    const handleAddRelationFormSubmit = () => {
        if (!id) return
        packagesCreatePackageGroupPresetMutation({
            variables: {
                groupId: Number(id),
                code: code ?? '',
                sku: sku ?? ''
            }
        }).then(() => {
            setIsOpenedPopup(false)
            refresh()
        })
    }

    const handleDeleteClick = (event, id: number) => {
        event?.stopPropagation() // Stops rowClick from firing
        setDeletePresetId(id)
        setIsOpenedConfirmPopup(true)
    }

    return (
        <>
            <ListWrapper>
                <TopToolbar>
                    <Button color="primary" variant="outlined" onClick={toggleModal}>
                        <AddIcon />
                        {translate('manager.resources.packageGroup.preset.add')}
                    </Button>
                </TopToolbar>
                <ArrayField resource={`${RESOURCE}`} source="presets">
                    <Datagrid
                        rowClick={false}
                        bulkActionButtons={
                            <BulkActionButtons
                                isWithActionsFlush
                                customize={{
                                    mutation: handleBulkDelete,
                                    onEnd: () => refresh()
                                }}
                            />
                        }
                    >
                        <TextField
                            source="code"
                            label={translate('manager.resources.packageGroup.preset.code')}
                        />
                        <TextField
                            source="sku"
                            label={translate('manager.resources.packageGroup.preset.sku')}
                        />
                        <FunctionField
                            label={translate('manager.resources.general.actions')}
                            render={(record: RaRecord) =>
                                record?.id && (
                                    <Tooltip title={translate('manager.resources.general.delete')}>
                                        <DeleteIcon
                                            sx={sx.deleteIcon}
                                            onClick={(event) =>
                                                handleDeleteClick(event, +record?.id)
                                            }
                                        />
                                    </Tooltip>
                                )
                            }
                        />
                    </Datagrid>
                </ArrayField>
            </ListWrapper>

            {isOpenedPopup && (
                <Dialog fullWidth open={isOpenedPopup} onClose={() => toggleModal()}>
                    <DialogTitle>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.packageGroup.preset.add')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <AvailableProductsSelectSingle
                            disabled={false}
                            required
                            onChangeCallback={(value) => {
                                setSku(value)
                            }}
                        />
                        <ConfiguratorPresetsSelect
                            required
                            setIsSelectedPreset={(value) => {
                                setCode(value.target.value)
                            }}
                        />
                        <DialogActions sx={sx.dialogActions}>
                            <Button onClick={toggleModal} variant="contained">
                                {translate('manager.resources.packageGroup.preset.cancel')}
                            </Button>
                            <Button
                                onClick={handleAddRelationFormSubmit}
                                variant="contained"
                                color="primary"
                                disabled={!code || !sku}
                            >
                                {translate('manager.resources.packageGroup.preset.submit')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}

            <WarningDialog
                open={isOpenedConfirmPopup}
                handleClose={handleCloseConfirmPopup}
                handleContinue={handleContinueConfirmPopup}
                maxWidth="md"
                title={translate(
                    'manager.resources.packageGroup.package.delete_confirm_popup.title'
                )}
            />
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    padding-bottom: 2rem;

    & .MuiPaper-root {
        border: 0;
    }
`

export default PackageGroupProductList
