import {
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material'
import { useState } from 'react'
import { BulkDeleteButton, Button, useGetMany, useListContext, useTranslate } from 'react-admin'

// Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import IconButton from '@mui/material/IconButton'

// GrapQL
import { usePresetExportModelMutation } from 'apollo/configurator/mutations/PresetExportModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
interface PresetList {
    internalName: string
    status: boolean
}

// Move ExportResultDialog outside the handleExport function
const ExportResultDialog = ({
    open,
    dialogKey,
    processing,
    list,
    handleClose,
    handleDialogClose
}: {
    open: boolean
    dialogKey?: number
    processing: boolean
    list?: PresetList[]
    handleClose: () => void
    handleDialogClose: (_event: {}, reason: string) => void
}) => {
    return (
        <Dialog
            onClose={(event, reason) => handleDialogClose(event, reason)}
            open={open}
            key={dialogKey}
            disableEscapeKeyDown
        >
            <IconButton
                disabled={processing}
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <List>
                    <ListItem dense>
                        <ListItemText primary={'Status'} />
                    </ListItem>
                    <Divider />
                    {processing && <CircularProgress />}
                    {!processing &&
                        list &&
                        list.map((preset, index) => {
                            return (
                                <ListItem dense key={index}>
                                    <ListItemIcon>
                                        {preset.status ? (
                                            <CheckBoxIcon color="success" />
                                        ) : (
                                            <ClearIcon color="error" />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={preset?.internalName} />
                                </ListItem>
                            )
                        })}
                </List>
            </DialogContent>
        </Dialog>
    )
}

type PresetBulkActionButtonsProps = {
    isWithActionsFlush?: boolean
}

export const PresetBulkActionButtons = ({ isWithActionsFlush }: PresetBulkActionButtonsProps) => {
    const translate = useTranslate()
    const [presetExportModelMutation] = usePresetExportModelMutation()
    const [dialogKey, setDialogKey] = useState<number>()
    const [open, setOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [list, setList] = useState<PresetList[]>()

    const { selectedIds } = useListContext()

    // const { selectedIds } = props

    const [flushActionsCache] = useFlushActionsCacheMutation()

    // Fetch all presets based on selectedIds in bulkAction
    const { data: selectedPresets } = useGetMany('Preset', { ids: selectedIds })

    // Close dialog and reset
    const handleClose = () => {
        setOpen(false)
        setList([])
        setProcessing(false)
    }

    // To handle deprecated disableBackdropClick on <Dialog />
    const handleDialogClose = (_event: {}, reason: string) => {
        // Disable backdropClick
        if (reason === 'backdropClick') return false
        handleClose()
    }

    const handleExport = async () => {
        setOpen(true)
        setProcessing(true)
        const exportListResult: PresetList[] = []
        if (selectedPresets) {
            // Loop selected presets
            selectedPresets.forEach((preset, index) => {
                const presetId = parseInt(`${preset.id}`)
                const configuratorExportModelId = preset.parent_model_id

                if (configuratorExportModelId && presetId) {
                    presetExportModelMutation({
                        variables: {
                            configuratorExportModelId: configuratorExportModelId,
                            preset: presetId
                        }
                    }).then((res) => {
                        exportListResult.push({
                            internalName: preset?.internal_name,
                            status: !!res?.data?.configuratorExportModel?.code
                        })
                        setList(exportListResult)
                        setDialogKey(index)
                    })
                }
            })
        }
    }

    const handlePublishButtonClick = () => {
        handleExport().then(() => {
            setProcessing(false)
        })
        if (isWithActionsFlush) flushActionsCache()
    }

    const handleDeleteButtonClick = () => (isWithActionsFlush ? flushActionsCache() : undefined)

    return (
        <>
            <Button
                onClick={handlePublishButtonClick}
                label={translate('manager.resources.preset.publish')}
            >
                <CloudUploadIcon />
            </Button>
            <BulkDeleteButton onClick={handleDeleteButtonClick} />

            <ExportResultDialog
                open={open}
                dialogKey={dialogKey}
                processing={processing}
                list={list}
                handleClose={handleClose}
                handleDialogClose={handleDialogClose}
            />
        </>
    )
}
