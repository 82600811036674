import Chip from '@mui/material/Chip'
import {
    ArrayField,
    Datagrid,
    FunctionField,
    TextField,
    useRecordContext,
    useTranslate
} from 'react-admin'
import styled from 'styled-components'

import { Typography } from '@mui/material'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const RetailerClassRepresentativeListAsArrayField = (props) => {
    const translate = useTranslate()
    const record = useRecordContext(props)

    return (
        <ListWrapper>
            <Typography className="title" variant="h6" gutterBottom>
                {translate('manager.resources.retailerClass.representatives')}
            </Typography>
            {record?.representatives?.length > 0 ? (
                <ArrayField source="representatives">
                    <Datagrid
                        className="table"
                        rowClick={(_, __, record) => `/retailerClassRepresentative/${record.id}`}
                    >
                        <TextField
                            source="name"
                            label={translate('manager.resources.contactPersons.name')}
                        />
                        <TextField
                            source="email"
                            label={translate('manager.resources.contactPersons.email')}
                        />
                        <TextField
                            source="ssn"
                            label={translate('manager.resources.contactPersons.ssn')}
                        />
                        <TextField
                            source="telephone"
                            label={translate('manager.resources.contactPersons.phone')}
                        />
                        <FunctionField
                            label={translate('manager.resources.contactPersons.retailers')}
                            render={(record: Record<string, any>) => {
                                return record?.retailers && record.retailers.length > 0 ? (
                                    record.retailers.map((retailer) => (
                                        <Chip
                                            size="small"
                                            label={retailer.name}
                                            sx={{ margin: '2px' }}
                                        />
                                    ))
                                ) : (
                                    <> — </>
                                )
                            }}
                        />
                        <FunctionField
                            label={translate('manager.resources.contactPersons.classes')}
                            render={(record: Record<string, any>) => {
                                return record?.classes && record.classes.length > 0 ? (
                                    record.classes.map((_classes) => (
                                        <Chip
                                            size="small"
                                            label={_classes.name}
                                            sx={{ margin: '2px' }}
                                        />
                                    ))
                                ) : (
                                    <> — </>
                                )
                            }}
                        />
                        <TextField
                            source="discount_amount"
                            label={translate('manager.resources.contactPersons.discount_amount')}
                        />
                        <TextField
                            source="discount_percentage"
                            label={translate(
                                'manager.resources.contactPersons.discount_percentage'
                            )}
                        />
                        <DateFieldCustom
                            label={translate('manager.resources.general.last_updated')}
                            source="updated_at"
                            showTime
                            transform={(value) => formatDateString(value, true)}
                        />
                    </Datagrid>
                </ArrayField>
            ) : (
                <Typography variant="body1" gutterBottom>
                    {translate('manager.resources.retailerClass.no_contacts')}
                </Typography>
            )}
        </ListWrapper>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    .title {
        width: 100%;
    }
    & .MuiToolbar-root {
        > form {
            flex-wrap: initial;
        }
        > div.MuiToolbar-root {
            width: auto;
        }
    }
`

export default RetailerClassRepresentativeListAsArrayField
