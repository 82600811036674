import { Box, FormControl, InputLabel, NativeSelect } from '@mui/material'
import { ModelContext } from 'context/model/ModelContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { IModelSelector, INode } from 'ts/interfaces'
import WarningDialog from '../popups/WarningDialog'
import ImportModelButton from './ImportModelButton'
import NewModelButton from './NewModelButton'

interface IState {
    id: number | null
}

const ModelSelector = ({ nodes, setModel }: IModelSelector) => {
    const translate = useTranslate()
    const [state, setState] = React.useState<IState>({ id: null })
    const { state: ModelState, setState: setModelState } = useContext(ModelContext)
    const [showWarning, setShowWarning] = useState(false)
    const [modelCode, setModelCode] = useState<string>('')
    const [changeState, setChangeState] = useState<IState>()
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const name = event.target.name
        const currentModelCode = event?.target?.selectedOptions?.[0]?.getAttribute('data-modelcode')

        if (ModelState.hasChanges) {
            setShowWarning(true)
            setChangeState({ ...state, [name]: event.target.value })

            if (currentModelCode) {
                setModelCode(currentModelCode)
            }
        } else {
            setState({
                ...state,
                [name]: event.target.value
            })

            if (currentModelCode) {
                setModelCode(currentModelCode)
            }
        }
    }
    useEffect(() => {
        if (state?.id) {
            setModel(state.id)
        }
    }, [state, setModel])

    useEffect(() => {
        if (modelCode) {
            setModelState({ ...ModelState, currentModelCode: modelCode })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelCode]) // Disabled becuase it creates an infinite loop in the TreeView

    useEffect(() => {
        if (ModelState.currentModel) {
            setState({ id: +ModelState.currentModel })
        } else {
            setState({ id: null })
        }
    }, [ModelState.currentModel])

    const handleCloseWarning = () => {
        setShowWarning(false)
    }

    const handleContinueWarning = () => {
        changeState && setState(changeState)
        setModelState({
            hasChanges: false,
            hasEdited: false,
            currentModel: '',
            currentModelCode: '',
            updatedModel: false,
            showEditedMessage: false
        })
        setShowWarning(false)
    }

    return (
        <Box
            sx={{
                borderBottom: '1px solid #eaeaea',
                padding: '2rem',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <FormControl
                sx={{
                    margin: 4,
                    marginRight: 10,
                    minWidth: 160
                }}
            >
                {!state.id && <InputLabel htmlFor="code-native-helper">Select a Model</InputLabel>}
                <NativeSelect
                    value={state.id || ''}
                    onChange={handleChange}
                    inputProps={{
                        name: 'id',
                        id: 'id-native-helper'
                    }}
                >
                    <option value=""></option>
                    {nodes.map((node: INode) => (
                        <option value={node.id} key={node.id} data-modelcode={node.code}>
                            {JSON.parse(node.node_attributes).code}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>
            <NewModelButton />
            <ImportModelButton />
            <WarningDialog
                open={showWarning}
                handleClose={handleCloseWarning}
                handleContinue={handleContinueWarning}
                title={`${translate('manager.resources.model_manager.warning_dialog.title')}`}
                content={`${translate('manager.resources.model_manager.warning_dialog.content')}`}
            />
        </Box>
    )
}

export default ModelSelector
