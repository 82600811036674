import { useEffect, useState } from 'react'
import { AutocompleteArrayInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { ChoiceType } from 'types'

const TagsAutocompleteInput = ({ source, initialValues, initialChoices, onCreate }) => {
    const { setValue, watch } = useFormContext()
    const [choices, setChoices] = useState<ChoiceType[]>()
    const [values, setValues] = useState<ChoiceType[]>()

    // Watch for changes in the field
    const fieldValue = watch(source)

    useEffect(() => {
        setValues(initialValues)
    }, [initialValues])

    useEffect(() => {
        setChoices(initialChoices)
    }, [initialChoices])

    // Update form values when the AutocompleteArrayInput changes
    useEffect(() => {
        if (values) {
            setValue(source, values, { shouldDirty: true })
        }
    }, [source, values, setValue])

    return (
        <AutocompleteArrayInput
            fullWidth
            source={source}
            variant="outlined"
            format={(value) => value && value.map((v) => v.id)}
            parse={(value) => value && value.map((v) => ({ id: v }))}
            allowDuplicates={false}
            onCreate={onCreate}
            choices={choices}
        />
    )
}

export default TagsAutocompleteInput
