import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Toolbar } from '@mui/material'
import {
    Toolbar as RaToolbar,
    SaveButton,
    useRedirect,
    useResourceContext,
    useTranslate
} from 'react-admin'
import { useLocation } from 'react-router'

const CustomToolBar = (props) => {
    const translate = useTranslate()
    const redirect = useRedirect()
    const resource = useResourceContext()

    const location = useLocation()
    const { pathname } = location

    // regex to check if the current pathname is either /Retailer/:id/images or /Rule/:id/actions
    const alwaysEnabledPathnameRegex =
        /.*?(\/Retailer\/[^\/]+\/images|\/Rule\/[^\/]+\/actions|\/Rule\/[^\/]+\/rules)$/

    const isActionEdit = alwaysEnabledPathnameRegex.test(pathname)

    return (
        <Toolbar sx={{ position: 'fixed', bottom: 0, margin: 0, zIndex: 2 }} disableGutters>
            <RaToolbar
                {...props}
                sx={{
                    '&.RaToolBar': {
                        marginTop: 0
                    }
                }}
            >
                <SaveButton
                    type={isActionEdit ? 'button' : 'submit'}
                    alwaysEnable={isActionEdit}
                    sx={{
                        marginRight: '1rem'
                    }}
                    label={translate('manager.resources.general.save')}
                />
                <SaveButton
                    label={translate('manager.resources.general.save_and_go_back')}
                    mutationOptions={{
                        onSuccess: () => {
                            redirect('list', resource)
                        }
                    }}
                    color="secondary"
                    icon={<SaveAltIcon />}
                    type="button"
                />
            </RaToolbar>
        </Toolbar>
    )
}
export default CustomToolBar
