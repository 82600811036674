import { useState } from 'react'
import { Create, useRedirect, useTranslate } from 'react-admin'
import { useLocation } from 'react-router-dom'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import RetailerClassForm from './RetailerClassForm'

const RetailerClassCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)
    const redirect = useRedirect()
    const location = useLocation()
    const { retailerId } = location.state

    const handleRedirect = () => {
        redirect(`/Retailer/${retailerId}/classes`)
    }

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Create
                onSuccess={handleRedirect}
                title={translate('manager.resources.retailerClass.create_a_retailerClass')}
                {...props}
            >
                <RetailerClassForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default RetailerClassCreate
