import { Grid2 } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { GalleryContext } from 'context/gallery/GalleryContext'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-admin'
import GalleryPopupActions from '../../atoms/buttons/popup/GalleryPopupActions'
import GalleryPopupContent from '../../atoms/buttons/popup/GalleryPopupContent'
import GalleryPopupTitle from '../../atoms/buttons/popup/GalleryPopupTitle'
import FolderTree from './FolderTree'
import ImagesBlock from './ImagesBlock'
import UploadBlock from './UploadBlock'

const GalleryPopup = () => {
    const translate = useTranslate()
    const { state, setGalleryState } = useContext(GalleryContext)
    const [file, setFile] = useState('')
    const [extension, setExtension] = useState('')

    const handleClose = () => {
        setGalleryState({ ...state, openGallery: false, isUploading: false })
    }

    const handleFileSelection = (url: string) => {
        const fullUrl = `${state.breadcrumbs === '/' ? '' : state.breadcrumbs}/${url.substr(
            0,
            url.lastIndexOf('.')
        )}`
        const extension = `${url.substr(url.lastIndexOf('.'))}`

        setFile(fullUrl)
        setExtension(extension)
    }

    const handleFileSelect = () => {
        return setGalleryState({
            ...state,
            openGallery: false,
            selectedFile: file,
            selectedFileExtension: extension
        })
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={state.openGallery}
                maxWidth={false}
            >
                <GalleryPopupTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    toggleUpload={() =>
                        setGalleryState({ ...state, isUploading: !state.isUploading })
                    }
                    isUploading={state.isUploading}
                >
                    {translate('manager.gallery.gallery')}
                </GalleryPopupTitle>
                {state.isUploading && <UploadBlock />}
                <GalleryPopupContent
                    dividers
                    sx={{
                        width: '95vw'
                    }}
                >
                    <Grid2 container>
                        <Grid2 size={3}>
                            {state.openGallery ? (
                                <FolderTree
                                    wrapperSx={{
                                        height: '64px'
                                    }}
                                />
                            ) : null}
                        </Grid2>
                        <Grid2 size={9}>
                            <ImagesBlock handleFileSelection={handleFileSelection} />
                        </Grid2>
                    </Grid2>
                </GalleryPopupContent>
                <GalleryPopupActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                        {translate('manager.gallery.close')}
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleFileSelect}
                        variant="contained"
                        color="primary"
                    >
                        {translate('manager.gallery.select_file')}
                    </Button>
                </GalleryPopupActions>
            </Dialog>
        </div>
    )
}

export default GalleryPopup
