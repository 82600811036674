import { useEffect, useState } from 'react'
import { Edit, useNotify, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import { usePersonalizationRulesQuery } from 'apollo/personalization/queries/PersonalizationRules.generated'
import { usePersonalizationUpdateRulesPathsMutation } from 'apollo/personalization/queries/PersonalizationUpdateRulesPaths.generated'
import { PersonalizationRulePathUpdateInput } from 'apollo/types.generated'
import RetailerTagForm from './RetailerTagForm'
import { PersonalizationRule, getRetailerTagOccurences } from './utils/getRetailerTagOccurrences'

const RetailerTagEdit = (props) => {
    const translate = useTranslate()
    const notify = useNotify()

    const [isPristineForm, setIsPristineForm] = useState(true)
    const [updateRulesPaths] = usePersonalizationUpdateRulesPathsMutation()
    const [record, setRecord] = useState<Record<string, any>>()

    const [filteredPersonalizationRules, setFilteredPersonalizationRules] =
        useState<Array<PersonalizationRule | PersonalizationRulePathUpdateInput>>()

    const { data: { personalizationRules } = {}, refetch } = usePersonalizationRulesQuery({
        fetchPolicy: 'cache-and-network'
    })

    const transform = async (newRecord: Record<string, any>) => {
        const currentTag = localStorage.getItem('currentTag')
        const { data: { personalizationRules } = {} } = await refetch()

        const filteredPersonalizationRules = newRecord
            ? getRetailerTagOccurences({
                  personalizationRules,
                  record: { ...newRecord, id: newRecord?.id, tag: currentTag },
                  returnPaths: true,
                  newTagValue: newRecord?.tag || ''
              })
            : undefined

        if (filteredPersonalizationRules?.length) {
            updateRulesPaths({
                variables: {
                    updates:
                        filteredPersonalizationRules as Array<PersonalizationRulePathUpdateInput>
                }
            }).catch((err) => {
                notify(`Failed to update rules: ${err.toString()}`)
            })
        }

        return { ...newRecord, notify: true }
    }

    useEffect(() => {
        if (personalizationRules && record) {
            const filteredPersonalizationRules = getRetailerTagOccurences({
                personalizationRules,
                record: { ...record, id: record?.id }
            })

            setFilteredPersonalizationRules(
                filteredPersonalizationRules?.length ? filteredPersonalizationRules : undefined
            )
        }
    }, [personalizationRules, record])

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/retailerTag" />
            <Edit
                title={translate('manager.resources.tag.edit')}
                transform={transform}
                mutationMode={'undoable'}
                {...props}
            >
                <RetailerTagForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                    setRecord={setRecord}
                    filteredPersonalizationRules={filteredPersonalizationRules}
                    {...props}
                />
            </Edit>
        </>
    )
}

export default RetailerTagEdit
