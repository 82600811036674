import { useEffect, useState } from 'react'

import { CircularProgress, Grid } from '@mui/material'

import { useNodeQuery } from 'apollo/configurator/queries/Node.generated'

import { PresetModelContext, PresetModelProvider } from 'context/preset-model/PresetModelContext'
import { COLORS } from 'layout/theme/colors'
import { useRecordContext } from 'ra-core'
import { TreeItem } from 'react-sortable-tree'
import AttributesEditor from './presetManager/AttributesEditor'
import TreeView from './presetManager/TreeView'

const PresetManager = (props) => {
    const sx = {
        root: {
            height: 'calc(100vh - 230px)'
        },
        treeViewContainer: {
            height: '100%'
        },
        attibutesEditorContainer: {
            height: '100%',
            borderLeft: `2px dashed ${COLORS.theme.grey.light}`,
            padding: '10px',
            overflow: 'scroll'
        }
    }
    const record = useRecordContext(props)
    const presetId = parseInt(record?.id?.toString() || '')
    const parentModelId = record?.parent_model_id ?? ''
    const modelCode = record?.model?.code ?? ''

    const { data: { configuratorNode: initialRootNode } = {}, loading: rootNodeIsLoading } =
        useNodeQuery({
            variables: {
                id: parentModelId
            }
        })
    const { refetch: refetchRootNode } = useNodeQuery({
        variables: { id: parentModelId },
        skip: true
    })

    const [nodeId, setNodeId] = useState<number | null>()
    const [parentId, setParentId] = useState<string>()
    const [treeKey, setTreeKey] = useState<number>(0)
    const [syncing, setSyncing] = useState<boolean>(false)
    const [attributeEditorKey, setAttributeEditorKey] = useState<number>(0)
    const [rootNode, setRootNode] = useState<TreeItem[]>()

    // Initial root node
    useEffect(() => {
        if (!rootNodeIsLoading) setRootNode(initialRootNode as any)
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialRootNode])

    // Handle click on node in tree
    const handleNodeClick = (id: number | null, parentId: string) => {
        setNodeId(id)
        setParentId(parentId)
    }

    // Re-fetching rootNode and re-rendering tree
    const handleTreeReRender = async () => {
        setSyncing(true)
        await refetchRootNode()
            .then(({ data: { configuratorNode: rootnode } }) => {
                setRootNode(rootnode as any)
                setTreeKey(treeKey + 1)
            })
            .finally(() => {
                setTimeout(() => {
                    setSyncing(false)
                }, 250)
            })
    }

    // Re-rendering form
    const handleAttributeEditorReRender = () => {
        setAttributeEditorKey(attributeEditorKey + 1)
    }

    return (
        <>
            <PresetModelProvider>
                <PresetModelContext.Consumer>
                    {() => (
                        <Grid container sx={sx.root}>
                            <Grid item xs={6} sx={sx.treeViewContainer}>
                                {!syncing && rootNode && !rootNodeIsLoading ? (
                                    <TreeView
                                        key={treeKey}
                                        handleNodeClick={handleNodeClick}
                                        reRenderCallback={handleTreeReRender}
                                        presetId={presetId}
                                        rootNode={rootNode}
                                    />
                                ) : (
                                    <CircularProgress />
                                )}
                            </Grid>
                            <Grid item xs={6} sx={sx.attibutesEditorContainer}>
                                {nodeId && (
                                    <AttributesEditor
                                        key={attributeEditorKey}
                                        reRenderCallback={handleAttributeEditorReRender}
                                        nodeId={+nodeId}
                                        parentId={parentId}
                                        presetId={presetId}
                                        modelCode={modelCode}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    )}
                </PresetModelContext.Consumer>
            </PresetModelProvider>
        </>
    )
}

export default PresetManager
